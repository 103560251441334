import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../../hooks/useLagoon';
import { useSpecialtiesResult } from '../../../hooks/useSpecialties';
import { BhProviderIcons } from '../../../models/Lagoon';
import { FacilityDDPCode } from '../../../models/Provider';
import { ProviderType } from '../../../models/ProviderDetails';
import { returnSuppressFlag } from '../../../utils/featureSuppress';
import { getBhIcon } from '../../../utils/iconography.utils';
import { isVirtualCareIconFeatureEnabledOnDetailsHeader } from '../../../utils/indicator.utils';
import {
  UserDDPCode,
  getSuppressFacilityFlags,
  isShowPreferredFacilityBadge,
} from '../../../utils/providerDetails.utils';
import { parseProviderName } from '../../../utils/providerNames.utils';
import { getCurrentMember, getIsOxfordAndUSP } from '../../../utils/user.utils';
import { widgetModalEvent } from '../../../utils/widgetEvents.utils';
import { Constants } from '../../Constants';
import { FeatureFlags } from '../../ConstantsFeatureFlags';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import { mobileOnly, phoneOnly } from '../../ConstantsStyles';
import {
  BenefitsBadge,
  BenefitsBadgeType,
} from '../../DetailsPageHeader/BenefitsBadge';
import { AvatarDetailsPage } from '../../DetailsPageHeader/DetailsPageHeader.styled';
import { InNetworkStatusLabel } from '../../DetailsPageHeader/InNetworkStatusLabel';
import { ProviderDetailsRating } from '../../DetailsPageHeader/ProviderDetailsRating';
import { VirtualCareOfferedLabel } from '../../DetailsPageHeader/VirtualCareOfferedLabel';
import { ImageComponent } from '../../ImageComponent';
import { getFeatureFlag } from '../../Utils';
import {
  BenefitsBadgeContainer,
  CostEstimateContainer,
  OutOfPocketContainer,
  ProviderInfo,
  ProviderName,
  ProviderNameAndCost,
  ProviderNameContainer,
  ProviderSpecialty,
  TotalContainer,
  WidgetHeader,
  YouPayContainer,
} from './DetailsWidgetHeader.styled';

type Props = {
  coverageType: string;
  ddpFacilityCode?: string;
  ddpUserCode?: UserDDPCode;
  imageLocation: string;
  inNetwork: string;
  isOxPreferredLab?: boolean;
  isFreeStandingFacility?: boolean;
  isPreferredLabNetwork?: boolean;
  nameAttrs?: any;
  organizationType: string;
  outOfPocketCost?: string;
  primarySpeciality: string;
  professionalDesignation: string;
  providerName: string;
  providerType: ProviderType;
  rating: string;
  surveyCount: number;
  totalCost?: string;
  virtualCareOffered: boolean;
};
export const DetailsWidgetHeader = ({
  coverageType,
  ddpFacilityCode,
  ddpUserCode,
  imageLocation,
  inNetwork,
  isOxPreferredLab,
  isFreeStandingFacility,
  isPreferredLabNetwork,
  nameAttrs,
  organizationType,
  outOfPocketCost,
  primarySpeciality,
  professionalDesignation,
  providerName,
  providerType,
  rating,
  surveyCount,
  totalCost,
  virtualCareOffered,
}: Props) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const featureFlags = useLagoon('feature-flags')();
  const mobileScreen = useMediaQuery(mobileOnly);
  const phoneScreen = useMediaQuery(phoneOnly);
  const { t } = useTranslation();
  const currentMember = getCurrentMember();

  const bhProviderIcons: BhProviderIcons[] = useLagoon('bh_provider_icons')();
  const { data: bhSpecialtyCodes = [] } = useSpecialtiesResult({
    coverageType: 'B',
  });
  const bhIcon =
    coverageType === 'B'
      ? getBhIcon([], [primarySpeciality], bhProviderIcons, bhSpecialtyCodes)
      : '';

  const showPreferredProviderTag = getFeatureFlag(
    featureFlags,
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
      Constants.CHIPS_CATEGORIES.FACILITIES
    ].PREFERRED_FACILITY_IDENTIFICATION
  );
  const suppressReviews = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PATIENT_REVIEWS
  );
  const suppressFacilityFlags = getSuppressFacilityFlags();

  const displayVirtualCareIcon =
    virtualCareOffered &&
    isVirtualCareIconFeatureEnabledOnDetailsHeader(featureFlags, coverageType);

  const { isOXMember } = getIsOxfordAndUSP(
    currentMember,
    coverageType,
    featureFlags
  );

  const oxfordPlnFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.OXFORD_PLN
  );

  const showPreferredFacilityBadge = isShowPreferredFacilityBadge(
    suppressFacilityFlags,
    showPreferredProviderTag,
    ddpFacilityCode as FacilityDDPCode,
    ddpUserCode,
    isPreferredLabNetwork,
    isOxPreferredLab,
    isOXMember,
    isFreeStandingFacility,
    oxfordPlnFlag
  );

  const trackPreferredFacilityClick = () => {
    widgetModalEvent({
      modalName: 'preferred facility',
    });
  };

  return (
    <WidgetHeader>
      <Flex>
        <AvatarDetailsPage data-testid="widget-profile-picture">
          {ImageComponent({
            imgRef,
            providerType,
            imgSrc: imageLocation,
            bhIcon,
          })}
        </AvatarDetailsPage>

        <ProviderInfo grow space="0">
          <ProviderNameAndCost alignItems="top" alignLayout="between">
            <ProviderNameContainer alignItems="left" space="4">
              {showPreferredFacilityBadge ? (
                <BenefitsBadgeContainer
                  data-testid="benefits-badge-container"
                  onClick={trackPreferredFacilityClick}
                >
                  <BenefitsBadge
                    badgeType={BenefitsBadgeType.PREFERRED_FACILITY}
                  />
                </BenefitsBadgeContainer>
              ) : null}
              <ProviderName
                data-testid={`widget-header-${providerType}-name`}
                fontWeight="$bold"
                size="$lg"
              >
                {parseProviderName(
                  providerName,
                  providerType,
                  professionalDesignation,
                  nameAttrs
                )}
              </ProviderName>
              <ProviderSpecialty
                data-testid={`widget-header-${providerType}-specialty`}
                fontWeight="$normal"
                size="$md"
                transform="capitalize"
              >
                {providerType === ProviderType.ORGANIZATION
                  ? organizationType
                  : primarySpeciality}
              </ProviderSpecialty>
            </ProviderNameContainer>
            {!phoneScreen && (outOfPocketCost || totalCost) ? (
              <CostEstimateContainer alignItems="left" space="2">
                <YouPayContainer data-testid="widget-header-you-pay-container">
                  {t('DETAILS_WIDGET.YOU_PAY_IN_NETWORK')}
                </YouPayContainer>
                {outOfPocketCost ? (
                  <OutOfPocketContainer>{`$${outOfPocketCost}`}</OutOfPocketContainer>
                ) : null}

                {totalCost ? (
                  <TotalContainer>
                    {`$${totalCost} `} {t('DETAILS_WIDGET.TOTAL')}
                  </TotalContainer>
                ) : null}
              </CostEstimateContainer>
            ) : null}
          </ProviderNameAndCost>
          <Layout.Group space={mobileScreen ? '0' : '-8'}>
            <Layout.Stack alignItems="left" space="4">
              <Layout.Group space="0">
                <InNetworkStatusLabel
                  iconColor="$providerDetailsDrawerHeaderInNetworkStatusIconColor"
                  inNetwork={inNetwork}
                  inNetworkTextColor="$providerDetailsDrawerHeaderInNetworkStatusLabelColor"
                />
                {!suppressReviews ? (
                  <ProviderDetailsRating
                    color="$providerDetailsDrawerHeaderTextColor"
                    providerType={providerType}
                    rating={rating}
                    surveyCount={surveyCount}
                  />
                ) : null}
              </Layout.Group>
              <VirtualCareOfferedLabel
                iconColor="$primary2"
                textColor="$primary2"
                virtualCareOffered={displayVirtualCareIcon}
              />
            </Layout.Stack>
          </Layout.Group>
        </ProviderInfo>
      </Flex>
    </WidgetHeader>
  );
};
