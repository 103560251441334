import { config } from '@abyss/web/tools/config';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import React from 'react';

import { ProviderTypes } from './Constants';

export type Props = {
  imgRef: React.RefObject<HTMLImageElement>;
  providerType?: string;
  imgSrc?: string;
  bhIcon?: string;
  width?: string;
  height?: string;
};

export const ImageComponent = ({
  imgRef,
  providerType,
  imgSrc,
  bhIcon,
  width = '84px',
  height = '84px',
}: Props) => {
  let dataTestId: string;

  if (imgSrc && imgSrc.includes('default')) {
    dataTestId = 'default-image';
    if (
      bhIcon &&
      providerType?.toLowerCase() !== ProviderTypes.ORGANIZATION.toLowerCase()
    ) {
      return <IconBrand css={{ width, height }} icon={bhIcon} />;
    } else if (
      bhIcon &&
      providerType?.toLowerCase() == ProviderTypes.ORGANIZATION.toLowerCase()
    ) {
      return <IconBrand css={{ width, height }} icon="clinic" />;
    }
  } else {
    dataTestId = 'provider-image';
  }
  const handleImageError = () => {
    if (imgRef.current) {
      // eslint-disable-next-line no-param-reassign
      imgRef.current.src = `${config(
        'CDN_BASE_URL'
      )}/cdn/assets/images/default_${providerType}.jpg`;
    }
  };
  return (
    <img
      alt=""
      data-testid={dataTestId}
      onError={handleImageError}
      ref={imgRef}
      src={imgSrc}
    />
  );
};
