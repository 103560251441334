import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { getModuleOrDefault } from '@optum-fpc-psx-mobile-apps/mapping-files-translation';
import find from 'lodash/find';
import pick from 'lodash/pick';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import { adobeImpressionTrackEvent } from '../../../../common/AdobeTagging/adobeImpressionTrackEvent';
import { adobeLinkTrackEvent } from '../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { adobeSearchModifiedEvent } from '../../../../common/AdobeTagging/adobeSearchModifiedEvent';
import { adobeStandardSearchEvent } from '../../../../common/AdobeTagging/adobeStandardSearchEvent';
import {
  CareCategories,
  Constants,
  ReverseCoverageTypesCodes,
  TypeaheadChipCategoriesMapping,
  errorCodeRouteMap,
} from '../../../../common/Constants';
import {
  FeatureFlags,
  SECTION_TERM,
} from '../../../../common/ConstantsFeatureFlags';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { ConstantsRoutes } from '../../../../common/ConstantsRoutes';
import { phoneOnly } from '../../../../common/ConstantsStyles';
import { DisclaimersContainer } from '../../../../common/DisclaimersContainer/DisclaimersContainer';
import { SectionSkeleton } from '../../../../common/LazyLoaderSkeleton/SectionSkeleton';
import { NullResultsPage } from '../../../../common/NullResultsPage';
import { PSXHeader } from '../../../../common/PSXHeader';
import { StillNeedHelp } from '../../../../common/StillNeedHelp';
import { convertObjectToUrlParams } from '../../../../common/Utils';
import {
  convertCoverageType,
  getIndicatorsForImpressions,
  getSearchLocation,
  getTier1Indicator,
} from '../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { CountySearchContext } from '../../../../context/CountySearchContext';
import { PortalContext } from '../../../../context/PortalContext';
import { SearchFilterContext } from '../../../../context/SearchFilterContext';
import { useAdobePageTrackEvent } from '../../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useCoverageType } from '../../../../hooks/useCoverageType';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { useGeoLocationStorage } from '../../../../hooks/useGeoLocationStorage';
import { useInitializeStore } from '../../../../hooks/useInitializeStore';
import { useLagoon } from '../../../../hooks/useLagoon';
import {
  usePlanYear,
  useSaveRecentSearches,
} from '../../../../hooks/useRecentSearches/useRecentSearches';
import { useSpecialtiesResult } from '../../../../hooks/useSpecialties';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { useTypeAheadData } from '../../../../hooks/useTypeAheadData';
import { useTypeaheadPseudoRollupTable } from '../../../../hooks/useTypeaheadPseudoRollup/useTypeaheadPseudoRollup';
import { BreadcrumbSessionStorage } from '../../../../models/BreadcrumbSessionStorage';
import { useAnalyticsStore } from '../../../../store/useAnalyticsStore';
import { useChipStore } from '../../../../store/useChipStore';
import { ChipState } from '../../../../store/useChipStore/chipStore';
import { useErrorStore } from '../../../../store/useErrorStore';
import { usePCPStore } from '../../../../store/usePCPStore';
import { useSearchStore } from '../../../../store/useSearchStore';
import { useTypeaheadStore } from '../../../../store/useTypeaheadStore';
import { LagoonData } from '../../../../utils/common';
import { returnSuppressFlag } from '../../../../utils/featureSuppress';
import { getSuppressFacilityFlags } from '../../../../utils/providerDetails.utils';
import { getLagoonConfigValue } from '../../../../utils/providerSearch.utils';
import { getResolvedSource } from '../../../../utils/typeahead.utils';
import {
  getCoverageTypes,
  getCurrentMember,
  getDependentInfo,
  getLoggedInMember,
  isOHCNSMember,
} from '../../../../utils/user.utils';
import { NullPageContainerStyled } from '../NullSpecialtyPage/NullSpecialtyPage.styles';
import { SearchedResultSectionData } from './SearchedResultSectionData';
import {
  getConditionAoeCodes,
  getCorrectChipCategory,
  getECPValueCode,
  getGenderCode,
  getRecentSearchVariables,
  getSearchResultsPageParams,
  getSearchType,
  getSelectedCoverageType,
  getSpecialtyCode,
  getVirtualCareCode,
} from './SearchResults.UtilsV2';
import {
  getNoOfResultsForAnalytics,
  getProviderSearchPayload,
  getProviderSearchPayloadWithRecriprocityId,
  getProviderType,
  getProviderTypesFromDisplayedResults,
} from './searchUtils';
import {
  UseParallelSearchResults,
  useParallelSearchResultsCategoriesCount,
} from './useParallelSearchResultsCategoriesCount';
import { useSearchResultsProviderResults } from './useSearchResultsProviderResults';
import {
  checkForPseudoRollupCodes,
  compareAndReturn,
  getAllProvidersData,
  getFirstEnabledChip,
  isShowProviderGroups,
} from './utils';

export const SearchResultsV2 = () => {
  // initialize store values from url searchParams for search results page
  const { providerType = '' } = useInitializeStore(
    ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key
  );
  const { t } = useTranslation();
  const { navigate } = useRouter();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const storedLocation = useGeoLocationStorage();
  const mobileScreen = useMediaQuery(phoneOnly);
  const { isCountySearch } = useContext(CountySearchContext);
  const { portalData } = useContext(PortalContext);
  const { clearSearchFilters } = useContext(SearchFilterContext);

  const coverageTypeFromStore = useCoverageType();
  const coverageTypeFromParams = searchParams.get('coverageType');
  const coverageType = coverageTypeFromParams || coverageTypeFromStore;
  // Todo: Need to fix specialityCodes search and check if this is still in use
  const { data: specialtyCodes = [] } = useSpecialtiesResult({
    coverageType,
  });

  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const deepLinkMapping = useLagoon(Constants.LAGOON_TABLE.DEEPLINK_MAPPING)();
  const uiMessaging = useLagoon('ui-messaging')();
  const typeaheadPreferenceCodes = useLagoon('typeahead-preference')();
  const allConfigs = useLagoon('config');
  const searchRadius = getLagoonConfigValue(
    allConfigs,
    'DEFAULT_SEARCH_RADIUS'
  );
  const autoIncrementRadius = getLagoonConfigValue(
    allConfigs,
    'AUTO_INCREMENT_SEARCH_RADIUS'
  );
  const suppressFacilityFlags = getSuppressFacilityFlags();
  const suppressPremiumCare = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PREMIUM_CARE
  );
  const [
    cleanUpProviderCalls,
    enableBHAoeTypeahead,
    eapCodeFlag,
    showTierProviderTag,
    enableNavigateToCorrectCategory,
    enableAoeMedical,
  ] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.CLEANUP_PROVIDER_CALLS,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_BH_AOE_TYPEAHEAD,
    ConstantsLagoon.FEATURE_FLAGS.EAP_CODE,
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
      Constants.CHIPS_CATEGORIES.PRIMARY_CARE
    ][SECTION_TERM.TIER_ONE],
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_NAVIGATE_TO_CORRECT_CATEGORY,
    ConstantsLagoon.FEATURE_FLAGS.OXFORD_PLN,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_AOE_MEDICAL,
  ]);

  const [, saveRecentSearch] = useSaveRecentSearches({});
  const [planYear] = usePlanYear();

  const memberHasRPK = useStore(StoreKeys.TIER1_PLAN_FLAG);
  const [globalSeachParams] = useSessionStorage<{
    includeSpecialityRollupCodes: string;
  }>(Constants.STORAGE_KEYS.SESSION.PSX_GLOBAL_SEARCH, {
    includeSpecialityRollupCodes: '',
  });
  const [breadcrumbUrls, setBreadcrumbUrls] =
    useSessionStorage<BreadcrumbSessionStorage>(
      Constants.STORAGE_KEYS.SESSION.BREADCRUMB_URLS,
      {}
    );

  const searchParamsObject = Object.fromEntries(searchParams.entries());
  const {
    searchType = '',
    search = '',
    source = '',
    choosePCP: choosePCPFromParams,
  } = searchParamsObject;
  const choosePCP = choosePCPFromParams === 'true';
  const isSpecialtySearch =
    searchParams.get('searchType') === Constants.SEARCH_TYPES.SPECIALTY;
  const isGlobalSearch = source === Constants.GLOBAL_SEARCH;

  const isSearchModified = useRef(false);
  const isTypeAheadDataLoaded = useRef(false);
  const isCategoryCountLoaded = useRef(false);
  const isEmptyResults = useRef(false);
  const typeAheadDataRef = useRef<any>({});
  const categoryCountResultsRef = useRef<any>({});
  const previousDataRef = useRef(null);
  const dataRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [, setEmptyResults] = useState(false);
  const [isPageTracked, setPageTracked] = useState<boolean>(false);

  const [showProviders, setShowProviders] = useState(true);
  const [showProviderGroups, setShowProviderGroups] = useState(true);
  const [showFacilities, setShowFacilities] = useState(true);

  const uesTranslation = getModuleOrDefault('en', 'uesSpecialityTranslations');
  const aoeTranslations = getModuleOrDefault('en', 'aoeTranslations');
  const specialityCodeTranslation = getModuleOrDefault(
    'en',
    'sourceSystemSpecialityTranslations'
  );

  const { dependentSeqNbr } = usePCPStore(
    useShallow((state: any) => ({
      dependentSeqNbr: state.pcpSearchState.dependentSeqNbr,
    }))
  );
  const { originLinkNameForAnalytics = '', setAnalyticsState } =
    useAnalyticsStore(
      useShallow((state: any) => ({
        originLinkNameForAnalytics:
          state.analyticsState.originLinkNameForAnalytics,
        setAnalyticsState: state.setAnalyticsState,
      }))
    );

  const { setErrorStore } = useErrorStore(
    useShallow((state: any) => ({
      setErrorStore: state.setErrorStore,
    }))
  );

  const {
    chipValue: chipValue,
    setChipValue,
    setCoverageType,
  } = useChipStore(
    useShallow((state: ChipState) => ({
      chipValue: state.chipValue,
      setChipValue: state.setChipValue,
      setCoverageType: state.setCoverageType,
    }))
  );

  const { typeaheadSearchStore, setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: any) => ({
      typeaheadSearchStore: state.typeaheadSearchStore,
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );

  const { onlineRetailers } = useSearchStore(
    useShallow((state: any) => ({
      onlineRetailers: state.searchStore.onlineRetailers,
    }))
  );

  const OHCNSpolicyIds = find(uiMessaging, {
    key: ConstantsLagoon.UI_MESSAGING.STATE_SPECIFIC_DISCLAIMER,
  })?.policyIDs;
  const isOHCNS = isOHCNSMember(OHCNSpolicyIds);

  const isSuperUser = storage.session.get(
    Constants.STORAGE_KEYS.SESSION.SUPER_USER
  );
  const isGuestUser = storage.session.get(
    Constants.STORAGE_KEYS.SESSION.IS_GUEST
  );

  const currentMember = dependentSeqNbr
    ? getDependentInfo(dependentSeqNbr)
    : getCurrentMember();

  const ddpCodeUser = getLoggedInMember()?.ddpCode;
  const { longitude, latitude } = storedLocation;
  const memberCoverageTypes = getCoverageTypes(currentMember);
  const dependentInfo = getDependentInfo(dependentSeqNbr);
  const pseudoRollupCodeTable: LagoonData[] = useTypeaheadPseudoRollupTable();

  const baseParams = {
    pseudoRollupCodeTable,
    deepLinkMapping,
    choosePCP,
    aoeTranslations,
    specialityCodeTranslation,
    specialtyCodes,
    isGlobalSearch,
    globalSeachParams,
    setTypeaheadSearchStore,
    typeaheadSearchStore,
    currentMember,
    onlineRetailers,
    featureFlags,
    portalData,
    isSpecialtySearch,
    uesTranslation,
    dependentSeqNbr,
    coverageType,
    searchRadius,
    autoIncrementRadius,
    providerType,
    dependentInfo,
  };

  const setShowProviderGroupsWithAdditionalCheck = (value: boolean) => {
    const resolvedValue = isShowProviderGroups(
      value,
      currentMember?.lineOfBusiness,
      choosePCP
    );
    setShowProviderGroups(resolvedValue);
  };

  const uniqueSearch = `${search}-${getResolvedSource(
    source
  )}-${latitude}-${longitude}`;
  // Set empty results to false and chip category to all, when new search is triggered.
  useEffect(() => {
    if (search) {
      isEmptyResults.current = false;
      isTypeAheadDataLoaded.current = false;
      isCategoryCountLoaded.current = false;
      setChipValue(CareCategories.ALL);
    }
  }, [uniqueSearch]);

  const onTypeAheadDataLoaded = (tyeaheadData: any) => {
    isTypeAheadDataLoaded.current = true;
    typeAheadDataRef.current = tyeaheadData;
    // If choosePCP is true, then we don't need to make category count request.
    if (choosePCP) {
      return;
    }
    const { categoryCountRequestParams } = getSearchResultsPageParams({
      ...baseParams,
      tyeaheadData: typeAheadDataRef.current,
      chipValue,
    });
    getParallelCategoryCountResults(categoryCountRequestParams);
  };

  useTypeAheadData({ onTypeAheadDataLoaded });

  const {
    otherParams: {
      preferredFacilities,
      search: searchTitle,
      virtualCare,
      includeSpecialityRollupCodes,
      acceptingNewPatients,
      gender,
      specialtyCodeFromQuery,
      preSelectEapFilter,
      essentialCommunityProvider,
      userZip,
      category: searchCategory,
      pesKeyword,
      linkName,
      combinedIncludeSpecialityRollupCodes,
      searchMethod,
      searchTerm,
      medicalSpeciality,
      sectionType,
      isMixedSuggestions,
      condition,
    },
  } = getSearchResultsPageParams({
    ...baseParams,
    tyeaheadData: typeAheadDataRef.current,
    chipValue,
  });

  const specialtyCode: string = getSpecialtyCode(
    pesKeyword,
    searchCategory,
    specialtyCodes
  );

  const hasOnlyPseudoRollupCodes = checkForPseudoRollupCodes(
    includeSpecialityRollupCodes
  );
  const genderCode = gender ? [getGenderCode(gender)] : [];
  const virtualCareCode = virtualCare ? getVirtualCareCode(t) : undefined;
  const essentialCommunityProviderCode = essentialCommunityProvider
    ? getECPValueCode(t)
    : undefined;
  const { areaOfExpertise } = getConditionAoeCodes(
    condition,
    deepLinkMapping,
    aoeTranslations
  );

  useEffect(() => {
    clearSearchFilters();
  }, []);

  useEffect(() => {
    if (choosePCP) {
      adobePageTrackEvent();
      setPageTracked(true);
    }
  }, [choosePCP]);

  useEffect(() => {
    setPageTracked(false);
  }, [chipValue]);

  // This is to load the initial Provider Search Results after Category Count is loaded
  const onCategoryCountLoaded = (
    categoryCountResults: UseParallelSearchResults
  ) => {
    isCategoryCountLoaded.current = true;
    categoryCountResultsRef.current = categoryCountResults;
    const { nameResults, specialityResults } = categoryCountResults;

    const enabledChipForNameSearch = getFirstEnabledChip(nameResults);
    const enabledChipForSpecialitySearch =
      getFirstEnabledChip(specialityResults);

    const hasOnlySpecialitySearchEnabled =
      !enabledChipForNameSearch && !!enabledChipForSpecialitySearch;

    const availableCategoryChips = specialityResults.reduce<string[]>(
      (acc, item) => {
        if (item.chipEnabled) {
          acc.push(TypeaheadChipCategoriesMapping[item.chipCategory]);
        }
        return acc;
      },
      []
    );

    const { combinedIncludeSpecialityRollupCodes } = typeAheadDataRef.current;

    const navigateToChipCategory = enableNavigateToCorrectCategory
      ? getCorrectChipCategory(
          combinedIncludeSpecialityRollupCodes,
          typeaheadPreferenceCodes,
          specialityResults
        )
      : '';

    const chipToSelect =
      !(hasOnlySpecialitySearchEnabled || isSpecialtySearch) &&
      enabledChipForNameSearch
        ? enabledChipForNameSearch
        : availableCategoryChips.includes(navigateToChipCategory)
        ? navigateToChipCategory
        : enabledChipForSpecialitySearch;

    if (!isSuperUser && !isGuestUser) {
      const categoriesCountResults =
        enabledChipForNameSearch || !enabledChipForSpecialitySearch
          ? nameResults
          : specialityResults;

      const variables = getRecentSearchVariables({
        baseParams,
        tyeaheadData: typeAheadDataRef.current,
        categoriesCountResults,
        planYear,
        chipValue: chipToSelect || chipValue,
        storedLocation,
      });
      saveRecentSearch({ variables });
    }

    // If chipToSelect is defined, then we have results for that category
    if (chipToSelect) {
      const specialtySearch =
        hasOnlySpecialitySearchEnabled || isSpecialtySearch;
      setChipValue(chipToSelect);
      const selectedCoverageType = getSelectedCoverageType(chipToSelect);
      setCoverageType(ReverseCoverageTypesCodes[selectedCoverageType]);
      setSearchParams(
        {
          ...searchParamsObject,
          chipValue: chipToSelect,
          coverageType: ReverseCoverageTypesCodes[selectedCoverageType],
          searchType: getSearchType(specialtySearch),
        },
        { replace: true }
      );
      // We need this setTimeout for below reasons.
      // Apollo does not trigger onCompleted when the data is the same as the previous data
      // So, we are setting the isLoading to false after 1 second if the data is the same as the previous data
      setTimeout(() => {
        if (dataRef.current === previousDataRef.current) {
          setIsLoading(false);
        }
        previousDataRef.current = null;
      }, 1000);
    } else {
      // chipToSelect is not defined, so we don't have results for any category
      // So, we need to show the empty results page
      setIsLoading(false);
      isEmptyResults.current = true;
      previousDataRef.current = dataRef.current;
      const { chipValue: chipValueFromParams } = searchParamsObject;
      setChipValue(chipValueFromParams);
      const { coverageType } = typeAheadDataRef.current;
      if (coverageType && chipValue === CareCategories.ALL) {
        setCoverageType(coverageType);
        setSearchParams(
          {
            ...searchParamsObject,
            coverageType,
          },
          { replace: true }
        );
      }
    }
  };

  const [parallelCategoryCountResults, getParallelCategoryCountResults] =
    useParallelSearchResultsCategoriesCount({}, onCategoryCountLoaded);
  const {
    data,
    error,
    providerIsLoading,
    providersResult,
    medGrpResult,
    organizationResult,
    orgIsLoading,
    medGrpIsLoading,
    providerHasError,
    getProviderSearchResults,
  } = useSearchResultsProviderResults({});

  useEffect(() => {
    const {
      totalCountPractitioners,
      totalCountOrganizations,
      totalCountMedicalGroup,
    } = data || {};
    const isResultsLoaded =
      (!showProviders || totalCountPractitioners !== undefined) &&
      (!showFacilities || totalCountOrganizations !== undefined) &&
      (!showProviderGroups || totalCountMedicalGroup !== undefined);
    if (isResultsLoaded) {
      const hasResults = !!(
        totalCountPractitioners ||
        totalCountOrganizations ||
        totalCountMedicalGroup
      );
      setEmptyResults(!hasResults);
      isEmptyResults.current = !hasResults;
      setIsLoading(false);
    }
  }, [JSON.stringify(data), showProviders, showFacilities, showProviderGroups]);

  const allProviders = getAllProvidersData(data, chipValue);
  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: 'overview',
    sitesectionLevel1: 'search results',
    sitesectionLevel2: 'category',
    providerBlock: {
      type: convertCoverageType(coverageType),
    },
    impressionBlock: {
      type: 'provider',
      indicator:
        allProviders?.length > 0
          ? getIndicatorsForImpressions(
              allProviders,
              featureFlags,
              false,
              {
                suppressPremiumCare,
                ddpCodeUser,
                suppressFacilityFlags,
                memberHasRPK,
                isCountySearch,
              },
              currentMember
            )
          : '',
      providerTier: getTier1Indicator(showTierProviderTag, allProviders),
    },
  });

  const getDataForProviders = (
    isSpecialtySearch: boolean,
    chipToUse: string
  ) => {
    const {
      providerSearchParams,
      otherParams: { coverageTypes },
    } = getSearchResultsPageParams({
      ...baseParams,
      tyeaheadData: typeAheadDataRef.current,
      chipValue: chipToUse,
    });

    const {
      search,
      preferredFacilities,
      includeSpecialityRollupCodes,
      pseudoVerticalsDetails,
      isUserTextSearch,
      typeaheadAoeCodes,
      keyword,
      bhProgramFacilityAgg,
    } = providerSearchParams;

    if (
      !choosePCP &&
      !(search || includeSpecialityRollupCodes || preferredFacilities?.length)
    ) {
      return;
    }
    const searchTypeToUse = getSearchType(isSpecialtySearch);

    const payloadWithRecriprocityId =
      getProviderSearchPayloadWithRecriprocityId(
        memberCoverageTypes,
        featureFlags,
        currentMember,
        coverageType,
        choosePCP,
        onlineRetailers
      );
    const payload = getProviderSearchPayload(
      payloadWithRecriprocityId,
      isSpecialtySearch,
      searchTypeToUse,
      isUserTextSearch,
      typeaheadAoeCodes,
      enableBHAoeTypeahead,
      includeSpecialityRollupCodes,
      pseudoVerticalsDetails?.isPseudoRollupSearch,
      uesTranslation,
      search,
      keyword,
      coverageTypes,
      bhProgramFacilityAgg,
      enableAoeMedical
    );

    const selectedCategory = Constants.CARE_CATEGORIES_TYPEAHEAD.find(
      (category) => category.label === chipToUse
    );
    const selectedCategoryPayload = payload.find(
      (item) => item.chipCategory === selectedCategory?.id
    );

    const { specialityResults } = categoryCountResultsRef.current;
    const providerQueryTypes: any =
      specialityResults?.filter(
        (item) => item.chipCategory === selectedCategory?.id
      )[0]?.providerTypes || [];

    getProviderSearchResults({
      setShowFacilities,
      setShowProviderGroups: setShowProviderGroupsWithAdditionalCheck,
      setShowProviders,
      providerSearchParams: {
        ...providerSearchParams,
        searchType: searchTypeToUse,
      },
      choosePCP,
      cleanUpProviderCalls,
      pseudoVerticalsDetails,
      selectedCategory,
      selectedCategoryPayload,
      specialtySearch: isSpecialtySearch,
      searchType: searchTypeToUse,
      providerQueryTypes,
    });
    setTypeaheadSearchStore({ providerQueryTypes });
  };

  // This is to trigger Provider Search Results when dependencies change and conditions are met
  useEffect(() => {
    const isSpecialtySearch = searchType === Constants.SEARCH_TYPES.SPECIALTY;
    if (
      isTypeAheadDataLoaded.current &&
      isCategoryCountLoaded.current &&
      chipValue !== CareCategories.ALL &&
      !choosePCP
    ) {
      getDataForProviders(isSpecialtySearch, chipValue);
    }
  }, [chipValue, choosePCP, searchType]);

  // This is to trigger Provider Search Results for choosePCP flow
  useEffect(() => {
    if (choosePCP) {
      getDataForProviders(
        searchType == Constants.SEARCH_TYPES.SPECIALTY,
        CareCategories.PRIMARY_CARE
      );
    }
  }, [choosePCP, latitude, longitude]);

  useEffect(() => {
    const totalNumberOfResults = getNoOfResultsForAnalytics(data);
    if (!isLoading && chipValue && !choosePCP && totalNumberOfResults > 0) {
      const adobeSearchMethod = compareAndReturn(searchMethod, 'guided');
      const adobeSearchTerm =
        adobeSearchMethod === 'guided'
          ? ''
          : compareAndReturn(searchTerm, search);
      const adobeEventBody = {
        term: adobeSearchTerm,
        type: getProviderType(providerType),
        linkName: originLinkNameForAnalytics,
        method: adobeSearchMethod,
        filters: chipValue?.toLowerCase(),
        numberOfResults: getNoOfResultsForAnalytics(data),
        customAttributesBlock: {
          providerType: getProviderTypesFromDisplayedResults(data),
          searchLocation: getSearchLocation(storedLocation),
        },
      };

      adobePageTrackEvent();
      setPageTracked(true);

      if (!isSearchModified.current) {
        adobeStandardSearchEvent(adobeEventBody);
        isSearchModified.current = true;
      } else {
        adobeSearchModifiedEvent(adobeEventBody);
      }

      if (isMixedSuggestions && isSpecialtySearch) {
        adobeImpressionTrackEvent({
          type: 'search modify',
          message:
            'search instead for providers that include typed term in their name',
          searchTerm: adobeSearchTerm,
          method: adobeSearchMethod,
        });
      }
      if (isMixedSuggestions && !isSpecialtySearch) {
        adobeImpressionTrackEvent({
          type: 'search modify',
          message: 'search instead for providers that can treat typed term',
          searchTerm: adobeSearchTerm,
          method: adobeSearchMethod,
        });
      }
    }
  }, [
    providerIsLoading,
    medGrpIsLoading,
    orgIsLoading,
    isSpecialtySearch,
    chipValue,
  ]);

  const noResultUrlParams = convertObjectToUrlParams({} as ChipState, {
    chipValue,
    includeSpecialityRollupCodes,
    linkName,
    medicalSpeciality,
    originLinkNameForAnalytics,
    search,
    searchType,
    sectionType,
    specialtyCode,
  });

  const showMedicalSpecialityNSRPage =
    medicalSpeciality &&
    chipValue === Constants.CHIPS_CATEGORIES.MEDICAL_SPECIALISTS;

  useEffect(() => {
    if (!isLoading && isEmptyResults.current && showMedicalSpecialityNSRPage) {
      navigate(
        `${ConstantsRoutes.NULL_SPECIALTY_RESULTS.path}/${noResultUrlParams}`
      );
    }
    if (error) {
      const errorStoreValues = {
        uiSection: linkName || originLinkNameForAnalytics,
      };
      setErrorStore(errorStoreValues);
      const urlParams = convertObjectToUrlParams({} as ChipState, {
        ...errorStoreValues,
      });
      navigate(
        `${
          errorCodeRouteMap[providerHasError?.status] ||
          errorCodeRouteMap['4xx']
        }${urlParams}`
      );
    }
  }, [isLoading, error, showMedicalSpecialityNSRPage, noResultUrlParams]);

  useEffect(() => {
    if (chipValue !== CareCategories.ALL) {
      document.title = chipValue
        ? `${t(`${chipValue}-results-for`)} ${searchTitle} | ${
            Constants.SITE_NAME
          }`
        : Constants.SITE_NAME;
    }
  }, [chipValue, searchTitle]);

  useEffect(() => {
    const { pathname, search } = location;
    breadcrumbUrls[
      ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key
    ] = `${pathname}${search}`;
    const updatedBreadCrumbUrls = pick(
      breadcrumbUrls,
      ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key
    );
    setBreadcrumbUrls(updatedBreadCrumbUrls);
  }, [searchParams]);

  const { specialityResults, nameResults } = categoryCountResultsRef.current;
  const enabledChipForNameSearch = getFirstEnabledChip(nameResults);
  const enabledChipForSpecialitySearch = getFirstEnabledChip(specialityResults);

  const onSpecialtyResultClicked = (
    isSpecialtySearch: boolean,
    title: string = ''
  ) => {
    const { specialityResults } = categoryCountResultsRef.current;
    const navigateToChipCategory = enableNavigateToCorrectCategory
      ? getCorrectChipCategory(
          combinedIncludeSpecialityRollupCodes,
          typeaheadPreferenceCodes,
          specialityResults
        )
      : '';

    const firstEnabledChip = isSpecialtySearch
      ? enabledChipForSpecialitySearch
      : enabledChipForNameSearch;
    const chipToSelect =
      isSpecialtySearch && navigateToChipCategory
        ? navigateToChipCategory
        : firstEnabledChip;

    const selectedCoverageType = getSelectedCoverageType(chipToSelect);
    setCoverageType(ReverseCoverageTypesCodes[selectedCoverageType]);
    setChipValue(chipToSelect);
    setSearchParams(
      {
        ...searchParamsObject,
        chipValue: chipToSelect,
        coverageType: ReverseCoverageTypesCodes[selectedCoverageType],
        searchType: getSearchType(isSpecialtySearch),
      },
      { replace: true }
    );

    adobeLinkTrackEvent({
      name: title,
      location: 'body:result heading',
    });

    setAnalyticsState({ originLinkNameForAnalytics: title });
  };

  const getChipsToShowOnHeader = () => {
    if (enabledChipForNameSearch && !isSpecialtySearch) {
      return nameResults || [];
    }
    return specialityResults || [];
  };

  const breadcrumbs = [
    {
      title: t('BC Results'),
      href: '',
    },
  ];

  return (
    <React.Fragment>
      {!choosePCP ? (
        <PSXHeader
          breadcrumbs={breadcrumbs}
          categoryCount={getChipsToShowOnHeader()}
          dataTestId="search-results-search-form"
          isMixedResultsPage
          showChips={!isEmptyResults.current}
          showChoosePCPHeader={false}
          showMemberSelection={!mobileScreen}
          showSearchInputBackButton={mobileScreen}
        />
      ) : (
        <PSXHeader
          breadcrumbs={breadcrumbs}
          dataTestId="search-results-search-form"
          showChips={false}
          showChoosePCPHeader
          showMemberSelection={false}
        />
      )}
      {isLoading ||
      (!choosePCP &&
        !isEmptyResults.current &&
        chipValue === CareCategories.ALL) ? (
        <SectionSkeleton />
      ) : isEmptyResults.current && !showMedicalSpecialityNSRPage ? (
        <NullResultsPage
          emptyResults={isEmptyResults.current}
          onCommonSearchChipClicked={() => {
            isEmptyResults.current = false;
            setIsLoading(true);
          }}
          searchTitle={searchTitle}
          specialtyCode={specialtyCode}
          specialtySearch={isSpecialtySearch}
        />
      ) : (
        <SearchedResultSectionData
          acceptingNewPatients={acceptingNewPatients}
          areaOfExpertise={areaOfExpertise}
          categoryCount={getChipsToShowOnHeader()}
          choosePCP={choosePCP || false}
          contextValue={chipValue}
          data={data}
          dependentSeqNbr={dependentSeqNbr || ''}
          eapCodeFlag={eapCodeFlag}
          essentialCommunityProviderCode={essentialCommunityProviderCode}
          facilitiesHeaders={organizationResult.headers}
          genderCode={genderCode}
          hasOnlyPseudoRollupCodes={hasOnlyPseudoRollupCodes}
          isMixedResultsPage
          isMixedSuggestions={isMixedSuggestions}
          isNameSearchEnabled={enabledChipForNameSearch !== ''}
          isPageTracked={isPageTracked}
          isSpecialtySearchEnabled={
            hasOnlyPseudoRollupCodes || enabledChipForSpecialitySearch !== ''
          }
          medGrpIsLoading={medGrpIsLoading}
          medicalGroupHeaders={medGrpResult.headers}
          onSpecialtyResultClicked={onSpecialtyResultClicked}
          orgIsLoading={orgIsLoading}
          preSelectEapFilter={preSelectEapFilter}
          preferredFacility={preferredFacilities}
          providerHeaders={providersResult.headers}
          providerIsLoading={providerIsLoading}
          resultType={chipValue}
          searchMethod={searchMethod}
          searchTerm={searchTerm}
          searchTitle={searchTitle}
          searchType={getSearchType(isSpecialtySearch)}
          showChips={!isEmptyResults.current}
          showFacilities={showFacilities}
          showProviderGroups={showProviderGroups}
          showProviders={showProviders}
          specialtyCode={specialtyCode}
          specialtyCodeFromQuery={specialtyCodeFromQuery}
          specialtyResults={parallelCategoryCountResults?.specialityResults}
          specialtySearch={isSpecialtySearch}
          userZip={userZip}
          virtualCare={virtualCare}
          virtualCareCode={virtualCareCode}
        />
      )}
      <NullPageContainerStyled hidden={!isEmptyResults.current}>
        <StillNeedHelp />
        {isOHCNS && <DisclaimersContainer />}
      </NullPageContainerStyled>
    </React.Fragment>
  );
};
