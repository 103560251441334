
export const isMissingParameters = (apiName: string, params: Record<string, any>): boolean => {
  const missingParams = Object.entries(params)
    .filter(([, value]) => value === null || value === '' || value === undefined)
    .map(([key]) => key);

  if (missingParams.length > 0) {
    // eslint-disable-next-line no-console
    console.log(`Error: ${apiName} is missing parameters: ${missingParams.join(', ')}`);
    return true;
  }
  return false;
};