import { Accordion } from '@abyss/web/ui/Accordion';
import { Flex } from '@abyss/web/ui/Flex';
import { Markdown } from '@abyss/web/ui/Markdown';
import find from 'lodash/find';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';
import { Constants, FilterOptionsType } from '../../../../common/Constants';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { useLagoon } from '../../../../hooks/useLagoon';
import {
  Option,
  ProviderFiltersType,
  ProviderSearchFilters,
} from '../../../../models/ProviderSearch';
import { useChipStore } from '../../../../store/useChipStore';
import { ChipState } from '../../../../store/useChipStore/chipStore';
import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import { CheckBoxGroup } from '../../../CheckBoxGroup';
import { EAP_OPTION_LABEL, FilterCollectionModel } from '../../../Constants';
import { ConstantsLagoon } from '../../../ConstantsLagoon';
import { RadioGroup } from '../../../RadioGroup';
import { FilterSlider } from '../../FilterSlider';
import { AllFilterSkeleton } from './AllFilterSkeleton';
import {
  FilterAccordianTrigger,
  FilterAccordionContent,
  FilterAccordionItem,
} from './ProviderFilters.style';
import { getFeatureFlag } from '../../../../common/Utils';

type Props = {
  providerFilters: ProviderFiltersType[];
  selectedFilters: ProviderSearchFilters;
  onFilterSelectionChange: (
    data: ProviderSearchFilters,
    lastModifiedModel: string
  ) => void;
  value?: string[];
  sectionType: string | undefined;
  AccordionType?: string;
  isData: boolean;
};

type OptionTypeProps = {
  options: Option[];
  model: string;
  label: string;
  type: string;
};

export const ProviderFilters = ({
  providerFilters,
  selectedFilters,
  onFilterSelectionChange,
  value,
  sectionType,
  AccordionType = 'single',
  isData,
}: Props) => {
  const { t } = useTranslation();
  const { coverageType } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
    }))
  );

  const [enableEapFilterUsingBHProgramFF] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_EAP_FILTER_USING_BH_PROGRAM,
  ]);

  if (enableEapFilterUsingBHProgramFF) {
    providerFilters = providerFilters.map((filter) => {
      if (filter.model === FilterCollectionModel.AREA_OF_EXPERTISE) {
        const updatedOptions = filter.options.filter(
          (option) => option.dnt_label !== EAP_OPTION_LABEL
        );
        return { ...filter, options: updatedOptions };
      }
      return filter;
    });
  }

  const data = useLagoon('ui-messaging')();
  const UpcomingAvailabilityFilterContent = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS
      .UPCOMING_AVAILABILITY_FILTER_DESCRIPTION,
  });
  const [openedAccordion, setOpenedAccordion] = useState<string>('');
  const [hideSKelton, setHideSkeleton] = useState(false);
  const allFiltersDrawer: string = 'all filters drawer';
  const getLocationForAnalytics = (label: string) =>
    `modal:${allFiltersDrawer}:${label} filter`;

  const onOptionChange = (e: { [key: string]: Option[] }) => {
    const currentSelectedFilters = {
      ...selectedFilters,
      ...e,
    };
    const firstValue = Object.values(e)[0];
    const lastModifiedModel =
      Array.isArray(firstValue) && firstValue.length > 0
        ? Object.keys(e)[0]
        : '';
    onFilterSelectionChange(currentSelectedFilters, lastModifiedModel);
  };

  function onChangeAccordion(e) {
    let linkName;
    let linkType;
    const linkLocation: string = `modal:${allFiltersDrawer}`;

    if (e && openedAccordion !== e) {
      linkName = providerFilters?.find((option) => e === option.model)?.label;
      linkType = 'accordion open';
    } else {
      linkName = providerFilters?.find(
        (option) => openedAccordion === option.model
      )?.label;
      linkType = 'accordion close';
    }

    adobeLinkTrackEvent({
      name: linkName,
      location: linkLocation,
      type: linkType,
    });

    setOpenedAccordion(e);
  }
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const isSedationPerformed: any = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.SEDATION_DENTISTRY_SERVICES_PERFORMED
  );

  useEffect(() => {
    setHideSkeleton(isData);
  }, []);

  const renderResultBasedOnType = ({
    options,
    model,
    label,
    type,
  }: OptionTypeProps) => {
    const filteredOptions :any = label == "Services provided" ? (isSedationPerformed? options: options.filter((option) =>
      option.label !== FilterCollectionModel.SEDATION_DENTISTRY)) : options;
    switch (type) {
      case FilterOptionsType.RADIO:
        return (
          <RadioGroup
            defaultValue={
              selectedFilters[model]?.length
                ? selectedFilters[model][0].value
                : ''
            }
            locationForAnalytics={getLocationForAnalytics(label)}
            model={model}
            onOptionChange={onOptionChange}
            options={options}
          />
        );
      case FilterOptionsType.SLIDER:
        return (
          <FilterSlider
            defaultValue={
              selectedFilters[model]
                ? selectedFilters[model].map((item: Option) => item.value)?.[0]
                : 0
            }
            locationForAnalytics={getLocationForAnalytics(label)}
            model={model}
            onOptionChange={onOptionChange}
            options={options}
          />
        );
      default:
        return (
          <CheckBoxGroup
            defaultValue={
              selectedFilters[model]
                ? selectedFilters[model].map((item: Option) => item.value)
                : []
            }
            locationForAnalytics={getLocationForAnalytics(label)}
            model={model}
            onOptionChange={onOptionChange}
            options={filteredOptions}
          />
        );
    }
  };

  return (
    <Accordion
      defaultValue={value}
      isCollapsible
      key={value?.join('-')}
      onValueChange={(e) => onChangeAccordion(e)}
      type={AccordionType}
    >
      {hideSKelton
        ? providerFilters
            ?.filter(({ allFilterDrawerFilters }) =>
              allFilterDrawerFilters[coverageType]?.includes(sectionType)
            )
            .sort(
              (a, b) =>
                a.allFilterDrawerOrder[coverageType] -
                b.allFilterDrawerOrder[coverageType]
            )
            .map(({ options, model, label, title, type }) => (
              <FilterAccordionItem
                data-testid={`${label.replace(/ /g, '-').toLowerCase()}-filter`}
                key={model}
                value={model}
              >
                <FilterAccordianTrigger
                  data-testid={`${label
                    .replace(/ /g, '-')
                    .toLowerCase()}-filter-heading`}
                >
                  <Flex css={{ gap: '8px' }}>{t(title || label)}</Flex>
                </FilterAccordianTrigger>
                <FilterAccordionContent>
                  {label === 'Upcoming Availability' && (
                    <span
                      data-testid={`${label
                        .replace(/ /g, '-')
                        .toLowerCase()}-filter-description`}
                    >
                      <Markdown>
                        {UpcomingAvailabilityFilterContent?.message ?? ''}
                      </Markdown>
                    </span>
                  )}
                  {renderResultBasedOnType({ options, model, label, type })}
                </FilterAccordionContent>
              </FilterAccordionItem>
            ))
        : [1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
            <AllFilterSkeleton index={i} key={i} />
          ))}
    </Accordion>
  );
};
