import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isExternalUrl } from '../../../utils/common';
import { PortalUrl } from '../../../common/Portal';
import { getLanguage } from '../../../frontends/ProviderSearch/context/Internationalization/helpers';
import { getPortalConfigUrls } from '../../../utils/portalConfig.utils';
import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import { phoneOnly } from '../../ConstantsStyles';
import { StyledWhereToGetHelpIcon } from '../WhereToGetHelp.Style';

export const ContentCardsContainer = styled('div', {
  dynamic: ({ cssProps: { backgroundColor } }) => ({
    backgroundColor,
  }),
  height: '100%',
  padding: '$md 32px',

  borderRadius: '8px',
  '@screen < $sm': {
    padding: '$md 24px',
    minHeight: '174px',
  },
});

export const CardText = styled(Text, {
  '&.abyss-text-root': {
    color: '#4B4D4F',
    fontWeight: '$medium',
    '@screen < $sm': { fontSize: '14.22px' },
  },
});

export const CardButton = {
  height: '40px',
  '@screen < $sm': {
    height: '42px',
    fontWeight: '600',
  },
  'abyss-button-root': {
    '@screen < $sm': {
      fontWeight: '$semibold',
      fontSize: '13px',
    },
  },
};

const srOnly = { display: 'none' };

type Props = {
  cardTitle?: string;
  cardContent: string;
  buttonLabel: string;
  dntHelpKey: any;
  url?: string;
  cardIcon: React.ReactNode;
  cardBGColor: string;
  testId?: string;
  accessibilityText?: string;
};

const getUrl = (url?: string) => {
  if(isExternalUrl(url)) {
    return url;
  }
  return url ? `${window.location.origin}${url}` : null;
} 

const buildUrlForCard = (url: string | undefined, labelName: string, isExternal: boolean = false) => {
  // If URL is provided, return URL appended with the current origin
  const langCode = getLanguage().code;
  url = url?.replace('lang_code', langCode).trim();
  if (url) {
    return {
      url: `${window.location.origin}${url}`,
      isExternal,
    };
  }

  // If URL is not provided, get the URL from the portal config
  const portalUrls: PortalUrl[] = getPortalConfigUrls();
  if (portalUrls?.length > 0) {
    const portalUrl = portalUrls.find((item) =>
      item?.name?.includes(labelName)
    );
    url = portalUrl?.urlPath;
  }

  // Check if the URL is an external
  const isExternalNewUrl = isExternalUrl(url);

  // If the portalUrl is found return the URL
  const newURL = getUrl(url);

  return {
    url: newURL ?? '',
    isExternal: isExternalNewUrl,
  }
};

export const WhereToGetHelpContainer = ({
  cardTitle = '',
  cardContent,
  buttonLabel,
  dntHelpKey,
  url,
  cardIcon,
  cardBGColor,
  accessibilityText = '',
}: Props) => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const { t } = useTranslation();

  let currentUrl = {
    url,
    isExternal: isExternalUrl(url),
  }

  const analyticsInfo = {
    name: buttonLabel,
    location: 'body:where to get help',
    destinationUrl: url,
  };
  const handleLinkTrackEvent = () => {
    adobeLinkTrackEvent({
      ...analyticsInfo,
    });

    if (currentUrl.isExternal) window.open(currentUrl.url, '_blank');
    else window.location.href = currentUrl.url as any;
  };
  const datatestid = cardTitle.replace(/[^a-zA-Z ]/g, '').replace(/\s+/g, '-');

  if (!currentUrl.isExternal) {
    currentUrl = buildUrlForCard(url, dntHelpKey);
  }

  return (
    <ContentCardsContainer cssProps={{ backgroundColor: cardBGColor }}>
      <Layout.Group
        alignItems="top"
        css={{
          '&, > div': {
            height: '100%',
          },
        }}
        data-auto-testid="where-to-get-help-card"
        data-testid="where-to-get-help-card"
        space={mobileScreen ? 24 : 32}
      >
        <Layout.Stack
          css={{
            marginRight: '20px',
            '@screen < $md': {
              size: '48px',
            },
          }}
        >
          {cardIcon ? (
            <StyledWhereToGetHelpIcon
              icon={cardIcon}
              size="60px"
              variant="twotone"
            />
          ) : null}
        </Layout.Stack>
        <Layout.Stack
          alignItems="left"
          css={{
            justifyContent: 'space-between',
          }}
          space={mobileScreen ? 16 : 24}
        >
          <Flex direction="column">
            <Heading
              css={{
                'abyss-heading-root': {
                  marginBottom: '4px',
                  '@screen < $sm': {
                    lineHeight: '20px !important',
                    fontSize: '$md !important',
                  },
                },
              }}
              data-auto-testid={`${datatestid}-heading`}
              data-testid={`${datatestid}-heading`}
              display="h6"
              offset={1}
            >
              {t(cardTitle)}
            </Heading>
            <CardText
              data-auto-testid={`${datatestid}-content`}
              data-testid={`${datatestid}-content`}
            >
              {cardContent}
            </CardText>
          </Flex>
          <Button
            aria-label={`${buttonLabel} ${accessibilityText}`.toLowerCase()}
            css={CardButton}
            data-auto-testid={`${datatestid}-button`}
            data-testid={`${datatestid}-button`}
            name="get_started"
            onClick={() => handleLinkTrackEvent()}
          >
            {buttonLabel}
            <span className="sr-only" style={srOnly}>
              {accessibilityText.toLowerCase()}
            </span>
            {currentUrl.isExternal ? (
              <IconMaterial
                css={{ marginLeft: mobileScreen ? '4px' : '8px' }}
                icon="open_in_new"
              />
            ) : null}
          </Button>
        </Layout.Stack>
      </Layout.Group>
    </ContentCardsContainer>
  );
};
