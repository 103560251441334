import { Constants } from '../../common/Constants';
import { handleSearchBarSearchButtonClickOrEnter } from '../../common/PSXHeader/SearchBar/utils';
import { handleSearchBarSpecialtySuggestionClickOrEnter } from '../../utils/typeahead.utils';

export const retriveTypeAheadDataFromAutoComplete = ({
  suggestions,
  typeAheadSuggestions,
  combinedRollupCodes,
  aggregatedAoeCodes,
  bhProgramFacilityAgg,
  search,
  searchLinkName,
  searchTypeFromParams,
  searchType,
  enableRetriveTypeAheadData,
  enableUESSuggestionMatch,
  specialityPreferenceTable,
  getCoverageType,
  dependentSeqNbr,
  choosePCP,
  chipStore,
  setTypeaheadSearchStore,
  setAnalyticsState,
  setPCPSearchState,
  setChipValue,
  setCoverageType,
  setDetailsStore,
  currentMember,
  convertTypeaheadProviderIdAndType,
  getTypeAheadCategory,
  onTypeAheadDataLoaded,
  typeaheadSearchStore,
}) => {
  const value = suggestions?.find(
    ({ suggestion }) =>
      suggestion.toLowerCase() === search.toString().toLowerCase()
  );

  const isSearchButtonClickOrKeyboardEnter =
    ['search button', 'search enter', 'general search'].includes(
      searchLinkName
    ) || searchType === Constants.SEARCH_TYPES.NAME;
  const isSuggestionClickOrEnter = !isSearchButtonClickOrKeyboardEnter;

  if (isSuggestionClickOrEnter) {

    if (!value) {
      onTypeAheadDataLoaded(typeaheadSearchStore);
      return;
    }
    const typeaheadValues = handleSearchBarSpecialtySuggestionClickOrEnter({
      enableRetriveTypeAheadData,
      specialityPreferenceTable,
      value,
      searchTerm: search,
      commonSearch: false,
      getCoverageType,
      dependentSeqNbr,
      choosePCP,
      chipStore,
      setTypeaheadSearchStore,
      setAnalyticsState,
      setPCPSearchState,
      setChipValue,
      setCoverageType,
      setDetailsStore,
      navigate: undefined,
    });
    onTypeAheadDataLoaded(typeaheadValues);
  } else if (isSearchButtonClickOrKeyboardEnter) {
    const analyticsLinkName = searchLinkName || 'search button';
    const typeaheadLinkName =
      searchLinkName === 'general search'
        ? 'typeahead general search'
        : 'typeahead search';

    const typeaheadValues = handleSearchBarSearchButtonClickOrEnter(
      {
        enableRetriveTypeAheadData,
        enableUESSuggestionMatch,
        typeAheadSuggestions: typeAheadSuggestions || [],
        navigate: undefined,
        headers: undefined,
        analyticsLinkName,
        typeaheadLinkName,
        currentMember,
        userSearchTerm: search,
        combinedRollupCodes,
        aggregatedAoeCodes,
        bhProgramFacilityAgg,
        setIsFocusedOnKeywordSearchInput: () => {},
        convertTypeaheadProviderIdAndType,
        getTypeAheadCategory,
        store: {
          chipStore,
          setTypeaheadSearchStore,
          setAnalyticsState,
          setPCPSearchState,
          setChipValue,
          setCoverageType,
          setDetailsStore,
        },
      },
      specialityPreferenceTable
    );
    if (typeaheadValues.isMixedSuggestions && searchTypeFromParams) {
      typeaheadValues.searchType = searchTypeFromParams;
    }
    onTypeAheadDataLoaded(typeaheadValues);
  }
};
