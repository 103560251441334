import { styled } from '@abyss/web/tools/styled';
import { Card } from '@abyss/web/ui/Card';
import { Flex } from '@abyss/web/ui/Flex';

export const StepDataContainer = styled('div', {
  width: '100%',
});

export const StepIconContainer = styled('div', {
  width: '20px',
  fontSize: '$md',
  fontWeight: '$bold',
  lineHeight: '$md',
  textAlign: 'center',
});

export const StepContainer = styled('div', {
  display: 'flex',
  gap: '8px',
});

export const StepsWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

export const StepsContainter = styled('div', {
  dynamic: ({ cssProps: { mapContainerHeight } }) => ({
    margin: '0 $md',
    minHeight: '80px',
    maxHeight: mapContainerHeight - 230,
    marginBottom: '24px',
    overflowY: 'auto',

    '@screen < $sm': {
      maxHeight: 'calc(100vh - 434px)',
    },
  }),
});

export const TripLocationContainer = styled('div', {
  display: 'none',

  '@screen < $sm': {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    height: 'auto',
    padding: '$md',
  },
});

export const TripLocationDataContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

export const TripDurationContainer = styled('div', {
  dynamic: ({ cssProps: { isWidget } }) => ({
    flexWrap: isWidget ? 'wrap' : 'nowrap',
  }),
  padding: '4px',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',

  margin: '0 15px',
});

export const DurationDistanceWrapper = styled('div', {
  dynamic: ({ cssProps: { isWidget } }) => ({
    maxWidth: isWidget ? '' : '260px',
    marginRight: isWidget ? '18px' : '',
  }),
  display: 'flex',
  flexWrap: 'wrap',
  gap: '4px',
});

export const DuratonContainer = styled('div', {
  fontWeight: '$bold',
  fontSize: '$md',
  lineHeight: '$md',
  color: '$gray8',
});

export const DistanceContainer = styled('div', {
  fontWeight: '$bold',
  fontSize: '$md',
  lineHeight: '$md',
  color: '$gray8',

  '@screen < $sm': {
    display: 'none',
  },
});
export const DistanceContainerWidget = styled('div', {
  fontWeight: '$bold',
  fontSize: '$md',
  lineHeight: '$md',
  color: '$gray8',
});

export const RouteInstructionsContainer = styled('div', {});

export const ArrowContainer = styled('div', {
  margin: 'auto',
});

export const SeeDirectionsContainer = styled('button', {
  height: '34px',
  cursor: 'pointer',
  alignItems: 'center',
  display: 'flex',
  padding: '8px 0',
  gap: '4px',
  fontWeight: '$bold',
});

export const HorizontalDivider = styled('div', {
  width: '100%',
  height: '1px',
  boxShadow: '0px 2px 6px 0px #19191A29',
  background: '$gray4',
  marginTop: '0',
});

export const VerticalDivider = styled('div', {
  width: '.5px',
  height: '24px',
  background: '$gray4',
  margin: 'auto',
  border: '0.5px solid $gray4',
});

export const RouteDetailsContainer = styled('div', {
  display: 'none',
  flexDirection: 'column',
  gap: '16px',

  '@screen < $sm': {
    display: 'flex',
  },
});

export const DetailsContainer = styled('div', {
  background: '$neutralGray2',
  padding: '$md',
  borderRadius: '8px',
  margin: '$md',
});

export const RoutesContainer = styled('div', {
  margin: '0 $md',
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  padding: '$sm 0',
  borderBottom: '1px solid $gray4',
});

export const RoutesTabContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  background: '$gray2',
  padding: '0 6px',
  height: '40px',
  borderRadius: '4px',
});

export const RoutesTab = styled('button', {
  fontWeight: '$bold',
  fontSize: '$md',
  lineHeight: '$md',
  color: '$gray6',
  height: '30px',
  padding: '6px 12px',
  borderRadius: '4px',
});

export const MapContainer = styled(Card, {
  dynamic: ({
    cssProps: {
      enableMapEnhancements,
      fullWidth,
      clusterStatus,
      isOpenCompareShare,
      mobileRouteView,
      hasRoute,
      isWidget,
    },
  }) => ({
    position: 'relative',
    overflow: 'hidden',
    width: fullWidth ? '100% !important' : '',
    '@screen < $sm': {
      height: mobileRouteView
        ? enableMapEnhancements
          ? 'calc(100vh - 60px)'
          : 'calc(100vh - 90px)'
        : '',
      overflowY: isWidget ? 'hidden' : 'auto',
    },
    '.mapboxgl-control-container': {
      display: 'none',
    },
    '.mapboxgl-canvas': {
      '&:focus': {
        outlineColor: '$interactive1',
        outlineWidth: '2px',
        outlineOffset: '-2px',
        outlineStyle: 'solid',
      },
    },
    '.map-container': {
      position: 'relative',
      height: isOpenCompareShare
        ? 'calc(100vh - 220px)'
        : 'calc(100vh - 187px)',
      '@screen < $sm': {
        top: mobileRouteView ? '0px' : '',
        height: enableMapEnhancements
          ? 'calc(100vh - 60px)'
          : 'calc(100vh - 90px)',
      },
    },
    '.mapboxgl-map': {
      font: 'unset ! important',
    },
    '.map-icon-selected': {
      filter:
        'brightness(0) saturate(100%) invert(20%) sepia(61%) saturate(3769%) hue-rotate(208deg) brightness(91%) contrast(91%)',
      height: '100%',
      width: '100%',
    },

    '.mapboxgl-popup': {
      maxWidth: '100% !important',
      paddingBottom: enableMapEnhancements
        ? '24px !important'
        : clusterStatus
        ? '0px'
        : '40px !important',
      paddingTop: enableMapEnhancements
        ? '16px'
        : clusterStatus
        ? '24px'
        : '0px',
      display: hasRoute ? 'none' : 'flex',

      '.mapboxgl-popup-content': {
        boxShadow: clusterStatus
          ? 'none !important'
          : '0 1px 2px rgba(0,0,0,.1)',
        background: 'transparent !important',
        padding: '0 !important',
      },
    },
  }),
});

export const MapDirectionsContainer = styled('div', {
  dynamic: ({ cssProps: { isLocationsTab, mobileScreen } }) => ({
    marginTop: !isLocationsTab && !mobileScreen ? '8px' : '',
  }),
  background: 'white',
});

export const NavigationContainer2 = styled('div', {
  dynamic: ({ cssProps: { isLocationsTab, mobileScreen, hasRoute } }) => ({
    left: hasRoute && mobileScreen ? '0' : '16px',
    top: mobileScreen ? 0 : isLocationsTab ? '$md' : '$lg',
    background: isLocationsTab ? '$white' : '',
  }),
  position: 'absolute',
  zIndex: 1,
  '@screen < $md': {
    border: '0',
    borderRadius: '0',
    fontFamily: 'sans-serif',
    left: 0,
    overflowY: 'hidden',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  '@screen < $sm': {
    maxWidth: '100%',
  },
});

export const NavigationContainer = styled('div', {
  dynamic: ({ cssProps: { hasRoute, enableMapEnhancements } }) => ({
    background: hasRoute ? '$white' : 'transparent',
    boxShadow: hasRoute ? '0px 2px 6px rgba(25, 25, 26, 0.16)' : 'none',
    display: enableMapEnhancements && !hasRoute ? 'flex' : 'block',
    maxWidth: enableMapEnhancements && !hasRoute ? '' : '408px',
    top: enableMapEnhancements ? '50px' : '$lg',
    left: enableMapEnhancements ? (hasRoute ? '0' : '16px') : '80px',
  }),
  position: 'absolute',
  zIndex: 1,

  '@screen < $md': {
    border: '0',
    borderRadius: '0',
    fontFamily: 'sans-serif',
    left: 0,
    overflowY: 'hidden',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  '@screen < $sm': {
    maxWidth: '100%',
  },
});

export const MapCarousel = styled({
  '.abyss-carousel-root': {
    width: '408px',
    '@screen < $sm': {
      width: '320px',
    },
  },
  '.abyss-carousel-minimal-controls': {
    width: '220px !important',
    borderRadius: ' 0px 0px 8px 8px',
    backgroundColor: 'white',
    margin: 'auto',
    display: 'none',
  },
  '.abyss-slide-container': {
    backgroundColor: 'transparent',
  },
  'abyss-carousel-minimal-nav-container-right': {
    width: '20px',
    height: '20px',
    padding: '0px',
    top: '-3px',
  },
  'abyss-carousel-minimal-nav-container-left': {
    width: '20px',
    height: '20px',
    padding: '0px',
    top: '-3px',
  },
});

export const MapControlContainer = styled(Flex, {
  top: '$md',
  gap: '12px',
  position: 'absolute',
  zIndex: 1,
});

export const ZoomButton = styled(Flex, {
  background: '$primary2',
  width: '48px',
  height: '88px',
  borderRadius: '100px',
  gap: '$md',
  padding: '3px',
});
