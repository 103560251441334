import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { Layout } from '@abyss/web/ui/Layout';
import { Pagination, ResultCount } from '@abyss/web/ui/Pagination';
import { Text } from '@abyss/web/ui/Text';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { ChoosePCPAlert } from '../../../../common/Alert/ChoosePCPAlert';
import {
  Constants,
  CoverageTypesCodes,
  ReverseCoverageTypesCodes,
} from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { FilterButtons } from '../../../../common/ContentFilters/FilterButtons';
import { DataCardContainer } from '../../../../common/DataCard/DataCardContainer';
import { EAPCode } from '../../../../common/EAPCode';
import { getFeatureFlag } from '../../../../common/Utils';
import { useCoverageType } from '../../../../hooks/useCoverageType';
import { useLagoon } from '../../../../hooks/useLagoon';
import { CompareProvider } from '../../../../models/Provider';
import { ResponseHeaders } from '../../../../models/ResponseHeaders';
import { AllPaginationProps } from '../MapView/AllPaginationProps';
import { DataCardList, DataCardListStyled } from '../MapView/DataCardList';
import { MapViewTopPagination } from '../MapView/MapViewTopPagination';
import { DownloadResultsButton } from './DownloadResultsButton';
import { ShareCompareButton } from './ShareCompareButton';
import { EnhancePaginationMobileStyles } from './ViewAll.style';

const EAPStyle = styled('div', {
  padding: '4px 0 12px 0',
});

const TitleContainer = styled('div', {
  alignItems: 'center',
});

const ListViewWrapper = styled('div', {
  dynamic: ({ cssProps: { openMapview, navigateToDirections } }) => ({
    display: openMapview || navigateToDirections ? 'none' : 'block',
  }),
});

type Props = {
  heading: string;
  headers: ResponseHeaders;
  openCompare?: boolean;
  isLoading?: boolean;
  openShare?: boolean;
  pageSize?: number;
  paginationProps?: any;
  gotoPage?: any;
  nextPage?: any;
  previousPage?: any;
  results?: any[];
  search?: string | null;
  searchTerm?: string;
  sectionType?: string;
  selectedCheckbox?: any;
  totalResultsCount?: number;
  addSelectedProvider: (a: any) => void;
  selectedFilters?: string;
  setOpenCompare: (a: boolean) => void;
  setOpenMapview: (a: boolean) => void;
  setOpenShare: (a: boolean) => void;
  setSelectedCheckbox: (a: { checked: {} }) => void;
  setSelectedItems: (a: CompareProvider[]) => void;
  allPaginationProps: AllPaginationProps;
  navigateToDirections?: boolean;
  setNavigateToDirections?: (a: boolean) => void;
  map: any;
  openMapview: boolean;
  mobileRouteView?: boolean;
  selectLocation?: (locationId, locationLng, locationLat) => void;
  isMNR?: boolean;
  setOpenBoxContents: (a: boolean) => void;
  enableMapViewEnhancements: boolean;
  choosePCP: boolean;
};

export const MobileListView = ({
  heading,
  headers,
  openCompare = false,
  openShare = false,
  isLoading = false,
  pageSize = 10,
  paginationProps,
  results = [],
  search,
  sectionType,
  selectedCheckbox = { checked: {} },
  totalResultsCount = 0,
  gotoPage,
  nextPage,
  previousPage,
  addSelectedProvider,
  selectedFilters,
  setOpenCompare,
  setOpenShare,
  setOpenMapview,
  setSelectedCheckbox,
  setSelectedItems,
  allPaginationProps,
  searchTerm,
  navigateToDirections = false,
  setNavigateToDirections,
  map,
  openMapview = false,
  mobileRouteView = false,
  selectLocation,
  isMNR,
  setOpenBoxContents,
  enableMapViewEnhancements,
  choosePCP,
}: Props) => {
  const { t } = useTranslation();

  const translatedHeadingText = heading?.length
    ? t(`${heading}`)
    : t(`${search}`) || '';
  const pageHeader = `${t(
    `${sectionType}-results-for`
  )} ${translatedHeadingText}`;
  const mapViewSectionType = sectionType?.toLowerCase();
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const { pageSizeOptions, setPageSize, setLastPageSize, pageNumber } =
    allPaginationProps;
  const coverageType = useCoverageType();
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const eapCodeFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.EAP_CODE
  );
  const isOpenCompareShare = openCompare || openShare;
  const url = window.location.href;
  const urlCheck: boolean =
    url?.includes('findcare-') || url?.includes('localhost') ? false : true;
  const isBHCare: boolean =
    coverageType ===
    ReverseCoverageTypesCodes[CoverageTypesCodes.BEHAVIORAL_HEALTH];
  const topPaginationRef = useRef<HTMLDivElement>(null);
  const mapViewViewAllLinkTrack = (name: String, loc: String) => {
    adobeLinkTrackEvent({
      name,
      location: loc,
      type: 'internal',
      destinationUrl: '',
    });
  };
  const previousPageTop = () => {
    mapViewViewAllLinkTrack(
      'previous page',
      `body:top pagination control:page ${pageNumber}`
    );
    previousPage();
  };

  const setPageSizeFunc = (pageSizeValue) => {
    mapViewViewAllLinkTrack(
      `results per page:${pageSizeValue}`,
      `body:top pagination control:page ${pageNumber}`
    );
    setLastPageSize(pageSize);
    setPageSize(pageSizeValue);
    gotoPage(0);
  };

  const enablePagination: boolean = totalResultsCount < pageSize + 1;
  useEffect(() => {
    if (openMapview && map?.current) map.current.resize();
  }, [openMapview]);

  return (
    <ListViewWrapper
      cssProps={{
        openMapview,
        navigateToDirections,
      }}
    >
      {!enableMapViewEnhancements && (
        <>
          <ChoosePCPAlert isMapView />
          <TitleContainer>
            <Heading
              css={{
                '&.abyss-heading-root': {
                  fontSize: '$mediumText',
                  lineHeight: '$lg',
                },
              }}
              data-auto-testid="results-heading"
              data-testid="results-heading"
              offset={3}
              textAlign="left"
            >
              {pageHeader}
            </Heading>
          </TitleContainer>
        </>
      )}
      <FilterButtons
        enableMapViewEnhancements={enableMapViewEnhancements}
        openCompare={openCompare}
        openShare={openShare}
        results={results}
        sectionType={sectionType || ''}
        setOpenBoxContents={setOpenBoxContents}
        setOpenCompare={setOpenCompare}
        setOpenMapview={setOpenMapview}
        setOpenShare={setOpenShare}
        setSelectedCheckbox={setSelectedCheckbox}
        setSelectedItems={setSelectedItems}
      />
      <Layout.Stack
        alignItems="left"
        css={
          enableMapViewEnhancements &&
          !isOpenCompareShare &&
          EnhancePaginationMobileStyles
        }
        grow="true"
      >
        {!enableMapViewEnhancements && eapCodeFlag && isBHCare ? (
          <EAPStyle css={{ paddingLeft: '24px' }}>
            <EAPCode />
          </EAPStyle>
        ) : null}
        {isLoading ? (
          <DataCardListStyled
            data-auto-testid="skeleton-wrapper"
            data-testid="skeleton-wrapper"
          >
            {Array.from(Array(10).keys()).map((item) => (
              <DataCardContainer
                fromMobileListView
                isLoading={isLoading}
                key={item}
              />
            ))}
          </DataCardListStyled>
        ) : (
          <React.Fragment>
            {enableMapViewEnhancements ? (
              <Flex
                css={{
                  display: 'grid',
                  margin: '0 0 6px 0 ',
                  padding: '16px 0 0 24px',
                  position: 'sticky',
                  top: choosePCP
                    ? urlCheck
                      ? '224px'
                      : '184px'
                    : urlCheck
                    ? '152px'
                    : '112px',
                  zIndex: '2',
                  backgroundColor: 'white',
                }}
              >
                <Text
                  css={{
                    'abyss-text-root': {
                      marginBottom: '8px',
                      width: '375px',
                    },
                  }}
                  data-auto-testid="total-count-results-heading"
                  data-testid="total-count-results-heading"
                >
                  {!enablePagination ? (
                    <ResultCount
                      {...paginationProps}
                      css={{
                        'abyss-pagination-results-text': {
                          border: '0px',
                          margin: '0 2px ',
                          fontSize: '14px !important',
                          lineHeight: '18px !important',
                          fontWeight: '400 !important',
                          color: '#4B4D4F',
                        },
                      }}
                      customNoResultsLabel={t('PAGINATION.NO_RESULTS_LABEL')}
                      customOfLabel={t('PAGINATION.OF_LABEL')}
                      customResultLabel={t(`${mapViewSectionType} result for`)}
                      customResultsLabel={t(
                        `${mapViewSectionType} results for`
                      )}
                      customShowingLabel={' '}
                      pageSize={pageSize}
                      resultsTotalCount={totalResultsCount}
                    />
                  ) : (
                    `${totalResultsCount} ${t('of')} ${totalResultsCount} ${t(
                      `${mapViewSectionType} results for`
                    )}`
                  )}
                </Text>
                <TitleContainer
                  css={{ alignItems: enableMapViewEnhancements && 'start' }}
                >
                  <Heading
                    css={{
                      '&.abyss-heading-root': {
                        fontSize: '$mediumText',
                        lineHeight: '$lg',
                        paddingBottom: '4px',
                        width: '375px',
                      },
                    }}
                    data-auto-testid="results-heading"
                    data-testid="results-heading"
                    offset={3}
                    textAlign="left"
                  >
                    {translatedHeadingText}
                  </Heading>
                </TitleContainer>
                <Flex
                  css={{
                    paddingBottom: '10px',
                    '.abyss-text-root': {
                      fontSize: '14px',
                      lineHeight: '18px',
                    },
                    '.abyss-icon-material': {
                      height: '18px',
                      width: '18px',
                    },
                  }}
                >
                  {sectionType === Constants.RESULT_SECTION.PROVIDER && (
                    <ShareCompareButton
                      enableMapViewEnhancements={enableMapViewEnhancements}
                      isShareButton={false}
                      openCompare={openCompare}
                      sectionType={sectionType}
                      setIsClicked={setIsClicked}
                      setOpenCompare={setOpenCompare}
                      setOpenShare={setOpenShare}
                      setSelectedCheckbox={setSelectedCheckbox}
                      setSelectedItems={setSelectedItems}
                      text={t('COMPARE_BUTTONS.COMPARE')}
                      view="enhancement"
                    />
                  )}
                  <ShareCompareButton
                    enableMapViewEnhancements={enableMapViewEnhancements}
                    openShare={openShare}
                    sectionType={sectionType}
                    setIsClicked={setIsClicked}
                    setOpenCompare={setOpenCompare}
                    setOpenShare={setOpenShare}
                    setSelectedCheckbox={setSelectedCheckbox}
                    setSelectedItems={setSelectedItems}
                    text={t('COMPARE_BUTTONS.SHARE')}
                    view="enhancement"
                  />
                  <DownloadResultsButton
                    enableMapViewEnhancements={enableMapViewEnhancements}
                    isClicked={isClicked}
                    results={results}
                    setIsClicked={setIsClicked}
                    setOpenBoxContents={setOpenBoxContents}
                    setOpenCompare={setOpenCompare}
                    setOpenShare={setOpenShare}
                    setSelectedCheckbox={setSelectedCheckbox}
                    setSelectedItems={setSelectedItems}
                    text={t('COMPARE_BUTTONS.DOWNLOAD_RESULTS')}
                    view="enhancement"
                  />
                </Flex>
              </Flex>
            ) : (
              <ResultCount
                {...paginationProps}
                css={{
                  'abyss-pagination-results-root': {
                    fontWeight: '$semibold',
                  },
                }}
                customNoResultsLabel={t('PAGINATION.NO_RESULTS_LABEL')}
                customOfLabel={t('PAGINATION.OF_LABEL')}
                customResultLabel={t('PAGINATION.ONE_RESULT_LABEL')}
                customResultsLabel={t('PAGINATION.RESULTS_LABEL')}
                customShowingLabel={t('PAGINATION.SHOWING_LABEL')}
                pageSize={pageSize}
                resultsTotalCount={totalResultsCount}
              />
            )}
            <Flex
              css={{ paddingLeft: enableMapViewEnhancements ? '24px' : '0px' }}
            >
              <DataCardList
                addSelectedProvider={addSelectedProvider}
                enableMapViewEnhancements={enableMapViewEnhancements}
                fromMobileListView
                headers={headers}
                isLoading={isLoading}
                isMNR={isMNR}
                locationForAnalytics={`${sectionType} results for ${search}`}
                map={map}
                mobileRouteView={mobileRouteView}
                navigateToDirections={navigateToDirections}
                pageNumber={pageNumber}
                providerResults={results}
                searchTerm={searchTerm}
                sectionType={sectionType || ''}
                selectLocation={selectLocation}
                selectedCheckbox={selectedCheckbox}
                selectedFilters={selectedFilters}
                setNavigateToDirections={setNavigateToDirections}
                setSelectedCheckbox={setSelectedCheckbox}
                showCheckboxes={openShare || openCompare}
                updatePin={() => null}
                usedAt={Constants.MOBILE_LIST_VIEW}
              />
            </Flex>
          </React.Fragment>
        )}
        <Flex css={{ paddingLeft: enableMapViewEnhancements ? '24px' : '0px' }}>
          <Layout.Space space={20} />
          <Flex
            css={{
              gap: '21px',
              paddingTop: '24px',
              alignItems: enableMapViewEnhancements ? 'start' : 'center',
            }}
            direction="column"
          >
            {enableMapViewEnhancements ? (
              <Flex>
                {!enablePagination && (
                  <MapViewTopPagination
                    enableMapViewEnhancements={enableMapViewEnhancements}
                    isLoading={isLoading}
                    nextPageTop={nextPage}
                    pageSize={pageSize}
                    pageSizeOptions={pageSizeOptions}
                    paginationProps={paginationProps}
                    previousPageTop={previousPageTop}
                    setPageSizeFunc={setPageSizeFunc}
                    topPaginationRef={topPaginationRef}
                  />
                )}
              </Flex>
            ) : (
              <Pagination
                {...paginationProps}
                css={{
                  'abyss-pagination-page-button': {
                    minWidth: '20px',
                  },
                  'abyss-pagination-arrow': {
                    paddingLeft: '4px',
                    paddingRight: '4px',
                  },
                }}
                customNoResultsLabel={t('PAGINATION.NO_RESULTS_LABEL')}
                customOfLabel={t('PAGINATION.OF_LABEL')}
                customResultLabel={t('PAGINATION.ONE_RESULT_LABEL')}
                customResultsLabel={t('PAGINATION.RESULTS_LABEL')}
                customShowingLabel={t('PAGINATION.SHOWING_LABEL')}
                data-auto-testid="pagination-component"
                data-testid="pagination-component"
                gotoPage={gotoPage}
                nextPage={nextPage}
                pageSize={pageSize}
                previousPage={previousPage}
              />
            )}
          </Flex>
        </Flex>
      </Layout.Stack>
    </ListViewWrapper>
  );
};
