import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StoreKeys } from '../../../hooks/useStore/state';
import { useStore } from '../../../hooks/useStore/useStore';
import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import { phoneOnly } from '../../ConstantsStyles';

type Props = {
  text: string;
  title?: string;
  label: string;
  gap?: string;
  isNotAvailableUnknownTextEnabled?: boolean;
};
export const CopyToClipBoard = ({
  text,
  title,
  label,
  gap = '6px',
  isNotAvailableUnknownTextEnabled = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const phoneScreen = useMediaQuery(phoneOnly) || isWidget;
  useEffect(() => {
    let time;
    if (isOpen) {
      time = setTimeout(() => setIsOpen(false), 6000);
    }
    return () => clearTimeout(time);
  }, [isOpen, setIsOpen]);

  const copy = () => {
    navigator.clipboard.writeText(text);
    setIsOpen(true);
  };

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Popover
        align="start"
        aria-label={label}
        content={
          <Flex
            css={{
              backgroundColor: '$gray8',
              color: '#ffffff',
            }}
            justify="space-between"
          >
            <Flex
              css={{
                color: '#ffffff',
                gap: '10px',
                fontSize: '14px',
                width: '270px',
                flexWrap: 'nowrap',
              }}
            >
              <IconMaterial color="#ffffff" icon="check_circle" size={22} />
              <Text
                css={{
                  '&.abyss-text-root': { fontSize: '14px', fontWeight: '500' },
                  color: '#ffffff',
                }}
              >
                {t('COPY_TO_CLIPBOARD')}
              </Text>
            </Flex>
            <IconMaterial
              color="#ffffff"
              css={{ cursor: 'pointer' }}
              data-auto-testid="popover-close"
              data-testid="popover-close"
              icon="close"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              size={22}
            />
          </Flex>
        }
        css={{
          '.abyss-popover-arrow': { display: 'none !important' },
          '.abyss-popover-trigger': {
            display: 'block',
          },
          '&.abyss-popover-content': {
            width: '311px',
            padding: '12px',
            gap: '8px',
            borderRadius: '8px',
            backgroundColor: '$gray8',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            '& .abyss-theme-provider-root': {
              display: 'inline-flex',
            },
          },
          '& .abyss-popover-content-container': {
            '& .abyss-flex-root': { flexWrap: 'nowrap' },
          },
        }}
        data-auto-testid="copy-to-clipboard-popover"
        data-testid="copy-to-clipboard-popover"
        onOpenChange={() => {
          setIsOpen(!isOpen);
          if (!isOpen) {
            adobeLinkTrackEvent({
              name: 'copy clipboard',
              location: `body:${title}`,
              type: 'internal',
            });
          }
        }}
        open={isOpen}
        position="bottom"
        showClose={false}
      >
        <IconMaterial
          css={{
            marginLeft: gap,
          }}
          data-auto-testid={`${text}-copy-clipboard`}
          data-testid={`${text}-copy-clipboard`}
          icon="content_copy"
          onClick={copy}
          size={phoneScreen || isNotAvailableUnknownTextEnabled ? 20 : 24}
        />
      </Popover>
    </React.Fragment>
  );
};
