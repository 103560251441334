import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Markdown } from '@abyss/web/ui/Markdown';
import { Modal } from '@abyss/web/ui/Modal';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../../../common/Utils';
import { useLagoon } from '../../../../hooks/useLagoon';
import { useNotAvailableUnknownText } from '../../../../hooks/useNotAvailableUnknownText';
import { phoneOnly } from '../../../ConstantsStyles';
import { CulturalCompetencyTraining } from '../../../modal/CulturalCompetencyTraining';
import { ListItem } from './CollapseProviderDetailsSection';

export const ModalContent = styled(Markdown, {
  fontSize: '14px',
  lineHeight: '18px',
  color: '#323334',
});

export const ModalContainer = styled(Modal, {
  '.abyss-modal-content-container': {
    width: 'auto',
    height: 'auto',
    maxWidth: '572px',
    maxHeight: '900px',
  },
  '.abyss-modal-content-title': {
    lineHeight: '40px',
    fontSize: '32.43px',
    fontWeight: '600',
    '@screen < $sm': {
      lineHeight: '36px',
      fontSize: '28.83px',
      fontWeight: '600',
    },
  },
});

type Competence = {
  category?: string;
  description?: string;
};

type Props = {
  culturalCompetencies?: Competence[] | string[];
  mobileScreen: boolean;
  isProviderExperienceSectionEnabled?: boolean;
};
export const ListCulturalCompetencies = ({
  culturalCompetencies = [],
  mobileScreen,
  isProviderExperienceSectionEnabled,
}: Props) => {
  const { t } = useTranslation();
  const phoneScreen = useMediaQuery(phoneOnly);
  const featureFlags = useLagoon('feature-flags')();
  const isEnableL2CulturalCompetencies = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_L2_CULTURAL_COMPETENCIES
  );
  const enableCulturalCompetency = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_CULTURAL_COMPETENCY_TRAINING_SECTION
  );

  const [isOpen, setIsOpen] = useState(false);

  const { providerDetails: notAvailableOrUnknownText } =
    useNotAvailableUnknownText();

  return (
    <React.Fragment>
      {enableCulturalCompetency ? (
        <Flex
          css={{
            width: '320px',
          }}
          direction="row"
        >
          <b data-testid="cultural-competency-heading">
            {t(
              'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.CULTURAL_COMPETENCY_TRAINING'
            )}
          </b>

          <Flex
            aria-label="Tooltip information"
            css={{
              marginLeft: '4px',
              display: 'flex',
              cursor: 'pointer',
            }}
            data-testid="cultural-competency-tooltip-button"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <IconMaterial
              css={{
                'abyss-icon-material': {
                  '@screen < $sm': {
                    marginTop: '3px',
                  },
                },
              }}
              icon="info"
              size={phoneScreen ? 18 : 24}
              variant="outlined"
            />
          </Flex>
          <CulturalCompetencyTraining isOpen={isOpen} setIsOpen={setIsOpen} />
        </Flex>
      ) : (
        <b>
          {t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.CULTURAL_COMPETENCE')}
        </b>
      )}
      {isProviderExperienceSectionEnabled ? (
        <>
          {culturalCompetencies?.length > 0 &&
            isEnableL2CulturalCompetencies && (
              <ListItem color="#4B4D4F">
                {culturalCompetencies?.map((competence) => (
                  <li
                    data-testid="provider-experience-section-cultural-competence"
                    key={competence}
                  >{`${competence?.category}: ${competence?.description}`}</li>
                ))}
              </ListItem>
            )}
          {culturalCompetencies?.length > 0 &&
            !isEnableL2CulturalCompetencies && (
              <ListItem color="#4B4D4F">
                {culturalCompetencies?.map((competence) => (
                  <li
                    data-testid="provider-experience-section-cultural-competence"
                    key={competence}
                  >
                    {competence}
                  </li>
                ))}
              </ListItem>
            )}
          {!culturalCompetencies && (
            <ListItem color="#4B4D4F">
              <li data-testid="provider-experience-section-cultural-competence">
                {notAvailableOrUnknownText}
              </li>
            </ListItem>
          )}
        </>
      ) : (
        <Layout.Stack alignItems="left" space={2}>
          {culturalCompetencies?.length > 0 &&
            isEnableL2CulturalCompetencies &&
            culturalCompetencies?.map(
              (competence) =>
                `${competence?.category}: ${competence?.description}`
            )}
          {culturalCompetencies?.length > 0 &&
            !isEnableL2CulturalCompetencies &&
            culturalCompetencies?.map((competence) => `${competence}`)}
          {(culturalCompetencies == null ||
            culturalCompetencies.length == 0) && (
            <Text
              color="#4B4D4F"
              fontWeight="$medium"
              size={mobileScreen ? '$md' : '18px'}
            >
              {notAvailableOrUnknownText}
            </Text>
          )}
        </Layout.Stack>
      )}
    </React.Fragment>
  );
};
