import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Text } from '@abyss/web/ui/Text';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProviderLocation } from '../../models/ProviderDetails';
import { ContentWrapper } from '../ContentWrapper';
import { ContainerForAllSectionsStyled } from '../ProviderDetailTabs/ProviderDetailTabs.styled';
import { SendFeedbackFormDrawer } from '../SendFeedbackFormDrawer';

interface ProviderData {
  name: string | undefined;
  address: Record<string, any>;
  npi: string | undefined;
  mpin: string | undefined;
  providerType: string;
}

interface Props {
  data: ProviderData | null;
  type: string;
  coverageType: string;
  enableNewFeedbackFormDesign?: boolean;
  selectedIndex?: number;
  providerLocations?: ProviderLocation[];
}
export const SendFeedback = ({
  type = '',
  data,
  coverageType = '',
  enableNewFeedbackFormDesign,
  selectedIndex,
  providerLocations,
}: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { name, address, npi, mpin, providerType = '' } = data || {};

  const providerTypeFormat = {
    Organization: 'ORGANIZATION',
    Practitioner: 'PRACTITIONER',
    'Medical Group': 'MEDICAL_GROUP',
  };

  const location: string =
    `${address?.line?.[0]}, ${address?.city}, ${address?.state} ${address?.postalCode}` ||
    '';

  const providerData = {
    name,
    npi,
    location,
    mpin,
    providerType: providerTypeFormat[providerType],
  };

  return (
    <React.Fragment>
      <ContainerForAllSectionsStyled
        css={
          !enableNewFeedbackFormDesign
            ? {
                backgroundColor: '#F4F6FA',
                paddingTop: '18px',
                borderBottom: 0,
                '& > div': {
                  paddingBottom: 0,
                },
              }
            : { border: 'none' }
        }
      >
        <ContentWrapper
          style={
            enableNewFeedbackFormDesign
              ? { display: 'flex', alignItems: 'center', padding: 0, margin: 0 }
              : {}
          }
        >
          <Text
            css={
              enableNewFeedbackFormDesign
                ? {
                    display: 'flex',
                    fontWeight: 600,
                    fontSize: 14,
                    marginRight: 8,
                  }
                : { display: 'block', fontWeight: 700 }
            }
          >
            {enableNewFeedbackFormDesign
              ? t('FEEDBACK_FORM.OUT_OF_DATE_INFO_NEW_DESIGN')
              : t('FEEDBACK_FORM.OUT_OF_DATE_INFO')}
          </Text>
          {enableNewFeedbackFormDesign ? (
            <Flex
              css={{ cursor: 'pointer' }}
              data-testid="send-feedback-button"
              onClick={() => setIsOpen(true)}
            >
              <IconSymbol icon="flag" size="20px" />
              <Text
                color="$interactive1"
                css={{ fontWeight: 600, fontSize: 14, marginLeft: 4 }}
              >
                {t('FEEDBACK_FORM.SEND_FEEDBACK')}
              </Text>
            </Flex>
          ) : (
            <Button
              aria-label="send-feedback"
              css={{ marginTop: '8px' }}
              data-testid="send-feedback-button"
              onClick={() => setIsOpen(true)}
              size="$md"
              variant="outline"
            >
              {t('FEEDBACK_FORM.SEND_FEEDBACK')}
            </Button>
          )}
        </ContentWrapper>
      </ContainerForAllSectionsStyled>
      {isOpen && (
        <SendFeedbackFormDrawer
          coverageType={coverageType}
          enableNewFeedbackFormDesign={enableNewFeedbackFormDesign}
          isOpen={isOpen}
          providerData={providerData}
          providerLocations={providerLocations}
          selectedIndex={selectedIndex}
          setIsOpen={setIsOpen}
          type={type}
        />
      )}
    </React.Fragment>
  );
};
