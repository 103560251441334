// Breakpoints and media query
export const sm = 464;
export const md = 744;
export const lg = 984;
export const xl = 1248;

export const sixCardsCarouselBreakPoint = 1207;
export const carouselBreakPoint = 1191;
export const fourCardsCarouselBreakPoint = 983;
export const threeCardsCarouselBreakPoint = 775;
export const twoCardsCarouselBreakPoint = 567;

export const threeRecentSearchCardsBreakPoint = 572;
export const fourRecentSearchCardsBreakPoint = 772;
export const fiveRecentSearchCardsBreakPoint = 956;

export const benefitsCarouselBreakPoint = 1439;
export const quickCareCarouselBreakPoint = 1360;
export const resultsCarouselBreakPoint = 1442;
export const compareCarouselBreakPoint = 1383;
export const compareExpandedCarouselBreakPoint = 1389;
export const reviewsFourCardsCarouselBreakPoint = 1463;
export const reviewsThreeCardsCarouselBreakPoint = 1139;
export const reviewsTwoCardsCarouselBreakPoint = 815;

export const reviewsTwoCardsCarouselScreen = `(max-width: ${reviewsTwoCardsCarouselBreakPoint}px)`;
export const reviewsThreeCardsCarouselScreen = `(max-width: ${reviewsThreeCardsCarouselBreakPoint}px)`;
export const reviewsFourCardsCarouselScreen = `(max-width: ${reviewsFourCardsCarouselBreakPoint}px)`;
export const expandedCarouselScreen = `(max-width: ${compareExpandedCarouselBreakPoint}px)`;
export const compareCarouselScreen = `(max-width: ${compareCarouselBreakPoint}px)`;
export const resultsCarouselScreen = `(max-width: ${resultsCarouselBreakPoint}px)`;
export const benefitsCarouselScreen = `(max-width: ${benefitsCarouselBreakPoint}px)`;
export const quickCareCarouselScreen = `(max-width: ${quickCareCarouselBreakPoint}px)`;
export const crisisSupportCarouselScreen = `(max-width: ${carouselBreakPoint}px)`;

export const twoCardscarouselScreen = `(max-width: ${twoCardsCarouselBreakPoint}px)`;
export const threeCardscarouselScreen = `(max-width: ${threeCardsCarouselBreakPoint}px)`;
export const fourCardscarouselScreen = `(max-width: ${fourCardsCarouselBreakPoint}px)`;
export const carouselScreen = `(max-width: ${carouselBreakPoint}px)`;
export const carouselMinScreen = `(min-width: ${carouselBreakPoint + 1}px)`;
export const sixCardscarouselScreen = `(max-width: ${sixCardsCarouselBreakPoint}px)`;

export const threeRecentSearchCardsScreen = `(max-width: ${threeRecentSearchCardsBreakPoint}px)`;
export const fourRecentSearchCardsScreen = `(max-width: ${fourRecentSearchCardsBreakPoint}px)`;
export const fiveRecentSearchCardsScreen = `(max-width: ${fiveRecentSearchCardsBreakPoint}px)`;

export const phoneOnly = `(max-width: ${sm - 1}px)`;
export const notPhone = `(min-width: ${sm}px)`;
export const mobileOnly = `(max-width: ${md - 1}px)`;
export const notMobile = `(min-width: ${md}px)`;
export const tabletOnly = `(min-width: ${sm}px) and (max-width: ${lg - 1}px)`;
export const desktopOnly = `(min-width: ${lg}px)`;
export const mediumScreenOnly = `(min-width: ${sm}px) and (max-width: 680px)`;

// Common styles
export const cardSubHeaderContainerStyle = {
  'abyss-flex-root': {
    margin: '0 0 12px 0',
    gap: '16px',
    alignItems: 'baseline',
    flexWrap: 'wrap',
    '@screen < $md': {
      justifyContent: 'space-between',
      margin: '0 0 12px 0',
      gap: '10px',
      flexWrap: 'wrap',
    },
    '@screen < $sm': {
      gap: '5px',
      flexWrap: 'wrap',
    },
  },
};

export const cardSubHeaderWithNavigatorStyle = {
  'abyss-heading-root': {
    margin: '0',
    paddingTop: '$md',
    display: 'inline-block',
    '@screen < $md': {
      paddingTop: '0',
      fontSize: '$md !important',
      lineHeight: '$md !important',
    },
  },
};

export const cardHeaderContainerStyle = {
  'abyss-heading-root': {
    marginRight: '16px',
    '@screen < $md': {
      fontSize: '$mediumText !important',
    },
  },
  'abyss-flex-root': {
    margin: '0 0 5 0',
    gap: '10px',
    '@screen < $md': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
};

export const cardHeaderStyle = {
  'abyss-flex-root': {
    margin: '0 0 $md 0',
    gap: '10px',
    '@screen < $md': {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0 0 12px 0',
      flexWrap: 'wrap',
    },
    '@screen < $sm': {
      gap: '5px',
      flexWrap: 'wrap',
    },
  },
};

export const landingCardHeaderStyle = {
  'abyss-flex-root': {
    margin: '0 0 $md 0',
    gap: '10px',
    alignItems: 'baseline',
    flexWrap: 'wrap',
    '@screen < $md': {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0 0 12px 0',
      flexWrap: 'wrap',
    },
    '@screen < $sm': {
      gap: '5px',
      flexWrap: 'wrap',
    },
  },
};

export const viewAllLinkStyle = {
  'abyss-link-root': {
    textWrap: 'nowrap',
  },
};

export const cardHeaderBrowseStyle = {
  'abyss-flex-root': {
    margin: '0 0 4px 0',
    gap: '16px',
    '@screen < $md': {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '10px',
    },
  },
};

export const h2SmallerFontForResponsive = {
  '@screen < $md': {
    '&.abyss-heading-root': {
      fontSize: '20.25px !important',
      lineHeight: '24px !important',
    },
  },
};

export const cardHeaderHeadingStyle = {
  'abyss-heading-root': {
    margin: '0',
    '@screen < $md': {
      fontSize: '$mediumText !important',
    },
  },
};
export const cardHeaderRecentVisitStyle = {
  'abyss-heading-root': {
    lineHeight: '1.5rem !important',
    marginBottom: '12px',
    '@screen >= $md': {
      marginBottom: '$md',
      lineHeight: '2rem !important',
    },
  },
};

export const primaryCareCardHeaderStyle = (isMnr: boolean) => ({
  'abyss-flex-root': {
    margin: '0 0 8px 0',
    gap: '10px',
    '@screen < $md': {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0 0 4px 0',
    },
  },
  ...(isMnr && {
    'abyss-flex-root': {
      margin: '0 0 16px 0',
      gap: '10px',
      '@screen < $md': {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0 0 12px 0',
      },
    },
  }),
});

export const cardHeaderWithNavigatorStyle = {
  'abyss-heading-root': {
    margin: '0',
    '@screen >= $md': {
      lineHeight: '32px !important',
    },
    '@screen < $md': {
      fontSize: '$mediumText !important',
      lineHeight: '$lg !important',
    },
  },
};

export const cardHeaderNullPageStyle = {
  '&.abyss-heading-root': {
    marginBottom: '16px',
    '@screen < $sm': {
      fontSize: '20.25px',
      lineHeight: '24px',
      marginBottom: '12px',
    },
  },
};

export const cardHeaderMyBenefits = {
  'abyss-heading-root': {
    margin: '0',
    lineHeight: '1.5rem !important',
    marginBottom: '12px',
    '@screen >= $md': {
      marginBottom: '$md',
      lineHeight: '2rem !important',
    },
    '@screen < $md': {
      fontSize: '20.25px !important',
    },
  },
};

export const cardHeaderActiveReferral = {
  'abyss-heading-root': {
    margin: '0',
    marginBottom: '4px',
    '@screen >= $md': {
      marginBottom: '$md',
      lineHeight: '2rem !important',
    },
    '@screen < $md': {
      fontSize: '$mediumText !important',
      lineHeight: '$lg !important',
    },
  },
};

export const landingPopOverIcon = {
  'abyss-icon-material': {
    marginBottom: '-0.5px',
    '@screen < $md': {
      marginBottom: '-2.5px',
    },
  },
};
export const infoIconButtonMobile = {
  'abyss-button-root': {
    display: 'inline-block',
    padding: '0px',
    height: '16px',
    marginBottom: '-2.5px',
  },
};
export const infoCostIconButtonMobile = {
  'abyss-button-root': {
    display: 'inline-block',
    padding: '0px',
    height: '20px',
    width: '20px',
  },
};

export const landingDrawerIcon = {
  'abyss-modal-content-container': {
    borderRadius: '20px 20px 0 0',
    height: 'auto !important',
  },
  'abyss-modal-body': {
    margin: '16px 24px 16px 24px',
    color: '$gray8',
    whiteSpace: 'pre-line',
    fontSize: '16px',
    fontWeight: '500',
  },
  'abyss-modal-close-button': { margin: '8px 10px 0 0' },
  'abyss-modal-content-title': {
    paddingTop: '2px',
    fontFamily: '$primary !important',
    fontSize: '20.25px !important',
    color: '#323334 !important',
    lineHeight: '24px !important',
    fontWeight: '700 !important',
  },
  'abyss-modal-header-container': {
    padding: '16px 24px 0px 24px',
  },
};

export const landingDrawerIconPhone = {
  ...landingDrawerIcon,
  'abyss-modal-content-title': {
    ...landingDrawerIcon['abyss-modal-content-title'],
    fontWeight: 600,
    fontSize: '28.83px',
    lineHeight: '36px',
    letterSpacing: '0px',
    color: '#002677',
    fontFamily: '$heading',
  },
  'abyss-modal-body': {
    ...landingDrawerIcon['abyss-modal-body'],
    color: '#4B4D4F',
    fontWeight: '400',
  }
}

export const hcbsLandingDrawerIcon = {
  ...landingDrawerIcon,
  'abyss-modal-content-container': {
    borderRadius: '5px 5px 0 0',
    height: 'auto !important',
    maxHeight:'250px'
  },
  'abyss-modal-close-button': {
     margin: '6px 8px 0 0' },
  'abyss-modal-content-title': {
    ...landingDrawerIcon['abyss-modal-content-title'],
    paddingTop:'16px',
    fontWeight: 600,
    fontSize: '25.63px !important',
    lineHeight: '32px !important',
    letterSpacing: '0px !important',
    color: '#002677 !important',
    fontFamily: '$heading !important',
  },
  'abyss-modal-body': {
    ...landingDrawerIcon['abyss-modal-body'],
    color: '#4B4D4F',
    fontWeight: '400',
  }
}

export const h2StylingForResponsive = {
  marginBottom: '4px',
  '@screen < $md': {
    '&.abyss-heading-root': {
      fontSize: '20.25px !important',
      lineHeight: '24px !important',
      marginBottom: '8px',
    },
  },
};

export const cardHeaderViewAllButtonStyle = {
  'abyss-flex-root': {
    marginTop: '$xs',
  },
};

export const benefitCardHeadingStyle = {
  'abyss-heading-root': {
    marginBottom: '$xs',
    fontSize: '16px !important',
    lineHeight: '20px !important',
    '@screen < $md': {
      fontSize: '14px !important',
      lineHeight: '18px !important',
    },
  },
};
export const browseFacilityHeadingStyle = {
  'abyss-heading-root': {
    marginBottom: '16px',
    '@screen < $md': {
      fontSize: '20.25px !important',
      lineHeight: '24px !important',
    },
  },
};

export const recentVisitsCardStyle = {
  'abyss-card-root': {
    width: '342px',
    borderRadius: '8px',
  },
};

export const recentVisitsCardSectionStyle = {
  'abyss-card-section': {
    padding: '12px $md',
    height: '92px',
  },
};

export const thingsToKnowHeading = {
  'abyss-heading-root': {
    '@screen < $md': {
      fontSize: '20.25px !important',
      lineHeight: '24px !important',
    },
  },
};

export const thingsToKnowInnerHeading = {
  'abyss-heading-root': {
    fontSize: '18px !important',
    lineHeight: '24px !important',
    '@screen < $md': {
      fontSize: '16px !important',
      lineHeight: '20px !important',
    },
  },
};

export const recentActivityDrawerMobile = {
  'abyss-modal-close-button': {
    marginTop: '18px',
    marginRight: '$lg',
  },
  'abyss-modal-content-container': {
    borderRadius: '20px 20px 0px 0px',
    height: 'auto !important',
  },
};

export const recentActivityDrawerDesktop = {
  'abyss-modal-close-button': {
    marginTop: '20px',
    marginRight: '$lg',
  },
};

export const providerShareDrawerMobile = {
  'abyss-modal-close-button': {
    marginTop: '24px',
    marginRight: '$lg',
  },
  'abyss-floating-section-root': {
    borderRadius: '20px 20px 0px 0px',
    padding: '0px 0px 24px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  'abyss-modal-body': {
    alignItems: 'center',
    overflowX: 'unset',
    overflowY: 'unset',
  },
};

export const providerFloatDesktopClosed = {
  'abyss-floating-section-root': {
    borderRadius: '20px 20px 0px 0px',
    boxShadow: '0px -6px 20px rgba(25, 25, 26, 0.16)',
    width: '100%',
    paddingBottom: '24px',
    position: 'fixed',
    bottom: '0',
    zIndex: '2',
    overflowX: 'unset',
    left: 0,
  },
};

export const providerFloatDesktopOpen = {
  'abyss-floating-section-root': {
    borderRadius: '20px 20px 0px 0px',
    boxShadow: '0px -6px 20px rgba(25, 25, 26, 0.16)',
    width: '100%',
    paddingBottom: '24px',
    position: 'fixed',
    bottom: '0',
    zIndex: '999',
    overflowX: 'unset',
    left: 0,
  },
};

export const providerFloatDesktopOpenSelected = {
  'abyss-floating-section-root': {
    borderRadius: '20px 20px 0px 0px',
    boxShadow: '0px -6px 20px rgba(25, 25, 26, 0.16)',
    width: '100%',
    paddingBottom: '24px',
    position: 'fixed',
    bottom: '0',
    zIndex: '2',
    overflowX: 'unset',
    left: 0,
  },
};

export const providerFloatMobile = {
  'abyss-modal-close-button': {
    marginTop: '24px',
    marginRight: '$lg',
  },
  'abyss-floating-section-root': {
    borderRadius: '20px 20px 0px 0px',
    boxShadow: '0px -6px 20px rgba(25, 25, 26, 0.16)',
    width: '100%',
    maxHeight: '100%',
    position: 'fixed',
    bottom: '0',
    zIndex: '3',
    overflowX: 'unset',
    overflowY: 'hidden',
    left: 0,
  },
  'abyss-modal-body': {
    overflowX: 'unset',
    overflowY: 'unset',
  },
};

export const providerShareFloatDesktop = {
  'abyss-modal-close-button': {
    marginTop: '24px',
    marginRight: '$lg',
  },
  'abyss-floating-section-root': {
    borderRadius: '20px 20px 0px 0px',
    boxShadow: '0px -6px 20px rgba(25, 25, 26, 0.16)',
    width: '100%',
    position: 'fixed',
    zIndex: '1',
    overflowX: 'unset',
  },
  'abyss-modal-body': {
    overflowX: 'unset',
    overflowY: 'unset',
  },
};

export const hideScrollbar = {
  '@screen < $sm': {
    paddingLeft: '$md',
    paddingRight: '$md',
  },
  '::-webkit-scrollbar': {
    display: 'none',
  },

  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
};

export const PlatinumBadgeStyle = {
  '.abyss-badge-content': {
    lineHeight: '18px',
  },
};

export const tier1AlertStyle = {
  '.abyss-alert-container': {
    display: 'flex',
    width: '343px',
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    alignSelf: 'stretch',
    borderRadius: '4px',
    border: '1px solid $info1',
    background: '$info2',
  },
  '.abyss-alert-title': {
    color: '$gray8',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
    margin: '0px',
  },
  '.abyss-alert-icon-container': {
    width: '20px',
    height: '20',
  },
  '.abyss-alert-header-container': {
    alignItems: 'center',
  },
};
export const CardButtonStyle = {
  'abyss-button-root': {
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
    },
  },
};
