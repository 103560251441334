import { config } from '@abyss/web/tools/config';
import { t } from 'i18next';
/* istanbul ignore file */
/* eslint-disable no-param-reassign */
import mapboxgl from 'mapbox-gl';

import { handleLinkAndModalTrack } from '../common/Utils/adobeTrackUtils';
import { Provider } from '../models/Provider';
import { Directions } from '../models/RouteDirections';
import { RESULT_LAYER, ROUTE_LAYER, USER_LAYER } from './generalMap.utils';
import { getLocale } from './providerSearch.utils';

export const USER_LOCATION_PNG = `${config(
  'CDN_BASE_URL'
)}/cdn/assets/images/user_location.png`;
export const RESULT_LOCATION_PNG = `${config(
  'CDN_BASE_URL'
)}/cdn/assets/images/location_pin.png`;

let clickResultId = null;
let hoverResultId = null;
let curNavType = 'driving-traffic';
let mapboxKey = '';

const loadUserLocationPin = (map, userLng, userLat) => {
  map.loadImage(USER_LOCATION_PNG, (error, image) => {
    if (error) {
      throw error;
    }

    map.addImage('userLocation', image);
  });

  map.addSource(USER_LAYER, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [userLng, userLat],
          },
        },
      ],
    },
  });

  map.addLayer({
    id: 'curLocationLayer',
    type: 'symbol',
    source: USER_LAYER,
    layout: {
      'icon-image': 'userLocation',
      'icon-allow-overlap': true,
    },
  });
};

export const setZoomLevel = async (map, coordinates) => {
  if (coordinates?.length) {
    const bounds = new mapboxgl.LngLatBounds();
    coordinates?.forEach((f: [number, number]) => bounds.extend(f));
    await map?.fitBounds(bounds, { maxZoom: 20, padding: 100 });
  }
};

const clickMapPinPoint = (
  el,
  map,
  location,
  practitioner,
  highlightPinCallback,
  updatePin,
  enableMapEnhancements,
  mobileScreen
) => {
  clickResultId = location.id;

  highlightPinCallback({
    providerId: practitioner.providerId ?? practitioner.locationId,
    from: 'mapView',
  });
  updatePin?.(practitioner);
  if (mobileScreen) {
    updateIconSize(true, el, enableMapEnhancements);
  }

  setResultIcon(map, hoverResultId, false, highlightPinCallback, null);
  setResultIcon(
    map,
    clickResultId,
    true,
    highlightPinCallback,
    practitioner.providerId ?? practitioner.locationId
  );
  handleLinkAndModalTrack(
    'click map pin point',
    'body: results page map view',
    'map view pin point'
  );
};

const hoverMapPinPoint = (
  el,
  map,
  location,
  practitioner,
  highlightPinCallback,
  updatePin,
  enableMapEnhancements
) => {
  if (clickResultId === null) {
    if (hoverResultId !== null) {
      setResultIcon(map, hoverResultId, false, highlightPinCallback, null);
    }
    updateIconSize(true, el, enableMapEnhancements);
    hoverResultId = location.id;
    setResultIcon(
      map,
      hoverResultId,
      true,
      highlightPinCallback,
      practitioner.providerId ?? practitioner.locationId
    );
    updatePin?.(practitioner);
  }
};

export const closePopUp = (el, popUp, tooltip, updatePin) => {
  (popUp as HTMLElement).style.visibility = 'hidden';
  (tooltip as HTMLElement).style.visibility = 'hidden';
  el.focus();
  updatePin?.(null);
};

const loadResultLocationPins = async (
  mobileScreen,
  map,
  features,
  coordinates,
  highlightPinCallback,
  selectPinCallback,
  disabledPinAction,
  updatePin,
  enableMapEnhancements
) => {
  await map?.isStyleLoaded();
  map.addSource(RESULT_LAYER, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features,
    },
  });

  map.addLayer({
    id: 'unclustered-point',
    type: 'symbol',
    source: RESULT_LAYER,
  });

  features?.forEach((location, index) => {
    const { description } = location.properties;
    const practitioner = JSON.parse(description);

    const el = document.createElement('button');

    const elImage = document.createElement('img');
    elImage.src = RESULT_LOCATION_PNG;
    elImage.className = 'map-icon';
    elImage.alt = '';

    el.className = 'custom-marker';
    el.id = `custom-marker-${
      practitioner.providerId ?? practitioner.locationId
    }`;
    el.ariaLabel = `${t('ACCESSIBILITY_LABELS.MAP_PINPOINT')}, ${
      practitioner.providerName ??
      (practitioner?.providerGroupsAffiliations
        ? practitioner?.providerGroupsAffiliations[0].value
        : '')
    }`;
    el.appendChild(elImage);
    el.tabIndex = 0;

    let enterPin = false;
    if (!disabledPinAction) {
      el.addEventListener('keydown', (e) => {
        if (e.key === 'Enter' || e.key === 'Tab' || e.key === 'Escape') {
          if (e.key === 'Enter') {
            clickMapPinPoint(
              el,
              map,
              location,
              practitioner,
              highlightPinCallback,
              updatePin,
              enableMapEnhancements,
              mobileScreen
            );
            enterPin = true;
          }
          const rootElem = document.querySelector('.datacard-popup');
          const tooltip = document.querySelector('.mapboxgl-popup-tip');

          if (e.key === 'Tab' && enterPin === true) {
            (rootElem as HTMLElement)?.focus();
            enterPin = false;
          }
          if (e.key === 'Escape') {
            closePopUp(el, rootElem, tooltip, updatePin);
            clickResultId = null;
          }
        }
      });

      el.addEventListener('mouseenter', () => {
        if (!enableMapEnhancements || !mobileScreen) {
          hoverMapPinPoint(
            el,
            map,
            location,
            practitioner,
            highlightPinCallback,
            updatePin,
            enableMapEnhancements
          );
        }
      });

      el.addEventListener('click', (event) => {
        if (clickResultId !== null && mobileScreen && enableMapEnhancements) {
          updateIconSize(
            false,
            selectPin(clickResultId),
            enableMapEnhancements
          );
        }
        el.focus();
        enterPin = true;
        event?.stopPropagation();

        clickMapPinPoint(
          el,
          map,
          location,
          practitioner,
          highlightPinCallback,
          updatePin,
          enableMapEnhancements,
          mobileScreen
        );
      });

      el.addEventListener('mouseleave', () => {
        if (!enableMapEnhancements || !mobileScreen) {
          updateIconSize(false, el, enableMapEnhancements);
          if (clickResultId === null) {
            updatePin?.(null);
            highlightPinCallback({ providerId: '', from: 'mapView' });
          }
        }
      });

      el.addEventListener('focus', () => {
        clickResultId = null;
      });
    }

    new mapboxgl.Marker(el).setLngLat(coordinates[index])?.addTo(map);
  });
};

export const updateIconSize = (
  isHighlighting,
  marker,
  enableMapEnhancements
) => {
  if (marker !== null) {
    const iconWidth = isHighlighting ? '32.67px' : '23.33px';
    const iconHeight = isHighlighting ? '46.67px' : '33.33px';
    marker.style.width = iconWidth;
    marker.style.height = iconHeight;
    if (enableMapEnhancements) {
      const img = marker.querySelector("[class^='map-icon']");
      img.className = isHighlighting ? 'map-icon-selected' : 'map-icon';
    }
  }
};

export const setResultIcon = (
  map,
  targetId,
  isHighlighting,
  highlightPinCallback,
  providerId
) => {
  const iconSize = isHighlighting ? 1.2 : 1;
  const loaded = map?.isStyleLoaded();
  if (loaded)
    map?.setLayoutProperty(RESULT_LAYER, 'icon-size', [
      'match',
      ['id'],
      targetId,
      iconSize,
      1,
    ]);
  if (map) {
    highlightPinCallback({ providerId, from: 'mapView' });
  }
};
export const clearRoute = (
  map,
  selectPinCallback?,
  highlightPinId?,
  highlightPinCallback?
) => {
  if (map?.getSource(ROUTE_LAYER)) {
    map.removeLayer(ROUTE_LAYER);
    map.removeSource(ROUTE_LAYER);
  }

  if (highlightPinCallback) {
    setResultIcon(map, highlightPinId, false, highlightPinCallback, null);
  }

  if (selectPinCallback) {
    selectPinCallback([null, null]);
  }
};

export const loadRoute = (
  map,
  routes,
  choosenRoute,
  userLng,
  userLat,
  endLng,
  endLat,
  mediumScreen = false
) => {
  clearRoute(map);

  const data = routes?.[choosenRoute];
  const route = data.geometry.coordinates;
  const geojson = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: route,
    },
  };

  if (map.getSource(ROUTE_LAYER)) {
    map.getSource(ROUTE_LAYER).setData(geojson);
  } else {
    map.addLayer({
      id: ROUTE_LAYER,
      type: 'line',
      source: {
        type: 'geojson',
        data: geojson,
      },
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#3887be',
        'line-width': 5,
        'line-opacity': 0.75,
      },
    });
  }

  map.fitBounds(
    [
      [userLng, userLat],
      [endLng, endLat],
    ],
    { padding: mediumScreen ? 50 : 120 }
  );
};

export const getRoute = async (
  map,
  userLng,
  userLat,
  endLng,
  endLat,
  mediumScreen = false,
  navType?,
  openDirections?,
  selectedRoute = 0,
  mapboxOnpremEnabled = false,
  mapOnPremKey = '',
  mapKey = '',
  mapOnPremURL = 'https://map.ce.uhg.com',
  mapLocationUrl = ''
): Promise<void | Directions> => {
  if (!map) {
    return;
  }

  if (navType) {
    curNavType = navType;
  }

  const mapLocationBasePath = mapLocationUrl || config('API_GATEWAY_URL');

  const mapBaseUrl = mapboxOnpremEnabled
    ? mapOnPremURL
    : 'https://api.mapbox.com';

  const mapGeoCodingUrl = mapboxOnpremEnabled
    ? `${mapLocationBasePath}/location-search`
    : 'https://api.mapbox.com/geocoding/v5';

  const endLangTyped = parseFloat(endLng)?.toFixed(7);
  const endLatTyped = parseFloat(endLat)?.toFixed(7);
  const routeQuery = await fetch(
    `${mapBaseUrl}/directions/v5/mapbox/${curNavType}/${userLng},${userLat};${endLangTyped},${endLatTyped}?steps=true&geometries=geojson&language=${getLocale()}&alternatives=true&access_token=${
      (mapboxOnpremEnabled ? mapOnPremKey : mapKey) || mapboxKey
    }`,
    { method: 'GET' }
  );
  const userPlaceQuery = await fetch(
    `${mapGeoCodingUrl}/mapbox.places/${userLng},${userLat}.json?types=address&&access_token=${
      mapKey || mapboxKey
    }`,
    { method: 'GET' }
  );
  const endPlaceQuery = await fetch(
    `${mapGeoCodingUrl}/mapbox.places/${endLng},${endLat}.json?types=address&&access_token=${
      mapKey || mapboxKey
    }`,
    { method: 'GET' }
  );
  const routeJson = await routeQuery.json();
  const userPlaceJson = await userPlaceQuery.json();
  const endPlaceJson = await endPlaceQuery.json();
  const { routes } = routeJson;
  const choosenRoute = selectedRoute < routes?.length ? selectedRoute : 0;
  const directions: Directions = {
    userPlaceName: userPlaceJson?.features?.[0]?.place_name,
    endPlaceName: endPlaceJson?.features?.[0]?.place_name,
    routes: routes?.map((route) => ({
      steps: route?.legs?.[0].steps,
      duration: route?.duration,
      distance: route?.distance,
      geometry: route?.geometry,
    })),
  };

  loadRoute(
    map,
    routes,
    choosenRoute,
    userLng,
    userLat,
    endLng,
    endLat,
    mediumScreen
  );

  // eslint-disable-next-line consistent-return
  return directions;
};

const mapClick = (
  map,
  highlightPinCallback,
  setPopupContent,
  enableMapEnhancements
) => {
  map.on('click', () => {
    if (map.getSource(ROUTE_LAYER)) {
      return;
    }

    if (clickResultId !== null) {
      setResultIcon(map, clickResultId, false, highlightPinCallback, null);
      updateIconSize(false, selectPin(clickResultId), enableMapEnhancements);
      clickResultId = null;
      setPopupContent?.(null);
      highlightPinCallback({ providerId: '', from: 'mapView' });
    }
  });
};

export const loadMapPins = async (
  mobileScreen,
  map,
  mapKey,
  userLng,
  userLat,
  features,
  coordinates,
  highlightPinCallback,
  selectPinCallback,
  disabledPinAction,
  setPopupContent,
  updatePin,
  enableMapEnhancements
) => {
  if (!map.getSource(USER_LAYER)) {
    map.addControl(new mapboxgl.NavigationControl(), 'top-right');
    map.getCanvas()?.setAttribute('role', 'application');
    map.getCanvas()?.setAttribute('aria-label', t('ACCESSIBILITY_LABELS.MAP'));
    mapboxKey = mapKey;
    await setZoomLevel(map, coordinates);
    await loadResultLocationPins(
      mobileScreen,
      map,
      features,
      coordinates,
      highlightPinCallback,
      selectPinCallback,
      disabledPinAction,
      updatePin,
      enableMapEnhancements
    );
    loadUserLocationPin(map, userLng, userLat);
    mapClick(map, highlightPinCallback, setPopupContent, enableMapEnhancements);
  }
};

export const setMapboxKey = (mapKey: string) => {
  mapboxKey = mapKey;
};

export const getProviderResults = (data: Provider[]) => {
  const cordinatesRef = {};
  const providerResults = data.map((practitioner: Provider) => {
    let cordinates = [practitioner.longitude, practitioner.latitude];
    const key = cordinates.join(',');
    if (cordinatesRef[key]) {
      const longSplits = practitioner.longitude.split('.');
      const fifthLongDecimal = parseInt(longSplits[1][4]);
      let randomValue;
      const latSplits = practitioner.latitude.split('.');
      const fifthLatDecimal = parseInt(latSplits[1][4]);
      let randomLatValue;
      do {
        randomValue = Math.floor(Math.random() * 10);
      } while (randomValue === fifthLongDecimal);
      const newLongitude = parseFloat(
        longSplits[0] +
          '.' +
          longSplits[1].substring(0, 4) +
          randomValue +
          longSplits[1].substring(5)
      );
      do {
        randomLatValue = Math.floor(Math.random() * 10);
      } while (randomLatValue === fifthLatDecimal);
      const newLatitude = parseFloat(
        latSplits[0] +
          '.' +
          latSplits[1].substring(0, 4) +
          randomLatValue +
          latSplits[1].substring(5)
      );
      cordinates = [String(newLongitude), String(newLatitude)];
    }
    cordinatesRef[key] = true;
    return {
      type: 'Feature',
      id: practitioner.providerId,
      properties: {
        description: JSON.stringify(practitioner),
        providerId: practitioner.providerId,
        type: 'provider',
      },
      geometry: {
        type: 'Point',
        coordinates: cordinates,
      },
    };
  });
  return providerResults;
};

const selectPin = (pinId) => document.querySelector(`#custom-marker-${pinId}`);
export const escapeRegExp = (string: string) =>
  string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
