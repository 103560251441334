import { config } from '@abyss/web/tools/config';

import { FacilityDetails } from '../models/FacilityDetails';
import { Provider } from '../models/Provider';
import {
  ProviderDetails,
  ProviderLocation,
  ProviderType,
} from '../models/ProviderDetails';
import { mockFacilities } from './Utils/mockResults/mockFacilities';
import { mockProviders } from './Utils/mockResults/mockProviders';

const defaultPractitionerImage = `${config(
  'CDN_BASE_URL'
)}/cdn/assets/images/default_Practitioner.jpg`;

export const MockResults: any = [
  {
    lastName: 'Doe',
    firstName: 'John',
    middleName: 'S',
    suffix: 'MD',
    practice: 'Family Medicine',
    address: '123 Main Street',
    city: 'Minneapolis',
    state: 'MN',
    zip: 55110,
    phone: '(123) 456-7899',
    premium: true,
    accepting: false,
    network: true,
    type: 'provider',
    providerId: '1',
  },
  {
    lastName: 'Jenkins',
    firstName: 'Leroy',
    middleName: 'Charles',
    suffix: 'MD',
    practice: 'Family Medicine',
    address: '321 18th Lane',
    city: 'Saint Paul',
    state: 'MN',
    zip: 55117,
    phone: '(452) 451-4532',
    premium: false,
    accepting: true,
    network: true,
    type: 'provider',
    providerId: '2',
  },
  mockProviders(),
  {
    firstName: 'Smith Family',
    practice: 'General Care',
    address: '72 Jefferson Drive',
    city: 'Saint Paul',
    state: 'MN',
    zip: 55113,
    phone: '(555) 555-5555',
    type: 'Facility',
    facilityId: '000516295',
  },
  {
    firstName: 'Johnson Family',
    practice: 'Orthopedics',
    address: '999 Washington Avenue',
    city: 'Minneapolis',
    state: 'MN',
    zip: 55110,
    phone: '(321) 432-5432',
    type: 'Facility',
    facilityId: '000516295',
  },
  mockFacilities(),
];

export const MockProviderDetails: ProviderDetails = {
  providerLocations: [
    {
      hoursOfOperation: [
        {
          day: 'sunday',
          openingTime: '',
          closingTime: '',
        },
        {
          day: 'monday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
        },
        {
          day: 'tuesday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
        },
        {
          day: 'wednesday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
        },
        {
          day: 'thursday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
        },
        {
          day: 'friday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
        },
        {
          day: 'saturday',
          openingTime: '',
          closingTime: '',
        },
      ],
      phones: {
        phone: ['763-295-2921'],
        emergency: [],
        appointment: [],
        fax: [],
      },
      distance: '38.4',
      efficiencyCode: 'E94',
      defaultLocation: false,
      ddpCode: '',
      address: {
        city: 'Monticello',
        district: 'Wright',
        line: ['1001 Hart Blvd Ste 100'],
        postalCode: '55362-8929',
        state: 'MN',
      },
      lastUpdatedDate: '2023-05-12',
      latitude: '45.2992',
      locationId: '889830577FF54627FD839500A73D0A20',
      longitude: '-93.77726',
      medicalEquipments: [],
      network: { start: '', end: '', status: '' },
      pcpId: '',
      practiceLimitations: {
        endAge: '125',
        gender: 'U',
        startAge: '0',
      },
      tin: '411227920',
      tty: '711',
      qualityCode: 'Q94',
      specialities: [],
      speciality: [],
      website: null,
      emailAddress: null,
      hasEveningAppointments: false,
      hasPCPServiceAvailability: false,
    },
  ],
  areaOfExpertise: ['MAT1'],
  biographicData: '',
  educationInfo: [
    {
      schoolName: '',
      degrees: [
        {
          degreeCode: 'MD',
          isPrimary: true,
          description: '',
        },
      ],
    },
  ],
  npi: '1528359452',
  gender: 'female',
  hospitalAffiliations: [
    {
      facilityId: null,
      id: '101691',
      imageId: null,
    },
    {
      facilityId: null,
      id: '461695',
      imageId: null,
    },
  ],
  imageLocation: defaultPractitionerImage,
  languagesSpoken: ['ENG'],
  licenseNumber: ['55348'],
  organizationType: [],
  pictureId: '',
  degrees: ['MEDICAL DOCTOR'],
  primaryDegrees: ['MD'],
  providerGroupsAffiliations: [
    {
      id: '461717',
      value: 'STELLIS HEALTH',
    },
  ],
  providerName: 'Labounty Abby R',
  providerType: ProviderType.PRACTITIONER,
  providerId: '003618041',
};

export const MockProviderDetails2: ProviderDetails = {
  providerLocations: [
    {
      hoursOfOperation: [
        {
          day: 'sunday',
          openingTime: '',
          closingTime: '',
        },
        {
          day: 'monday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
        },
        {
          day: 'tuesday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
        },
        {
          day: 'wednesday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
        },
        {
          day: 'thursday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
        },
        {
          day: 'friday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
        },
        {
          day: 'saturday',
          openingTime: '',
          closingTime: '',
        },
      ],
      phones: {
        phone: ['763-295-2921'],
        emergency: [],
        appointment: [],
        fax: [],
      },
      distance: '38.4',
      efficiencyCode: 'E03',
      defaultLocation: false,
      ddpCode: '',
      address: {
        city: 'Monticello',
        district: 'Wright',
        line: ['1001 Hart Blvd Ste 100'],
        postalCode: '55362-8929',
        state: 'MN',
      },
      lastUpdatedDate: '2023-05-12',
      latitude: '45.2992',
      locationId: '889830577FF54627FD839500A73D0A20',
      longitude: '-93.77726',
      medicalEquipments: [],
      network: { start: '', end: '', status: '' },
      pcpId: '',
      practiceLimitations: {
        endAge: '125',
        gender: 'U',
        startAge: '0',
      },
      tin: '411227920',
      tty: '711',
      qualityCode: 'Q03',
      isPremiumCarePhysician: 'YES',
      specialities: [],
      speciality: [],
      website: null,
      emailAddress: null,
      hasEveningAppointments: false,
      hasPCPServiceAvailability: false,
      isPlatinumProvider: false,
    },
  ],
  areaOfExpertise: ['MAT1'],
  biographicData: '',
  educationInfo: [
    {
      schoolName: '',
      degrees: [
        {
          degreeCode: 'MD',
          isPrimary: true,
          description: '',
        },
      ],
    },
  ],
  npi: '1528359452',
  gender: 'female',
  hospitalAffiliations: [
    {
      facilityId: null,
      id: '101691',
      imageId: null,
    },
    {
      facilityId: null,
      id: '461695',
      imageId: null,
    },
  ],
  imageLocation: defaultPractitionerImage,
  languagesSpoken: ['ENG'],
  licenseNumber: ['55348'],
  organizationType: [],
  pictureId: '',
  degrees: ['MEDICAL DOCTOR'],
  primaryDegrees: ['MD'],
  providerGroupsAffiliations: [
    {
      id: '461717',
      value: 'STELLIS HEALTH',
    },
  ],
  providerName: 'Labounty Abby R',
  providerType: ProviderType.PRACTITIONER,
  providerId: '003618041',
};

export const MockProviderDetailsWithLocationKey: ProviderLocation = {
  acceptingNewPatients: true,
  accommodates: null,
  accessibility: ['Parking', 'Restroom', 'Exterior building'],
  address: {
    line: ['11995 Singletree Ln Ste 500'],
    city: 'Eden Prairie',
    district: 'Hennepin',
    state: 'MN',
    postalCode: '55344-5349',
  },
  phones: {
    phone: ['952-595-1301'],
    emergency: null,
    appointment: null,
    fax: null,
  },
  hoursOfOperation: [
    {
      day: 'sunday',
      openingTime: '',
      closingTime: '',
    },
    {
      day: 'monday',
      openingTime: '',
      closingTime: '',
    },
    {
      day: 'tuesday',
      openingTime: '',
      closingTime: '',
    },
    {
      day: 'wednesday',
      openingTime: '',
      closingTime: '',
    },
    {
      day: 'thursday',
      openingTime: '',
      closingTime: '',
    },
    {
      day: 'friday',
      openingTime: '',
      closingTime: '',
    },
    {
      day: 'saturday',
      openingTime: '',
      closingTime: '',
    },
  ],
  defaultLocation: true,
  defaultTranslatedValues: {
    accessibility: ['Parking', 'Restroom', 'Exterior building'],
    languagesSpokenByOffice: ['English'],
    languagesSpokenByInterpreters: ['English'],
    practiceLimitations: {
      age: 'All ages',
      gender: '',
    },
    specialties: ['Family Practice', 'Radiologist'],
    virtualCareOffered: null,
  },
  distance: '4.3',
  efficiencyCode: 'E02,E97',
  emailAddress: null,
  hasEveningAppointments: false,
  hasWeekendAppointment: false,
  hasPCPServiceAvailability: true,
  isExpressAccessProvider: false,
  isPremiumCarePhysician: 'NO',
  isTieredProvider: false,
  languagesSpokenByOffice: ['English'],
  languagesSpokenByInterpreters: ['English'],
  lastUpdatedDate: '2024-02-04',
  latitude: '44.8564628',
  locationId: '953C420CA1D014E7C58C72C3F1206A66',
  longitude: '-93.4288318',
  network: {
    start: '2020-11-01',
    end: '9999-12-31',
    status: 'INN',
  },
  pcpId: '00003117356 016',
  practiceLimitations: {
    age: 'All ages',
    gender: '',
  },
  providerGroupsAffiliations: [
    {
      id: '2631672',
      value: 'WESTVIEW FAMILY MEDICINE',
    },
  ],
  qualityCode: 'Q02,Q97',
  specialties: ['Family Practice', 'Radiologist'],
  tin: '204665773',
  tty: '711',
  costIndicator: '$$$',
  virtualCareOffered: null,
  website: null,
  servicesOffered: [],
  accommodateOffered: [],
  medicalInterpreterInOffice: false,
  languageLineAvailable: false,
  methodsOfService: [],
  publicTransportationMethods: ['No'],
  isSedationOffered: false,
  electronicPrescribingOffered: null,
  isDocasapScheduled: false,
  docasapPracticeId: null,
  locationKey: '003117356-204665773-T-953C420CA1D014E7C58C72C3F1206A66',
};

export const MockProviderDetailResult: ProviderDetails = {
  biographicData: '',
  areaOfExpertise: ['Chiropractor'],
  imageLocation: '',
  isTieredProvider: true,
  npi: '1518286970',
  pictureId: '',
  providerName: 'ABRAHAM DAVID',
  firstName: 'DAVID',
  lastName: 'ABRAHAM',
  providerId: '006504354',
  providerType: ProviderType.PRACTITIONER,
  culturalCompetencies: [],
  languagesSpoken: [],
  licenseNumber: ['D0070977'],
  primaryDegrees: ['MD'],
  educationInfo: [
    {
      schoolName: 'University of Minnesota',
      degrees: [
        {
          degreeCode: 'Residency Hospital',
          isPrimary: true,
          description: 'Residency Hospital',
        },
      ],
    },
    {
      schoolName: 'Saint Georges University',
      degrees: [
        {
          degreeCode: 'Medical School',
          isPrimary: true,
          description: 'Medical School',
        },
      ],
    },
    {
      schoolName: 'University of California',
      degrees: [
        {
          degreeCode: 'Undergraduate School',
          isPrimary: true,
          description: 'Undergraduate School',
        },
        {
          degreeCode: 'Undergraduate School2',
          isPrimary: false,
          description: 'Undergraduate School2',
        },
      ],
    },
    {
      schoolName: 'N/A',
      degrees: [
        {
          degreeCode: 'DOCTOR OF OSTEOPATHY',
          isPrimary: true,
          description: 'DOCTOR OF OSTEOPATHY',
        },
        {
          degreeCode: 'Degree-Nurse Practitioner',
          isPrimary: false,
          description: 'Degree-Nurse Practitioner',
        },
      ],
    },
  ],
  gender: 'male',
  hospitalAffiliations: [
    {
      value: 'NO HOSPITAL AFFILIATIONS',
      id: 'NO HOSPITAL AFFILIATIONS',
    },
    {
      value: 'ATLANTICARE REG MED CTR',
      id: 'ATLANTICARE REG MED CTR',
    },
  ],
  professionalDesignation: 'DO',
  primarySpeciality: ['FAMILY PRACTICE'],
  degrees: ['MEDICAL DOCTOR'],
  providerSpecialities: [
    'Anesthesiology',
    'Emergency Medicine',
    'Pathology',
    'Radiology',
  ],
  hospitalAffiliationList: [
    {
      name: 'NO HOSPITAL AFFILIATIONS',
      facilityId: '0',
      imageId: null,
      value: 'NO HOSPITAL AFFILIATIONS',
      id: 'NO HOSPITAL AFFILIATIONS',
    },
    {
      name: 'ATLANTICARE REG MED CTR',
      facilityId: '428205',
      imageId: null,
      value: 'ATLANTICARE REG MED CTR',
      id: 'ATLANTICARE REG MED CTR',
    },
  ],
  otherSpecialities: [
    'Medication assisted treatment',
    'Indian Health Services Provider',
    'Panda/pans Provider',
    'Essential community provider',
  ],
  UHPDRating: 'Quality Care Physician',
  accreditations: ['DEFAULT SPECILTY BOARD APER ID'],
  boardCertifications: [],
  otherProviderDetails: [],
  networkStartDate: '3/22/2023',
  networkStatus: '',
  servicesProvided: [
    'Office visit',
    'Medication assisted treatment',
    'Indian Health Services Provider',
    'Panda/pans Provider',
    'Essential community provider',
  ],
  healthGradeRating: '4.3',
  lastUpdatedDate: '3/22/2023',
  lastVerifiedDate: '3/22/2023',
  providerLocations: [
    {
      locationId: '0001234',
      latitude: '39.321757',
      longitude: '-74.600816',
      costIndicator: '$$$',
      accessibility: [],
      efficiencyCode: '',
      qualityCode: '',
      ddpCode: '',
      tin: '',
      tty: '',
      website: 'http:www.google.com',
      digestId: '',
      pcpId: '006504354 012',
      medicalEquipments: [],
      lastUpdatedDate: '',
      languagesSpokenByOffice: [],
      languagesSpokenByInterpreters: [],
      specialities: [],
      hasEveningAppointments: false,
      hasPCPServiceAvailability: true,
      hasWeekendAppointment: true,
      isPlatinumProvider: true,
      isTieredProvider: true,
      defaultLocation: true,
      phones: {
        phone: ['609-569-7077'],
        appointment: [],
        emergency: [],
        fax: [],
      },
      network: {
        start: '07/07/2022',
        end: '02/02/2025',
        status: 'INN',
      },
      practiceLimitations: {
        age: '',
        gender: '',
      },
      providerGroupsAffiliations: [
        {
          id: '461717',
          value: 'STELLIS HEALTH',
        },
      ],
      facilityProviderSpecialities: {
        hasAnesthesiology: true,
        hasEmergencyMedicine: true,
        isFreeStandingFacility: true,
        hasPathology: true,
        hasRadiology: true,
        hasJCAHOAccreditation: true,
      },
      leapFrogRatings: {
        preventingMedicationErrors: '',
        rateOfEpisiotomy: '',
        managingSeriousErrors: '',
        reduceCLInfectionsInICUs: '',
        highRiskDeliveries: '',
      },
      hoursOfOperation: [
        {
          day: 'sunday',
          openingTime: '',
          closingTime: '',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'monday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'tuesday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'wednesday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'thursday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'friday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'saturday',
          openingTime: '',
          closingTime: '',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
      ],
      distance: '0.3586041711145234',
      emailAddress: ['test@test.com'],
      acceptingNewPatients: true,
      virtualCareOffered: [],
      address: {
        line: ['443 SHORE RD', 'STE 103'],
        city: 'SOMERS POINT',
        district: 'ATLANTIC',
        state: 'NJ',
        postalCode: '08244-2645',
      },
    },
    {
      locationId: '002027197-200096809-T-AC1B641C4B7BEBA645966F82CE743861-L',
      latitude: '40.53473',
      longitude: '-74.15427',
      accessibility: [],
      efficiencyCode: '',
      qualityCode: '',
      ddpCode: '',
      tin: '',
      tty: '',
      website: '',
      digestId: '',
      pcpId: '006504354 012',
      medicalEquipments: [],
      lastUpdatedDate: '',
      languagesSpokenByOffice: [],
      languagesSpokenByInterpreters: [],
      specialities: [],
      hasEveningAppointments: true,
      hasPCPServiceAvailability: true,
      hasWeekendAppointment: true,
      isTieredProvider: true,
      defaultLocation: true,
      phones: {
        phone: ['609-569-7077'],
        appointment: ['609-569-7075'],
        emergency: ['609-569-7076'],
        fax: [],
      },
      providerGroupsAffiliations: [
        {
          id: '4631717',
          value: 'STELLIS HEALTH2',
        },
      ],
      network: {
        start: '',
        end: '',
        status: '',
      },
      practiceLimitations: {
        endAge: '',
        gender: '',
        startAge: '',
      },
      facilityProviderSpecialities: {
        hasAnesthesiology: true,
        hasEmergencyMedicine: true,
        isFreeStandingFacility: true,
        hasPathology: true,
        hasRadiology: true,
        hasJCAHOAccreditation: true,
      },
      leapFrogRatings: {
        preventingMedicationErrors: '',
        rateOfEpisiotomy: '',
        managingSeriousErrors: '',
        reduceCLInfectionsInICUs: '',
        highRiskDeliveries: '',
      },
      hoursOfOperations: [
        {
          day: 'sunday',
          openingTime: '',
          closingTime: '',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'monday',
          openingTime: '12:00:00',
          closingTime: '19:00:00',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'tuesday',
          openingTime: '12:00:00',
          closingTime: '19:00:00',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'wednesday',
          openingTime: '',
          closingTime: '',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'thursday',
          openingTime: '10:00:00',
          closingTime: '19:00:00',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'friday',
          openingTime: '',
          closingTime: '',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'saturday',
          openingTime: '',
          closingTime: '',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
      ],
      distance: '1.3388114623310468',
      emailAddress: ['test@test.com'],
      acceptingNewPatients: false,
      virtualCareOffered: [],
      address: {
        line: ['4269 Richmond Ave'],
        city: 'Staten Island',
        district: 'Richmond',
        state: 'NY',
        postalCode: '10312',
      },
    },
    {
      locationId: '002027197-133861340-T-331E65B675712DC0C5E11F0A786D298E-L',
      latitude: '40.60857',
      longitude: '-73.95985',
      accessibility: [],
      efficiencyCode: '',
      qualityCode: '',
      ddpCode: '',
      tin: '',
      tty: '',
      website: '',
      digestId: '',
      pcpId: '006504354 013',
      medicalEquipments: [],
      lastUpdatedDate: '',
      languagesSpokenByOffice: [],
      languagesSpokenByInterpreters: [],
      specialities: [],
      hasEveningAppointments: true,
      hasPCPServiceAvailability: true,
      hasWeekendAppointment: true,
      isTieredProvider: true,
      defaultLocation: true,
      phones: {
        phone: ['609-569-7077'],
        appointment: [],
        emergency: [],
        fax: [],
      },
      providerGroupsAffiliations: [
        {
          id: '476717',
          value: 'STELLIS HEALTH3',
        },
      ],
      network: {
        start: '',
        end: '',
        status: '',
      },
      practiceLimitations: {
        endAge: '',
        gender: '',
        startAge: '',
      },
      facilityProviderSpecialities: {
        hasAnesthesiology: true,
        hasEmergencyMedicine: true,
        isFreeStandingFacility: true,
        hasPathology: true,
        hasRadiology: true,
        hasJCAHOAccreditation: true,
      },
      leapFrogRatings: {
        preventingMedicationErrors: '',
        rateOfEpisiotomy: '',
        managingSeriousErrors: '',
        reduceCLInfectionsInICUs: '',
        highRiskDeliveries: '',
      },
      hoursOfOperations: [
        {
          day: 'sunday',
          openingTime: '',
          closingTime: '',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'monday',
          openingTime: '09:00:00',
          closingTime: '20:00:00',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'tuesday',
          openingTime: '09:00:00',
          closingTime: '20:00:00',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'wednesday',
          openingTime: '09:00:00',
          closingTime: '20:00:00',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'thursday',
          openingTime: '09:00:00',
          closingTime: '20:00:00',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'friday',
          openingTime: '09:00:00',
          closingTime: '20:00:00',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'saturday',
          openingTime: '',
          closingTime: '',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
      ],
      distance: '11.489601963085107',
      emailAddress: ['test@test.com'],
      acceptingNewPatients: false,
      virtualCareOffered: [],
      address: {
        line: ['1655 E 13th St'],
        city: 'Brooklyn',
        district: 'Kings',
        state: 'NY',
        postalCode: '11229',
      },
    },
    {
      locationId: '002027197-133861340-T-670469D3A9B21A51DBCB1ACCD8DAFAAE-L',
      latitude: '40.63078',
      longitude: '-73.96648',
      accessibility: [],
      efficiencyCode: '',
      qualityCode: '',
      ddpCode: '',
      tin: '',
      tty: '',
      website: '',
      digestId: '',
      pcpId: '006504354 015',
      medicalEquipments: [],
      lastUpdatedDate: '',
      languagesSpokenByOffice: [],
      languagesSpokenByInterpreters: [],
      specialities: [],
      hasEveningAppointments: true,
      hasPCPServiceAvailability: true,
      hasWeekendAppointment: true,
      isTieredProvider: true,
      defaultLocation: true,
      phones: {
        phone: ['609-569-7077'],
        appointment: [],
        emergency: [],
        fax: [],
      },
      providerGroupsAffiliations: [
        {
          id: '476234',
          value: 'STELLIS HEALTH4',
        },
      ],
      network: {
        start: '',
        end: '',
        status: '',
      },
      practiceLimitations: {
        endAge: '',
        gender: '',
        startAge: '',
      },
      facilityProviderSpecialities: {
        hasAnesthesiology: true,
        hasEmergencyMedicine: true,
        isFreeStandingFacility: true,
        hasPathology: true,
        hasRadiology: true,
        hasJCAHOAccreditation: true,
      },
      leapFrogRatings: {
        preventingMedicationErrors: '',
        rateOfEpisiotomy: '',
        managingSeriousErrors: '',
        reduceCLInfectionsInICUs: '',
        highRiskDeliveries: '',
      },
      hoursOfOperations: [
        {
          day: 'sunday',
          openingTime: '',
          closingTime: '',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'monday',
          openingTime: '09:00:00',
          closingTime: '17:00:00',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'tuesday',
          openingTime: '09:00:00',
          closingTime: '17:00:00',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'wednesday',
          openingTime: '09:00:00',
          closingTime: '17:00:00',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'thursday',
          openingTime: '09:00:00',
          closingTime: '17:00:00',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'friday',
          openingTime: '09:00:00',
          closingTime: '17:00:00',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'saturday',
          openingTime: '09:00:00',
          closingTime: '17:00:00',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
      ],
      distance: '11.786504570404963',
      emailAddress: ['test@test.com'],

      acceptingNewPatients: false,
      virtualCareOffered: [],
      address: {
        line: ['1100 Coney Island Ave Ste 2'],
        city: 'Brooklyn',
        district: 'Kings',
        state: 'NY',
        postalCode: '11230',
      },
    },
  ],
  providerGroups: [],
  organizationType: [],
  specialties: ['Family Practice'],
};

export const MockProviderLocationsResult: ProviderDetails = {
  providerName: 'ABRAHAM DAVID',
  providerId: '006504354',
  providerType: ProviderType.PRACTITIONER,
  educationInfo: [
    {
      schoolName: 'University of Minnesota',
      degrees: [
        {
          degreeCode: 'Residency Hospital',
          isPrimary: true,
          description: '',
        },
      ],
    },
    {
      schoolName: 'Saint Georges University',
      degrees: [
        {
          degreeCode: 'Medical School',
          isPrimary: true,
          description: '',
        },
      ],
    },
    {
      schoolName: 'University of California',
      degrees: [
        {
          degreeCode: 'Undergraduate School',
          isPrimary: true,
          description: '',
        },
        {
          degreeCode: 'Undergraduate School',
          isPrimary: false,
          description: '',
        },
      ],
    },
    {
      schoolName: 'N/A',
      degrees: [
        {
          degreeCode: 'DOCTOR OF OSTEOPATHY',
          isPrimary: true,
          description: '',
        },
        {
          degreeCode: 'Degree-Nurse Practitioner',
          isPrimary: false,
          description: '',
        },
      ],
    },
  ],
  gender: 'male',
  professionalDesignation: 'DO',
  primarySpeciality: ['FAMILY PRACTICE'],
  providerSpecialities: [
    'Anesthesiology',
    'Emergency Medicine',
    'Pathology',
    'Radiology',
  ],
  hospitalAffiliations: [
    {
      id: '391457',
      value: 'LOS ALAMITOS MEDICAL CENTER',
      name: null,
      facilityId: null,
      imageId: null,
    },
    {
      id: '388638',
      value: 'LONG BEACH MEMORIAL MED CTR',
      name: null,
      facilityId: null,
      imageId: null,
    },
  ],
  otherSpecialities: [
    'Medication assisted treatment',
    'Indian Health Services Provider',
    'Panda/pans Provider',
    'Essential community provider',
  ],
  UHPDRating: 'Quality Care Physician',
  accreditations: ['DEFAULT SPECILTY BOARD APER ID'],
  boardCertifications: [],
  otherProviderDetails: [],
  networkStartDate: '3/22/2023',
  networkStatus: '',
  servicesProvided: [
    'Office visit',
    'Medication assisted treatment',
    'Indian Health Services Provider',
    'Panda/pans Provider',
    'Essential community provider',
  ],
  healthGradeRating: '4.3',
  lastUpdatedDate: '3/22/2023',
  lastVerifiedDate: '3/22/2023',
  organizationType: [],
  providerLocations: [
    {
      locationId: '0001234',
      latitude: '39.321757',
      longitude: '-74.600816',
      accessibility: [],
      efficiencyCode: '',
      qualityCode: '',
      ddpCode: '',
      tin: '',
      tty: '',
      website: '',
      digestId: '',
      pcpId: '',
      medicalEquipments: [],
      lastUpdatedDate: '',
      languagesSpokenByOffice: [],
      languagesSpokenByInterpreters: [],
      specialities: [],
      hasEveningAppointments: true,
      hasPCPServiceAvailability: true,
      hasWeekendAppointment: true,
      isTieredProvider: true,
      defaultLocation: true,
      phones: {
        phone: ['609-569-7077'],
        appointment: [],
        emergency: [],
        fax: [],
      },
      providerGroupsAffiliations: [
        {
          id: '476717',
          value: 'STELLIS HEALTH3',
        },
      ],
      network: {
        start: '',
        end: '',
        status: '',
      },
      practiceLimitations: {
        age: '',
        gender: '',
        startAge: '',
      },
      facilityProviderSpecialities: {
        hasAnesthesiology: true,
        hasEmergencyMedicine: true,
        isFreeStandingFacility: true,
        hasPathology: true,
        hasRadiology: true,
        hasJCAHOAccreditation: true,
      },
      leapFrogRatings: {
        preventingMedicationErrors: '',
        rateOfEpisiotomy: '',
        managingSeriousErrors: '',
        reduceCLInfectionsInICUs: '',
        highRiskDeliveries: '',
      },
      hoursOfOperations: [
        {
          day: 'sunday',
          openingTime: '',
          closingTime: '',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'monday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'tuesday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'wednesday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'thursday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'friday',
          openingTime: '08:00:00',
          closingTime: '17:00:00',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
        {
          day: 'saturday',
          openingTime: '',
          closingTime: '',
          sunday: '',
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
        },
      ],
      distance: '0.3586041711145234',
      emailAddress: ['test@test.com'],
      acceptingNewPatients: true,
      virtualCareOffered: [],
      address: {
        line: ['443 SHORE RD', 'STE 103'],
        city: 'SOMERS POINT',
        district: 'ATLANTIC',
        state: 'NJ',
        postalCode: '08244-2645',
      },
    },
  ],
  areaOfExpertise: [],
  providerGroups: [],
  languagesSpoken: [],
  culturalCompetencies: [],
  npi: '',
  licenseNumber: [],
  pictureId: '',
  imageLocation: '',
  biographicData: '',
  primaryDegrees: [],
};

export const MockProviderLocationNoResult: ProviderDetails = {
  providerName: 'ABRAHAM DAVID',
  providerId: '006504354',
  providerType: ProviderType.PRACTITIONER,
  educationInfo: [
    {
      schoolName: 'University of Minnesota',
      degrees: [
        {
          degreeCode: 'Residency Hospital',
          isPrimary: true,
          description: '',
        },
      ],
    },
    {
      schoolName: 'Saint Georges University',
      degrees: [
        {
          degreeCode: 'Medical School',
          isPrimary: true,
          description: '',
        },
      ],
    },
    {
      schoolName: 'University of California',
      degrees: [
        {
          degreeCode: 'Undergraduate School',
          isPrimary: true,
          description: '',
        },
        {
          degreeCode: 'Undergraduate School',
          isPrimary: false,
          description: '',
        },
      ],
    },
    {
      schoolName: 'N/A',
      degrees: [
        {
          degreeCode: 'DOCTOR OF OSTEOPATHY',
          isPrimary: true,
          description: '',
        },
        {
          degreeCode: 'Degree-Nurse Practitioner',
          isPrimary: false,
          description: '',
        },
      ],
    },
  ],
  gender: 'male',
  professionalDesignation: 'DO',
  primarySpeciality: ['FAMILY PRACTICE'],
  providerSpecialities: [
    'Anesthesiology',
    'Emergency Medicine',
    'Pathology',
    'Radiology',
  ],
  hospitalAffiliationList: [
    {
      name: 'NO HOSPITAL AFFILIATIONs',
      facilityId: '0',
      imageId: null,
      value: null,
      id: null,
    },
    {
      name: 'ATLANTICARE REG MED CTR',
      facilityId: '428205',
      imageId: null,
      value: null,
      id: null,
    },
  ],
  hospitalAffiliations: [
    {
      name: 'NO HOSPITAL AFFILIATIONs',
      facilityId: '0',
      imageId: null,
      value: null,
      id: null,
    },
    {
      name: 'ATLANTICARE REG MED CTR',
      facilityId: '428205',
      imageId: null,
      value: null,
      id: null,
    },
  ],
  otherSpecialities: [
    'Medication assisted treatment',
    'Indian Health Services Provider',
    'Panda/pans Provider',
    'Essential community provider',
  ],
  UHPDRating: 'Quality Care Physician',
  accreditations: ['DEFAULT SPECILTY BOARD APER ID'],
  boardCertifications: [],
  otherProviderDetails: [],
  networkStartDate: '3/22/2023',
  networkStatus: '',
  servicesProvided: [
    'Office visit',
    'Medication assisted treatment',
    'Indian Health Services Provider',
    'Panda/pans Provider',
    'Essential community provider',
  ],
  healthGradeRating: '4.3',
  lastUpdatedDate: '3/22/2023',
  lastVerifiedDate: '3/22/2023',
  organizationType: [],
  providerLocations: [],
  areaOfExpertise: [],
  providerGroups: [],
  languagesSpoken: [],
  culturalCompetencies: [],
  npi: '',
  licenseNumber: [],
  pictureId: '',
  imageLocation: '',
  biographicData: '',
  primaryDegrees: [],
};

export const MockFacilityDetailResult: FacilityDetails = {
  culturalCompetencies: null,
  defaultTranslatedValues: {
    culturalCompetencies: [],
    organizationType: ['Federally Qualified Health Center'],
    specialties: [],
  },
  imageLocation: null,
  npi: '1063525152',
  pictureId: null,
  organizationType: ['Federally Qualified Health Center'],
  providerId: '001803760',
  providerType: ProviderType.ORGANIZATION,
  providerName: 'Premier Healthcare',
  providerLocations: [
    {
      locationId: '001803760-133916271-T-332DFF0C80B0BC65E50A4F5A2C40E23C-L',
      defaultLocation: true,
      accessibility: ['P', 'R', 'EB'],
      ddpCode: '',
      facilityProviderSpecialities: {
        hasAnesthesiology: true,
        hasEmergencyMedicine: true,
        isFreeStandingFacility: true,
        hasPathology: true,
        hasRadiology: true,
        hasJCAHOAccreditation: true,
      },
      latitude: '39.266954',
      longitude: '-74.592907',
      tin: '',
      tty: '',
      website: '',
      digestId: '',
      pcpId: '',
      medicalEquipments: [],
      languagesSpokenByOffice: ['English', 'Spanish'],
      languagesSpokenByInterpreters: ['English', 'Spanish'],
      hasEveningAppointments: false,
      hasPCPServiceAvailability: true,
      hasWeekendAppointment: true,
      isPlatinumProvider: true,
      network: {
        start: '07/07/2022',
        end: '02/02/2025',
        status: 'INN',
      },
      practiceLimitations: {
        age: '',
        gender: '',
      },
      leapFrogRatings: [
        {
          displayField: 'Name of Leapfrog rating 1',
          ratingValue: 'Achieved the Standard',
          rawValue: '4',
        },
      ],
      lastUpdatedDate: '03/06/2023',
      hoursOfOperations: [
        {
          day: 'Sunday',
          openingTime: '10:00:00',
          closingTime: '18:00:00',
        },
        {
          day: 'Monday',
          openingTime: '08:00:00',
          closingTime: '18:00:00',
        },
        {
          day: 'Tuesday',
          openingTime: '08:00:00',
          closingTime: '18:00:00',
        },
      ],
      distance: '20',
      emailAddress: ['test@test.com'],
      phones: {
        phone: [
          '212-360-2600',
          '718-743-3850',
          '718-306-1300',
          '212-273-6519',
          '718-855-6240',
          '718-705-1000',
          '212-273-6182',
          '212-563-7474',
          '212-273-6100',
          '212-273-6521',
          '212-273-6272',
          '718-239-1790',
          '516-280-7111',
          '212-273-6515',
        ],
        emergency: ['850-433-6580', '850-626-9626'],
        appointment: ['850-434-1863', '850-433-6580'],
        fax: ['850-969-1636', '251-368-2550'],
      },
      acceptingNewPatients: true,
      virtualCareOffered: [],
      address: {
        line: ['21111 Northern Blvd FL 2'],
        city: 'Bayside',
        district: 'Queens',
        state: 'NY',
        postalCode: '11361',
      },
    },
    {
      locationId: '12345677-133916271-T-332DFF0C80B0BC65E50A4F5A2C40E23C-D',
      latitude: '39.266954',
      longitude: '-74.592907',
      acceptingNewPatients: false,
      accessibility: [],
      ddpCode: '',
      tin: '',
      tty: '',
      website: '',
      digestId: '',
      pcpId: '',
      medicalEquipments: [],
      lastUpdatedDate: '03/06/2023',
      languagesSpokenByOffice: ['English', 'Spanish'],
      languagesSpokenByInterpreters: ['English', 'Spanish'],
      hasEveningAppointments: true,
      hasPCPServiceAvailability: true,
      hasWeekendAppointment: true,
      defaultLocation: false,
      network: {
        start: '',
        end: '',
        status: '',
      },
      practiceLimitations: {
        age: '',
        gender: '',
      },
      facilityProviderSpecialities: {
        hasAnesthesiology: true,
        hasEmergencyMedicine: true,
        isFreeStandingFacility: true,
        hasPathology: true,
        hasRadiology: true,
        hasJCAHOAccreditation: true,
      },
      leapFrogRatings: [
        {
          displayField: 'Name of Leapfrog rating 1',
          ratingValue: 'Achieved the Standard',
          rawValue: '4',
        },
      ],
      hoursOfOperations: [
        {
          day: 'Sunday',
          openingTime: '12:00:00',
          closingTime: '16:00:00',
        },
      ],
      distance: '10',
      emailAddress: ['test@test.com'],
      phones: {
        phone: ['609-569-7077'],
        emergency: ['850-433-6580', '850-626-9626'],
        appointment: ['850-434-1863', '850-433-6580'],
        fax: ['850-969-1636', '251-368-2550'],
      },
      virtualCareOffered: [],
      address: {
        line: ['3333 South Blvd FL 1'],
        city: 'New York',
        district: 'New York',
        state: 'NY',
        postalCode: '11361',
      },
    },
  ],
};

export const MockFacilityDetailResultWithNoAccessibility: FacilityDetails = {
  culturalCompetencies: null,
  imageLocation: null,
  npi: '1063525152',
  pictureId: null,
  organizationType: ['033'],
  providerId: '001803760',
  providerType: ProviderType.ORGANIZATION,
  providerName: 'Premier Healthcare',
  providerLocations: [
    {
      locationId: '001803760-133916271-T-332DFF0C80B0BC65E50A4F5A2C40E23C-L',
      defaultLocation: true,
      accessibility: null,
      ddpCode: '',
      facilityProviderSpecialities: {
        hasAnesthesiology: true,
        hasEmergencyMedicine: true,
        isFreeStandingFacility: true,
        hasPathology: true,
        hasRadiology: true,
        hasJCAHOAccreditation: true,
      },
      latitude: '39.266954',
      longitude: '-74.592907',
      tin: '',
      tty: '',
      website: '',
      digestId: '',
      pcpId: '',
      medicalEquipments: [],
      languagesSpokenByOffice: null,
      languagesSpokenByInterpreters: null,
      hasEveningAppointments: false,
      hasPCPServiceAvailability: true,
      hasWeekendAppointment: true,
      network: {
        start: '',
        end: '',
        status: '',
      },
      practiceLimitations: {
        age: '',
        gender: '',
      },
      leapFrogRatings: [
        {
          displayField: 'Name of Leapfrog rating 1',
          ratingValue: 'Achieved the Standard',
          rawValue: '4',
        },
      ],
      lastUpdatedDate: '03/06/2023',
      hoursOfOperations: [
        {
          day: 'Sunday',
          openingTime: '10:00:00',
          closingTime: '18:00:00',
        },
        {
          day: 'Monday',
          openingTime: '08:00:00',
          closingTime: '18:00:00',
        },
        {
          day: 'Tuesday',
          openingTime: '08:00:00',
          closingTime: '18:00:00',
        },
      ],
      distance: '20',
      emailAddress: ['test@test.com'],
      phones: {
        phone: [
          '212-360-2600',
          '718-743-3850',
          '718-306-1300',
          '212-273-6519',
          '718-855-6240',
          '718-705-1000',
          '212-273-6182',
          '212-563-7474',
          '212-273-6100',
          '212-273-6521',
          '212-273-6272',
          '718-239-1790',
          '516-280-7111',
          '212-273-6515',
        ],
        emergency: ['850-433-6580', '850-626-9626'],
        appointment: ['850-434-1863', '850-433-6580'],
        fax: ['850-969-1636', '251-368-2550'],
      },
      acceptingNewPatients: false,
      virtualCareOffered: [],
      address: {
        line: ['21111 Northern Blvd FL 2'],
        city: 'Bayside',
        district: 'Queens',
        state: 'NY',
        postalCode: '11361',
      },
    },
    {
      locationId: '12345677-133916271-T-332DFF0C80B0BC65E50A4F5A2C40E23C-D',
      latitude: '39.266954',
      longitude: '-74.592907',
      acceptingNewPatients: false,
      accessibility: null,
      ddpCode: '',
      tin: '',
      tty: '',
      website: '',
      digestId: '',
      pcpId: '',
      medicalEquipments: [],
      lastUpdatedDate: '03/06/2023',
      languagesSpokenByOffice: null,
      languagesSpokenByInterpreters: null,
      hasEveningAppointments: true,
      hasPCPServiceAvailability: true,
      hasWeekendAppointment: true,
      defaultLocation: false,
      network: {
        start: '',
        end: '',
        status: '',
      },
      practiceLimitations: {
        endAge: '',
        gender: '',
        startAge: '',
      },
      facilityProviderSpecialities: {
        hasAnesthesiology: true,
        hasEmergencyMedicine: true,
        isFreeStandingFacility: true,
        hasPathology: true,
        hasRadiology: true,
        hasJCAHOAccreditation: true,
      },
      leapFrogRatings: [
        {
          displayField: 'Name of Leapfrog rating 1',
          ratingValue: 'Achieved the Standard',
          rawValue: '4',
        },
      ],
      hoursOfOperations: [
        {
          day: 'Sunday',
          openingTime: '12:00:00',
          closingTime: '16:00:00',
        },
      ],
      distance: '10',
      emailAddress: ['test@test.com'],
      phones: {
        phone: ['609-569-7077'],
        emergency: ['850-433-6580', '850-626-9626'],
        appointment: ['850-434-1863', '850-433-6580'],
        fax: ['850-969-1636', '251-368-2550'],
      },
      virtualCareOffered: [],
      address: {
        line: ['3333 South Blvd FL 1'],
        city: 'New York',
        district: 'New York',
        state: 'NY',
        postalCode: '11361',
      },
    },
  ],
};

export const MockProviderGroupResults: Provider[] = [
  {
    address: {
      line: ['123 Main St'],
      city: 'New York',
      district: 'New York',
      state: 'NY',
      postalCode: '10003',
    },
    distance: '0.4',
    facilityType: '',
    gender: '',
    latitude: '40.75406',
    locationId: '002850762-133916271-T-08134548569BC7601492CD2DDE48DAE4-L',
    longitude: '-73.99895',
    networkStatus: '',
    phones: {
      phone: ['555-555-5555', '123-456-7890'],
      emergency: [],
      appointment: [],
      fax: [],
    },
    practitionerRoleId: '',
    professionalDesignation: '',
    providerId: '1',
    providerName: 'Group One',
    providerType: 'Provider Group',
    speciality: 'Specialty',
  },
  {
    address: {
      line: ['123 Main St'],
      city: 'New York',
      district: 'New York',
      state: 'NY',
      postalCode: '10003',
    },
    distance: '0.7',
    facilityType: '',
    gender: '',
    latitude: '40.75406',
    locationId: '002850762-133916271-T-08134548569BC7601492CD2DDE48DAE4-L',
    longitude: '-73.99895',
    networkStatus: '',
    phones: {
      phone: ['555-555-5555', '123-456-7890'],
      emergency: [],
      appointment: [],
      fax: [],
    },
    practitionerRoleId: '',
    professionalDesignation: '',
    providerId: '2',
    providerName: 'Group Two',
    providerType: 'Provider Group',
    speciality: 'Specialty',
  },
  {
    address: {
      line: ['123 Main St'],
      city: 'New York',
      district: 'New York',
      state: 'NY',
      postalCode: '10003',
    },
    distance: '1.1',
    facilityType: '',
    gender: '',
    latitude: '40.75406',
    locationId: '002850762-133916271-T-08134548569BC7601492CD2DDE48DAE4-L',
    longitude: '-73.99895',
    networkStatus: '',
    phones: {
      phone: ['555-555-5555', '123-456-7890'],
      emergency: [],
      appointment: [],
      fax: [],
    },
    practitionerRoleId: '',
    professionalDesignation: '',
    providerId: '3',
    providerName: 'Group Three',
    providerType: 'Provider Group',
    speciality: 'Specialty',
  },
  {
    address: {
      line: ['123 Main St'],
      city: 'New York',
      district: 'New York',
      state: 'NY',
      postalCode: '10003',
    },
    distance: '2.5',
    facilityType: '',
    gender: '',
    latitude: '40.75406',
    locationId: '002850762-133916271-T-08134548569BC7601492CD2DDE48DAE4-L',
    longitude: '-73.99895',
    networkStatus: '',
    phones: {
      phone: ['555-555-5555', '123-456-7890'],
      emergency: [],
      appointment: [],
      fax: [],
    },
    practitionerRoleId: '',
    professionalDesignation: '',
    providerId: '4',
    providerName: 'Group Four',
    providerType: 'Provider Group',
    speciality: 'Specialty',
  },
  {
    address: {
      line: ['123 Main St'],
      city: 'New York',
      district: 'New York',
      state: 'NY',
      postalCode: '10003',
    },
    distance: '3.3',
    facilityType: '',
    gender: '',
    latitude: '40.75406',
    locationId: '002850762-133916271-T-08134548569BC7601492CD2DDE48DAE4-L',
    longitude: '-73.99895',
    networkStatus: '',
    phones: {
      phone: ['555-555-5555', '123-456-7890'],
      emergency: [],
      appointment: [],
      fax: [],
    },
    practitionerRoleId: '',
    professionalDesignation: '',
    providerId: '5',
    providerName: 'Group Five',
    providerType: 'Provider Group',
    speciality: 'Specialty',
  },
];

export const MockPrimaryCareResults = [
  {
    depSeqNbr: '00',
    lockInIndicator: false,
    pcpInfo: {
      hasPCP: true,
      isCoverageActive: true,
      pcpName: 'GRINER DONALD',
      pcpID: '123456 012',
      providerID: '123456',
      providerType: 'PRACTITIONER',
      startDate: '2020-01-01',
      endDate: '2029-12-31',
      futurePCP: {
        pcpName: 'JOHN SMITH',
        providerID: '006504354',
        providerType: 'PRACTITIONER',
        pcpID: '1234 002',
        addressSeqNbr: '001',
        startDate: '2030-01-01',
        endDate: '9999-12-31',
      },
    },
  },
  {
    depSeqNbr: '01',
    pcpInfo: {
      hasPCP: true,
      isCoverageActive: true,
      pcpName: 'GRINER DONALD',
      pcpID: '123456 012',
      providerID: '123456',
    },
  },
  {
    depSeqNbr: '02',
    pcpInfo: {
      hasPCP: false,
      isCoverageActive: false,
      pcpName: '',
      pcpID: '',
      providerID: '',
    },
  },
];
export const mockCarePathResults = {
  carePath: {
    totalCount: 14,
    carePathStepCategoryList: [
      'Laboratory',
      'Office',
      'Outpatient',
      'Physical Therapy',
    ],
    carePathCategorySelected: 'Office',
    carePathSteps: [
      {
        carePathIdentifier: 'XY15369',
        carePathStepCategoryName: 'Office',
        carePathName: '30-day heart test (EKG)',
        costIndicator: '$$',
        cost: '38',
      },
      {
        carePathIdentifier: 'MC455',
        carePathStepCategoryName: 'Office',
        carePathName: "Doctor's office visit",
        costIndicator: '$$$',
        cost: '97',
      },
      {
        carePathIdentifier: 'MC456',
        carePathStepCategoryName: 'Office',
        carePathName: "Doctor's office visit, long",
        costIndicator: '$$',
        cost: '141',
      },
      {
        carePathIdentifier: 'MC457',
        carePathStepCategoryName: 'Office',
        carePathName: "Doctor's office visit, long",
        costIndicator: '$$',
        cost: '190',
      },
      {
        carePathIdentifier: 'XY15355',
        carePathStepCategoryName: 'Office',
        carePathName: 'External heart recording',
        costIndicator: '$$',
        cost: '24',
      },
      {
        carePathIdentifier: 'XY15357',
        carePathStepCategoryName: 'Office',
        carePathName: 'External heart recording',
        costIndicator: '$$',
        cost: '38',
      },
      {
        carePathIdentifier: 'XY420',
        carePathStepCategoryName: 'Office',
        carePathName: 'Heart stress test ultrasound',
        costIndicator: '$$',
        cost: '351',
      },
      {
        carePathIdentifier: 'MC384',
        carePathStepCategoryName: 'Office',
        carePathName: 'Heart test (ECG)',
        costIndicator: '$$',
        cost: '25',
      },
      {
        carePathIdentifier: 'XY15352',
        carePathStepCategoryName: 'Office',
        carePathName: 'Mobile EKG review and report',
        costIndicator: '$$',
        cost: '40',
      },
      {
        carePathIdentifier: 'MC450',
        carePathStepCategoryName: 'Office',
        carePathName: 'New doctor office visit',
        costIndicator: '$',
        cost: '140',
      },
      {
        carePathIdentifier: 'MC451',
        carePathStepCategoryName: 'Office',
        carePathName: 'New doctor visit, 45+ minutes',
        costIndicator: '$$',
        cost: '214',
      },
      {
        carePathIdentifier: 'XY1472',
        carePathStepCategoryName: 'Office',
        carePathName: 'Radioactive contrast agent',
        costIndicator: '$$',
        cost: '521',
      },
      {
        carePathIdentifier: 'XY454',
        carePathStepCategoryName: 'Office',
        carePathName: 'Radioactive material for scan',
        costIndicator: '$',
        cost: '451',
      },
      {
        carePathIdentifier: 'XY4084',
        carePathStepCategoryName: 'Office',
        carePathName: 'Regadenoson injection',
        costIndicator: '$$',
        cost: '62',
      },
    ],
  },
};

export const MockProviderDetailResultWith6HospitalAffiliations: ProviderDetails =
  {
    biographicData: '',
    areaOfExpertise: ['Chiropractor'],
    imageLocation: '',
    isTieredProvider: true,
    npi: '1518286970',
    pictureId: '',
    providerName: 'ABRAHAM DAVID',
    firstName: 'DAVID',
    lastName: 'ABRAHAM',
    providerId: '006504354',
    providerType: ProviderType.PRACTITIONER,
    culturalCompetencies: [],
    languagesSpoken: [],
    licenseNumber: ['D0070977'],
    primaryDegrees: ['MD'],
    educationInfo: [],
    gender: 'male',
    hospitalAffiliations: [
      {
        value: 'NO HOSPITALS AFFILIATIONS',
        id: 'NO HOSPITALS AFFILIATIONS',
      },
      {
        value: 'ATLANTICARE REG MED CTR',
        id: 'ATLANTICARE REG MED CTR',
      },
      {
        value: 'NO HOSPITAL AFFILIATIONS',
        id: 'NO HOSPITAL AFFILIATIONS',
      },
      {
        value: 'ATLANTICARE GENERAL REG MED CTR',
        id: 'ATLANTICARE GENERAL REG MED CTR',
      },
      {
        value: 'NO HOSPITAL NEW AFFILIATIONS',
        id: 'NO HOSPITAL NEW AFFILIATIONS',
      },
      {
        value: 'ATLANTICARE MED REG MED CTR',
        id: 'ATLANTICARE MED REG MED CTR',
      },
      {
        value: 'NO ATLANTIC AFFILIATIONS',
        id: 'NO ATLANTIC AFFILIATIONS',
      },
      {
        value: 'ATLANTIC REG MED CTR',
        id: 'ATLANTIC REG MED CTR',
      },
    ],
    professionalDesignation: 'DO',
    primarySpeciality: ['FAMILY PRACTICE'],
    degrees: ['MEDICAL DOCTOR'],
    providerSpecialities: [
      'Anesthesiology',
      'Emergency Medicine',
      'Pathology',
      'Radiology',
    ],
    hospitalAffiliationList: [
      {
        name: 'NO HOSPITAL AFFILIATIONS',
        facilityId: '0',
        imageId: null,
        value: 'NO HOSPITAL AFFILIATIONS',
        id: 'NO HOSPITAL AFFILIATIONS',
      },
      {
        name: 'ATLANTICARE REG MED CTR',
        facilityId: '428205',
        imageId: null,
        value: 'ATLANTICARE REG MED CTR',
        id: 'ATLANTICARE REG MED CTR',
      },
    ],
    otherSpecialities: [
      'Medication assisted treatment',
      'Indian Health Services Provider',
      'Panda/pans Provider',
      'Essential community provider',
    ],
    providerGroups: [],
    organizationType: [],
    specialties: ['Family Practice'],
  };

export const MockProviderDetailResultNetworkStatusWithSameDates: ProviderDetails =
  {
    biographicData: '',
    areaOfExpertise: ['Chiropractor'],
    imageLocation: '',
    isTieredProvider: true,
    npi: '1518286970',
    pictureId: '',
    providerName: 'ABRAHAM DAVID',
    firstName: 'DAVID',
    lastName: 'ABRAHAM',
    providerId: '006504354',
    providerType: ProviderType.PRACTITIONER,
    culturalCompetencies: [],
    languagesSpoken: [],
    licenseNumber: ['D0070977'],
    primaryDegrees: ['MD'],
    educationInfo: [
      {
        schoolName: 'University of Minnesota',
        degrees: [
          {
            degreeCode: 'Residency Hospital',
            isPrimary: true,
            description: 'Residency Hospital',
          },
        ],
      },
      {
        schoolName: 'Saint Georges University',
        degrees: [
          {
            degreeCode: 'Medical School',
            isPrimary: true,
            description: 'Medical School',
          },
        ],
      },
      {
        schoolName: 'University of California',
        degrees: [
          {
            degreeCode: 'Undergraduate School',
            isPrimary: true,
            description: 'Undergraduate School',
          },
          {
            degreeCode: 'Undergraduate School2',
            isPrimary: false,
            description: 'Undergraduate School2',
          },
        ],
      },
      {
        schoolName: 'N/A',
        degrees: [
          {
            degreeCode: 'DOCTOR OF OSTEOPATHY',
            isPrimary: true,
            description: 'DOCTOR OF OSTEOPATHY',
          },
          {
            degreeCode: 'Degree-Nurse Practitioner',
            isPrimary: false,
            description: 'Degree-Nurse Practitioner',
          },
        ],
      },
    ],
    gender: 'male',
    hospitalAffiliations: [
      {
        value: 'NO HOSPITAL AFFILIATIONS',
        id: 'NO HOSPITAL AFFILIATIONS',
      },
      {
        value: 'ATLANTICARE REG MED CTR',
        id: 'ATLANTICARE REG MED CTR',
      },
    ],
    professionalDesignation: 'DO',
    primarySpeciality: ['FAMILY PRACTICE'],
    degrees: ['MEDICAL DOCTOR'],
    providerSpecialities: [
      'Anesthesiology',
      'Emergency Medicine',
      'Pathology',
      'Radiology',
    ],
    hospitalAffiliationList: [
      {
        name: 'NO HOSPITAL AFFILIATIONS',
        facilityId: '0',
        imageId: null,
        value: 'NO HOSPITAL AFFILIATIONS',
        id: 'NO HOSPITAL AFFILIATIONS',
      },
      {
        name: 'ATLANTICARE REG MED CTR',
        facilityId: '428205',
        imageId: null,
        value: 'ATLANTICARE REG MED CTR',
        id: 'ATLANTICARE REG MED CTR',
      },
    ],
    otherSpecialities: [
      'Medication assisted treatment',
      'Indian Health Services Provider',
      'Panda/pans Provider',
      'Essential community provider',
    ],
    UHPDRating: 'Quality Care Physician',
    accreditations: ['DEFAULT SPECILTY BOARD APER ID'],
    boardCertifications: [],
    otherProviderDetails: [],
    networkStartDate: '3/22/2023',
    networkStatus: '',
    servicesProvided: [
      'Office visit',
      'Medication assisted treatment',
      'Indian Health Services Provider',
      'Panda/pans Provider',
      'Essential community provider',
    ],
    healthGradeRating: '4.3',
    lastUpdatedDate: '3/22/2023',
    lastVerifiedDate: '3/22/2023',
    providerLocations: [
      {
        locationId: '0001234',
        latitude: '39.321757',
        longitude: '-74.600816',
        costIndicator: '$$$',
        accessibility: [],
        efficiencyCode: '',
        qualityCode: '',
        ddpCode: '',
        tin: '',
        tty: '',
        website: 'http:www.google.com',
        digestId: '',
        pcpId: '006504354 012',
        medicalEquipments: [],
        lastUpdatedDate: '',
        languagesSpokenByOffice: [],
        specialities: [],
        hasEveningAppointments: false,
        hasPCPServiceAvailability: true,
        hasWeekendAppointment: true,
        isPlatinumProvider: true,
        isTieredProvider: true,
        defaultLocation: true,
        phones: {
          phone: ['609-569-7077'],
          appointment: [],
          emergency: [],
          fax: [],
        },
        network: {
          start: '',
          end: '2024/08/28',
          status: '',
        },
        practiceLimitations: {
          age: '',
          gender: '',
        },
        providerGroupsAffiliations: [
          {
            id: '461717',
            value: 'STELLIS HEALTH',
          },
        ],
        facilityProviderSpecialities: {
          hasAnesthesiology: true,
          hasEmergencyMedicine: true,
          isFreeStandingFacility: true,
          hasPathology: true,
          hasRadiology: true,
          hasJCAHOAccreditation: true,
        },
        leapFrogRatings: {
          preventingMedicationErrors: '',
          rateOfEpisiotomy: '',
          managingSeriousErrors: '',
          reduceCLInfectionsInICUs: '',
          highRiskDeliveries: '',
        },
        hoursOfOperation: [
          {
            day: 'sunday',
            openingTime: '',
            closingTime: '',
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
          },
          {
            day: 'monday',
            openingTime: '08:00:00',
            closingTime: '17:00:00',
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
          },
          {
            day: 'tuesday',
            openingTime: '08:00:00',
            closingTime: '17:00:00',
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
          },
          {
            day: 'wednesday',
            openingTime: '08:00:00',
            closingTime: '17:00:00',
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
          },
          {
            day: 'thursday',
            openingTime: '08:00:00',
            closingTime: '17:00:00',
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
          },
          {
            day: 'friday',
            openingTime: '08:00:00',
            closingTime: '17:00:00',
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
          },
          {
            day: 'saturday',
            openingTime: '',
            closingTime: '',
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
          },
        ],
        distance: '0.3586041711145234',
        emailAddress: ['test@test.com'],
        acceptingNewPatients: true,
        virtualCareOffered: [],
        address: {
          line: ['443 SHORE RD', 'STE 103'],
          city: 'SOMERS POINT',
          district: 'ATLANTIC',
          state: 'NJ',
          postalCode: '08244-2645',
        },
      },
      {
        locationId: '002027197-200096809-T-AC1B641C4B7BEBA645966F82CE743861-L',
        latitude: '40.53473',
        longitude: '-74.15427',
        accessibility: [],
        efficiencyCode: '',
        qualityCode: '',
        ddpCode: '',
        tin: '',
        tty: '',
        website: '',
        digestId: '',
        pcpId: '006504354 012',
        medicalEquipments: [],
        lastUpdatedDate: '',
        languagesSpokenByOffice: [],
        specialities: [],
        hasEveningAppointments: true,
        hasPCPServiceAvailability: true,
        hasWeekendAppointment: true,
        isTieredProvider: true,
        defaultLocation: true,
        phoneNumber: {
          phone: ['609-569-7077'],
          appointment: ['609-569-7075'],
          emergency: ['609-569-7076'],
          fax: [],
        },
        providerGroupsAffiliations: [
          {
            id: '4631717',
            value: 'STELLIS HEALTH2',
          },
        ],
        network: {
          start: '',
          end: '2024/08/28',
          status: '',
        },
        practiceLimitations: {
          endAge: '',
          gender: '',
          startAge: '',
        },
        facilityProviderSpecialities: {
          hasAnesthesiology: true,
          hasEmergencyMedicine: true,
          isFreeStandingFacility: true,
          hasPathology: true,
          hasRadiology: true,
          hasJCAHOAccreditation: true,
        },
        leapFrogRatings: {
          preventingMedicationErrors: '',
          rateOfEpisiotomy: '',
          managingSeriousErrors: '',
          reduceCLInfectionsInICUs: '',
          highRiskDeliveries: '',
        },
        hoursOfOperations: [
          {
            day: 'sunday',
            openingTime: '',
            closingTime: '',
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
          },
          {
            day: 'monday',
            openingTime: '12:00:00',
            closingTime: '19:00:00',
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
          },
          {
            day: 'tuesday',
            openingTime: '12:00:00',
            closingTime: '19:00:00',
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
          },
          {
            day: 'wednesday',
            openingTime: '',
            closingTime: '',
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
          },
          {
            day: 'thursday',
            openingTime: '10:00:00',
            closingTime: '19:00:00',
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
          },
          {
            day: 'friday',
            openingTime: '',
            closingTime: '',
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
          },
          {
            day: 'saturday',
            openingTime: '',
            closingTime: '',
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
          },
        ],
        distance: '1.3388114623310468',
        emailAddress: ['test@test.com'],
        acceptingNewPatients: false,
        virtualCareOffered: [],
        address: {
          line: ['4269 Richmond Ave'],
          city: 'Staten Island',
          district: 'Richmond',
          state: 'NY',
          postalCode: '10312',
        },
      },
      {
        locationId: '002027197-133861340-T-331E65B675712DC0C5E11F0A786D298E-L',
        latitude: '40.60857',
        longitude: '-73.95985',
        accessibility: [],
        efficiencyCode: '',
        qualityCode: '',
        ddpCode: '',
        tin: '',
        tty: '',
        website: '',
        digestId: '',
        pcpId: '006504354 013',
        medicalEquipments: [],
        lastUpdatedDate: '',
        languagesSpokenByOffice: [],
        specialities: [],
        hasEveningAppointments: true,
        hasPCPServiceAvailability: true,
        hasWeekendAppointment: true,
        isTieredProvider: true,
        defaultLocation: true,
        phoneNumber: {
          phone: ['609-569-7077'],
          appointment: [],
          emergency: [],
          fax: [],
        },
        providerGroupsAffiliations: [
          {
            id: '476717',
            value: 'STELLIS HEALTH3',
          },
        ],
        network: {
          start: '',
          end: '2024/08/28',
          status: '',
        },
        practiceLimitations: {
          endAge: '',
          gender: '',
          startAge: '',
        },
        facilityProviderSpecialities: {
          hasAnesthesiology: true,
          hasEmergencyMedicine: true,
          isFreeStandingFacility: true,
          hasPathology: true,
          hasRadiology: true,
          hasJCAHOAccreditation: true,
        },
        leapFrogRatings: {
          preventingMedicationErrors: '',
          rateOfEpisiotomy: '',
          managingSeriousErrors: '',
          reduceCLInfectionsInICUs: '',
          highRiskDeliveries: '',
        },
        hoursOfOperations: [
          {
            day: 'sunday',
            openingTime: '',
            closingTime: '',
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
          },
          {
            day: 'monday',
            openingTime: '09:00:00',
            closingTime: '20:00:00',
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
          },
          {
            day: 'tuesday',
            openingTime: '09:00:00',
            closingTime: '20:00:00',
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
          },
          {
            day: 'wednesday',
            openingTime: '09:00:00',
            closingTime: '20:00:00',
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
          },
          {
            day: 'thursday',
            openingTime: '09:00:00',
            closingTime: '20:00:00',
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
          },
          {
            day: 'friday',
            openingTime: '09:00:00',
            closingTime: '20:00:00',
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
          },
          {
            day: 'saturday',
            openingTime: '',
            closingTime: '',
            sunday: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
          },
        ],
        distance: '11.489601963085107',
        emailAddress: ['test@test.com'],
        acceptingNewPatients: false,
        virtualCareOffered: [],
        address: {
          line: ['1655 E 13th St'],
          city: 'Brooklyn',
          district: 'Kings',
          state: 'NY',
          postalCode: '11229',
        },
      },
    ],
    providerGroups: [],
    organizationType: [],
    specialties: ['Family Practice'],
  };

export const MockKelseySeaboldPrimaryCareResults = [
  {
    depSeqNbr: '01',
    lockInIndicator: false,
    pcpInfo: {
      hasPCP: true,
      isCoverageActive: true,
      pcpID: '000176154',
      pcpName: 'Kelsey-Seybold Clinic',
      providerID: '176154NET933',
      providerType: 'MEDICAL_GROUP',
      startDate: '2024-04-01',
      endDate: '9999-12-31',
    },
  },
  {
    depSeqNbr: '02',
    lockInIndicator: false,
    pcpInfo: {
      hasPCP: true,
      isCoverageActive: true,
      pcpID: '000176154',
      pcpName: 'Kelsey-Seybold Clinic',
      providerID: '176154NET933',
      providerType: 'MEDICAL_GROUP',
      startDate: '2024-04-01',
      endDate: '9999-12-31',
    },
  },
];
