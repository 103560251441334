import { useForm } from '@abyss/web/hooks/useForm';
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useRouter } from '@abyss/web/hooks/useRouter';
import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { Divider } from '@abyss/web/ui/Divider';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { TextInput } from '@abyss/web/ui/TextInput';
import { ToggleSwitch } from '@abyss/web/ui/ToggleSwitch';
import { LOB } from 'parcels/src/common/Constants';
import { ProviderDetailsWidget } from 'parcels/src/frontends/ProviderDetailsWidget';
import { ProviderType } from 'parcels/src/models/ProviderDetails';
import { UserDDPCode } from 'parcels/src/utils/providerDetails.utils';
import React, { useEffect, useState } from 'react';

export const StyledSelectInput = styled(SelectInput, {
  '.abyss-select-input-label': {
    color: '$primary1',
  },
});

export const DetailsDrawer = () => {
  const { navigate } = useRouter();
  const [input, setInput] = useState<string>('005847827');
  const [policyId, setPolicyId] = useState<string>('0704201');
  const [providerId, setProviderId] = useState<string>('');
  const [locationId, setLocationId] = useState<string>('');
  const [includeCost, setIncludeCost] = useState<boolean>(true);
  const [lob, setLob] = useState<string>(LOB.ENI);
  const [population, setPopulation] = useState<string>('UNET');
  const [claimSystemTypeCode, setClaimSystemTypeCode] = useState<string | null>(
    null
  );
  const [eligibilitySystemTypeCode, setEligibilitySystemTypeCode] = useState<
    string | null
  >(null);
  const [planVariationCode, setPlanVariationCode] = useState<string | null>(
    '0001'
  );
  const [providerType, setProviderType] = useState<ProviderType>(
    ProviderType.PRACTITIONER
  );
  const [userDDP, setUserDDP] = useState<UserDDPCode>(UserDDPCode.L);
  const [coverageType, setCoverageType] = useState<string>('M');
  const [isLAWWToggled, setIsLAWWToggled] = useState<boolean>(false);

  useEffect(() => {
    if (lob === LOB.IFP) {
      setInput('6796697NET933');
      setPolicyId('TXOFEX');
    } else if (!isLAWWToggled) {
      setInput('005847827');
      setPolicyId('0704201');
    }
  }, [lob]);

  useEffect(() => {
    if (lob === LOB.IFP) {
      setClaimSystemTypeCode('A9');
      setEligibilitySystemTypeCode('A9');
      setPlanVariationCode('BRON');
    } else if (lob === LOB.ENI && population === 'USP') {
      setClaimSystemTypeCode('CR');
      setEligibilitySystemTypeCode('CR');
      setPlanVariationCode(null);
    } else {
      setClaimSystemTypeCode(null);
      setEligibilitySystemTypeCode(null);
      setPlanVariationCode('0001');
    }
  }, [population]);

  const [isOpen, setIsOpen] = useState(false);
  const onSubmit = () => {
    setProviderId(input);
    setPolicyId(policyId);
    setIsOpen(true);
    window.scrollTo(0, 0);
  };

  const handleClick = (e) => {
    setInput(e.target.innerHTML);
  };

  if (process.env.APP_ENV === 'prod') {
    navigate('/');
  }

  const getSampleProviderIdsForBHNonOrg = () => {
    let sampleProviderIds: string[] = [];
    if (lob === LOB.IFP) {
      sampleProviderIds = ['000554866', '001136032'];
    } else if (isLAWWToggled) {
      sampleProviderIds = ['000720965', '000720615', '000216939'];
    } else {
      sampleProviderIds = ['000768779', '001071641'];
    }

    return sampleProviderIds;
  };

  const getSampleReciprocityIdForBHNonOrg = () => {
    let sampleReciprocityId: string = '';
    if (lob === LOB.IFP) {
      sampleReciprocityId = '933';
    } else if (isLAWWToggled) {
      sampleReciprocityId = '100';
    } else {
      sampleReciprocityId = 'Employer%20Guest';
    }

    return sampleReciprocityId;
  };

  const getSampleReciprocityIdForBHFacility = () => {
    let sampleReciprocityId: string = '';
    if (lob === LOB.IFP) {
      sampleReciprocityId = '933';
    } else if (isLAWWToggled) {
      sampleReciprocityId = '100';
    } else {
      sampleReciprocityId = 'Employer%20Guest';
    }

    return sampleReciprocityId;
  };

  const getSampleFacilityIDsForBHFacility = () => {
    let sampleFacilityIds: string[] = [];
    if (lob === LOB.IFP) {
      sampleFacilityIds = ['FAC600810900'];
    } else if (isLAWWToggled) {
      sampleFacilityIds = ['FAC000462700', 'FAC000439800', 'FAC000462800'];
    } else {
      sampleFacilityIds = ['FAC000011600'];
    }
    return sampleFacilityIds;
  };

  const getSampleVals = () => {
    switch (coverageType) {
      case 'M':
        switch (providerType) {
          case ProviderType.ORGANIZATION:
            return {
              providerIds:
                lob === LOB.IFP
                  ? [
                      '1926185NET933',
                      '7071845NET933',
                      '1682565NET933',
                      '2446667NET933',
                    ]
                  : [
                      '002787576',
                      '007212473',
                      '000731852',
                      '006609285',
                      '000576590',
                    ],
              reciprocityId: lob === LOB.IFP ? '933' : '52',
            };
          default:
            return {
              providerIds:
                lob === LOB.IFP
                  ? [
                      '6796697NET933',
                      '5727404NET933',
                      '6397313NET933',
                      '3770469NET933',
                    ]
                  : [
                      '002787576',
                      '007212473',
                      '000731852',
                      '006609285',
                      '000576590',
                    ],
              reciprocityId: lob === LOB.IFP ? '933' : '52',
            };
        }
      case 'B':
        switch (providerType) {
          case ProviderType.ORGANIZATION:
            return {
              providerIds: getSampleFacilityIDsForBHFacility(),
              reciprocityId: getSampleReciprocityIdForBHFacility(),
            };
          default:
            return {
              providerIds: getSampleProviderIdsForBHNonOrg(),
              reciprocityId: getSampleReciprocityIdForBHNonOrg(),
            };
        }
      case 'V':
        switch (providerType) {
          case ProviderType.ORGANIZATION:
            return {
              providerIds:
                lob === LOB.IFP ? ['0000L375O', '00L40016O'] : ['00L22500O'],
              reciprocityId: '1',
            };
          default:
            return {
              providerIds:
                lob === LOB.IFP
                  ? ['00019075P', '00024046P']
                  : ['00089597P', '00017135P'],
              reciprocityId: '1',
            };
        }
      case 'D':
        return {
          providerIds: ['000007109100', '000000101572'],
          reciprocityId: 'PPO000000001',
        };
      default:
        return {};
    }
  };

  const sampleVals = getSampleVals();

  const form = useForm({
    defaultValues: {
      lob: LOB.ENI,
    },
  });

  const mockPortalData = {
    portalName: 'LAWW',
    portalConfig: {
      basePath: '/laww',
      theme: 'optum',
      language: 'en-US',
      isGuestUser: false,
      includeBaseCss: true,
      userTier: '2',
    },
  };

  useEffect(() => {
    if (isLAWWToggled) {
      setCoverageType('B');
      setLob(LOB.OBH);
      setProviderId('001023437');
      setPolicyId('0915181');
      setInput('001023437');
      setPopulation('');
      setUserDDP('');
    } else {
      setCoverageType('M');
      setLob(LOB.ENI);
      setPopulation('UNET');
      setUserDDP(UserDDPCode.L);
    }
  }, [isLAWWToggled]);

  return (
    <Flex>
      <Drawer
        hideClose
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          setProviderId('');
        }}
        size="600px"
      >
        <ProviderDetailsWidget
          claim-system-type-code={claimSystemTypeCode}
          coverage-type={coverageType}
          ddp-code={userDDP}
          eligibility-system-type-code={eligibilitySystemTypeCode}
          latitude="41.88169"
          lob={lob}
          location-id={locationId}
          longitude="-87.6228"
          out-of-pocket-cost={includeCost ? '109' : ''}
          plan-variation-code={planVariationCode}
          plan-year="2024"
          policy-id={policyId}
          population={population}
          portal-data={isLAWWToggled && JSON.stringify(mockPortalData)}
          provider-id={providerId}
          provider-type={providerType}
          reciprocity-id={sampleVals.reciprocityId}
          total-cost={includeCost ? '3600' : ''}
        />
      </Drawer>
      <div style={{ margin: '24px' }}>
        <ToggleSwitch
          data-testid="laww-portal-toggle-switch"
          isChecked={isLAWWToggled}
          label="isLAWWPortal"
          onChange={(e) => setIsLAWWToggled(e.target.checked)}
        />
        <RadioGroup
          data-testid="details-widget-coverage-type"
          display="row"
          label="Coverage type"
          onChange={(e) => {
            setCoverageType(e.target.value);
            setInput('');
          }}
          value={coverageType}
        >
          <RadioGroup.Radio
            data-testid="details-widget-coverage-type-medical"
            label="Medical"
            value="M"
          />
          <RadioGroup.Radio
            data-testid="details-widget-coverage-type-behavioral"
            label="Behavioral"
            value="B"
          />
          <RadioGroup.Radio
            data-testid="details-widget-coverage-type-vision"
            label="Vision"
            value="V"
          />
          <RadioGroup.Radio
            data-testid="details-widget-coverage-type-dental"
            label="Dental"
            value="D"
          />
        </RadioGroup>
        <div style={{ margin: '12px 0 12px 0' }}>
          <FormProvider
            data-testid="cex-widget-lob-form"
            onSubmit={onSubmit}
            state={form}
          >
            <StyledSelectInput
              data-testid="pick-one-lob-cex-widget"
              label="LOB"
              onChange={(e) => {
                form?.setValue('lob', e);
                if (e === LOB.IFP) {
                  setPopulation(LOB.IFP);
                }
                if (e === LOB.ENI) {
                  setPopulation('UNET');
                }
                setLob(e);
              }}
              options={[
                { value: LOB.ENI, label: LOB.ENI },
                { value: LOB.IFP, label: LOB.IFP },
                { value: LOB.OBH, label: LOB.OBH },
              ]}
              placeholder={lob}
              value={lob}
            />
          </FormProvider>
        </div>
        {lob === LOB.ENI && !isLAWWToggled && (
          <RadioGroup
            data-testid="details-widget-population"
            display="row"
            label="Population"
            onChange={(e) => setPopulation(e.target.value)}
            value={population}
          >
            <RadioGroup.Radio label="UNET" value="UNET" />
            <RadioGroup.Radio label="USP" value="USP" />
          </RadioGroup>
        )}
        <RadioGroup
          data-testid="details-widget-provider-type"
          display="row"
          label="Provider type"
          onChange={(e) => {
            // @ts-ignore
            setProviderType(e.target.value);
            setInput('');
          }}
          value={providerType}
        >
          <RadioGroup.Radio
            label="Practitioner"
            value={ProviderType.PRACTITIONER}
          />
          {coverageType !== 'D' && (
            <RadioGroup.Radio
              data-testid={`details-widget-${ProviderType.ORGANIZATION}`}
              label="Facility"
              value={ProviderType.ORGANIZATION}
            />
          )}
        </RadioGroup>
        {!isLAWWToggled && (
          <RadioGroup
            data-testid="details-widget-user-ddp-code"
            display="row"
            label="User DDP Code"
            onChange={(e) => {
              // @ts-ignore
              setUserDDP(e.target.value);
            }}
            size="18"
            value={userDDP}
          >
            <RadioGroup.Radio
              data-testid={`details-widget-${UserDDPCode.L}`}
              label="L"
              value={UserDDPCode.L}
            />
            <RadioGroup.Radio
              data-testid={`details-widget-${UserDDPCode.R}`}
              label="R"
              value={UserDDPCode.R}
            />
            <RadioGroup.Radio
              data-testid={`details-widget-${UserDDPCode.A}`}
              label="A"
              value={UserDDPCode.A}
            />
            <RadioGroup.Radio
              data-testid={`details-widget-${UserDDPCode.N}`}
              label="N"
              value={UserDDPCode.N}
            />
          </RadioGroup>
        )}
        <Checkbox
          data-testid="details-widget-include-cost"
          isChecked={includeCost}
          label="Include cost details"
          onChange={(e) => setIncludeCost(e.target.checked)}
          size="$xs"
        />
        <Divider />
        <div>
          <strong>Sample IDs</strong>
        </div>
        {sampleVals?.providerIds?.map((id) => (
          <div
            key={id}
            data-testid={`details-widget-id-${id}`}
            onClick={handleClick}
          >
            {id}
          </div>
        ))}
        <Divider />
        <TextInput
          data-testid="details-drawer-input"
          isClearable
          label="Set provider ID"
          onChange={(e) => setInput(e.target.value)}
          onClear={() => setInput('')}
          value={input}
        />

        <TextInput
          data-testid="details-drawer-policy-input"
          isClearable
          label="Set policy ID"
          onChange={(e) => setPolicyId(e.target.value)}
          onClear={() => setPolicyId('')}
          value={policyId}
        />

        <TextInput
          data-testid="details-drawer-location-id"
          isClearable
          label="Set location ID (optional)"
          onChange={(e) => setLocationId(e.target.value)}
          onClear={() => setLocationId('')}
          value={locationId}
        />
        <Divider />
        <Button
          data-testid="details-drawer-policy-submit-button"
          onClick={onSubmit}
        >
          Load Details
        </Button>
      </div>
    </Flex>
  );
};
