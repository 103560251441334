import { Button } from '@abyss/web/ui/Button';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';
import DOMPurify from 'dompurify';
import find from 'lodash/find';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Constants } from '../../../../../../../../../../common/Constants';
import { CostIndicator } from '../../../../../../../../../../common/CostIndicator/CostIndicator';
import { useLagoon } from '../../../../../../../../../../hooks/useLagoon';
import { popoverStyle } from '../../../../../utility/compareDrawer.styled';
import {
  CostButton,
  CostIndicatorContainer,
  CostIndicatorDrawerStyles,
  IconMaterialWeb,
  StyledNoCostIndicatorDiv,
} from './CostAttribute.style';

type Props = {
  costIndicator?: string;
  mobileScreen?: boolean;
};

export const CostAttribute = ({ costIndicator, mobileScreen }: Props) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [isCostIndicatorDrawerOpen, setIsCostIndicatorDrawerOpen] =
    useState(false);

  const data = useLagoon(Constants.LAGOON_TABLE.COST_INDICATOR_POPOVER)();
  const cost = find(data, {
    key: '1',
  });
  const costIndicatorContent = cost?.costIndicatorPopoverContent;
  const costTitle = cost?.title;

  const uiMessaging = useLagoon(Constants.LAGOON_TABLE.UI_MESSAGING)();
  const costInfo = find(uiMessaging, {
    key: 'COSTINFO',
  })?.message;

  const costAttribute = (
    <CostIndicatorContainer>
      {/* Desktop - Cost is NULL */}
      {!costIndicator && !mobileScreen && (
        <StyledNoCostIndicatorDiv
          data-auto-testid="Cost Information Not Availabler-text"
          data-testid="Cost Information Not Available-text"
        >
          {costInfo}
        </StyledNoCostIndicatorDiv>
      )}

      {/* Mobile - Cost is NULL */}
      {!costIndicator && mobileScreen && (
        <Popover
          content={costInfo}
          css={popoverStyle}
          showClose={false}
          tabIndex="-1"
          width={150}
        >
          <StyledNoCostIndicatorDiv
            data-auto-testid="Cost Information Not Availabler-text"
            data-testid="Cost Information Not Available-text"
          >
            {costInfo}
          </StyledNoCostIndicatorDiv>
        </Popover>
      )}

      {/* Desktop - Cost available */}
      {!mobileScreen && costIndicator && (
        <Popover
          align="center"
          aria-label={`${costIndicator} ${t(
            'ACCESSIBILITY_LABELS.INFORMATION_ABOUT_COST'
          )}`}
          content={
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(costIndicatorContent),
              }}
            />
          }
          css={{
            '&:focus-visible': {
              margin: '5px',
            },
          }}
          data-auto-testid="provider-cost-pop-over"
          data-testid="provider-cost-pop-over"
          onClick={(event) => {
            event.stopPropagation();
          }}
          onOpenChange={() => {
            setIsOpen(!isOpen);
          }}
          open={isOpen}
          title={<Text size="$sm">{costTitle}</Text>}
          width={220}
        >
          <Layout.Group space={4}>
            <CostButton
              className="icon-text-container"
              data-auto-testid="provider-cost-button"
              data-testid="provider-cost-button"
            >
              <CostIndicator indicatorData={costIndicator} />
            </CostButton>
            <IconMaterial
              css={IconMaterialWeb}
              data-auto-testid="provider-cost-Info"
              data-testid="provider-cost-Info"
              icon="info"
              size={20}
              tabIndex={-1}
              variant="outlined"
            />
          </Layout.Group>
        </Popover>
      )}

      {/* Mobile - Cost available */}
      {mobileScreen && costIndicator && (
        <Flex>
          <Drawer
            aria-label="Cost Indicator"
            css={CostIndicatorDrawerStyles}
            data-auto-testid="cost-indicator-drawer"
            data-testid="cost-indicator-drawer"
            isOpen={isCostIndicatorDrawerOpen}
            onClose={() => setIsCostIndicatorDrawerOpen(false)}
            position="bottom"
            size="200"
            title={costTitle}
          >
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(costIndicatorContent),
              }}
            />
          </Drawer>
          <Layout.Group space={4}>
            <CostIndicator indicatorData={costIndicator} />
            <Button
              css={{
                '&.abyss-button-root': {
                  padding: 0,
                  height: 'auto',
                },
              }}
              data-auto-testid="cost-indicator-drawer-info"
              data-testid="cost-indicator-drawer-infoo"
              onClick={() => setIsCostIndicatorDrawerOpen(true)}
              variant="ghost"
            >
              <IconMaterial icon="info" size={20} variant="outlined" />
            </Button>
          </Layout.Group>
        </Flex>
      )}
    </CostIndicatorContainer>
  );

  return <React.Fragment>{costAttribute}</React.Fragment>;
};
