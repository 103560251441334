import { Button } from '@abyss/web/ui/Button';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Markdown } from '@abyss/web/ui/Markdown';
import find from 'lodash/find';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { adobeImpressionTrackEvent } from '../AdobeTagging/adobeImpressionTrackEvent';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { LinkWithTracking } from '../LinkWithTracking/LinkWithTracking';
import {
  EAPCodeBoxContainer,
  EAPCodeNeeded,
  EAPCodeText,
  EAPFlexContainer,
  EAPGetMyCodeLink,
} from './EAPCode.styled';

export const EAPCode = () => {
  const { t } = useTranslation();
  const data = useLagoon('ui-messaging')();
  const eapWidgetContent = find(data, {
    key: ConstantsLagoon.EAP_AUTHORIZATION_CODE,
  });
  const [isOpen, setIsOpen] = useState(true);
  const navigationURL = eapWidgetContent?.url || '';
  const eapBannerMessage = eapWidgetContent?.message || '';
  const onClickGetMyLink = () => {
    adobeLinkTrackEvent({
      name: 'activate eap benefit',
      location: 'alert: eap benefit',
      type: 'external',
      destinationUrl: navigationURL,
    });
  };

  const eapCloseBtnStyle = {
    'abyss-button-root': {
      paddingRight: '0px',
      paddingLeft: '0px',
      height: '20px',
      borderRadius:'unset'
    }
  }

  useEffect(() => {
    adobeImpressionTrackEvent({
      type: 'alert',
      message: 'eap benefit',
    });
  }, []);

  return isOpen ? (
    <EAPCodeBoxContainer>
      <EAPFlexContainer
        data-auto-testid="eap-code-card-container"
        data-testid="eap-code-card-container"
        justify="space-between"
      >
        <EAPFlexContainer justify="flex-start">
          <IconBrand css={{ flexShrink: 0 }} icon="handshake" size="20px" />
          <EAPCodeNeeded
            data-auto-testid="eap-code-needed"
            data-testid="eap-code-needed"
          >
            <EAPCodeText>
              <Markdown>{eapBannerMessage}</Markdown>
            </EAPCodeText>
            <LinkWithTracking
              after={<IconMaterial icon="chevron_right" size="18" />}
              analyticsInfo={{
                location: 'body:get code',
                destinationUrl: navigationURL,
              }}
              css={EAPGetMyCodeLink}
              data-auto-testid="eap-code-card-get-my-code"
              data-testid="eap-code-card-get-my-code"
              hideIcon
              href={navigationURL}
              onClick={onClickGetMyLink}
            >
              {t('PROVIDER_DETAILS.ABOUT_TAB.EAP_CODE_CARD.LINK_TEXT')}
            </LinkWithTracking>
          </EAPCodeNeeded>
        </EAPFlexContainer>

        <Button
          css={eapCloseBtnStyle}
          data-auto-testid="eap-close-button"
          data-testid="eap-close-button"
          onClick={() => setIsOpen(false)}
          variant="ghost"
        >
          <IconMaterial
            color="$black"
            css={{ flexShrink: 0, cursor: 'pointer', alignSelf: 'center' }}
            data-testid="eap-code-close"
            icon="close"
            size="20px"
          />
        </Button>
      </EAPFlexContainer>
    </EAPCodeBoxContainer>
  ) : (
    <React.Fragment />
  );
};
