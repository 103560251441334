import { styled } from '@abyss/web/tools/styled';

import { mobileOnly, notMobile } from '../../ConstantsStyles';

const darkGrey = '#4B4D4F';
const lightGrey = '#d0d0d0';

export const Input = styled('input', {
  border: 'none !important',
  fontSize: 18,
  outlineWidth: '0',
  padding: '0px !important',
  width: '100%',
  '&:focus': {
    boxShadow: 'none',
    textOverflow: 'ellipsis',
  },
  '&:focus-visible': {
    boxShadow: 'none',
  },
  '&.allyFocusWithin:focus': {
    boxShadow: 'none',
  },
  textOverflow: 'ellipsis',
  '&::placeholder': {
    fontSize: '18px',
    fontStyle: 'italic',
    lineHeight: '24px',
  },
});

export const KeywordSearchStyledDiv = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  [`@media ${mobileOnly}`]: {
    paddingTop: '4px',
  },
  '#initInstr': {
    display: 'none',
  },
});

export const KeywordSearchLabel = styled('label', {
  color: darkGrey,
  fontSize: '12.64px',
  fontWeight: 700,
  lineHeight: '16px',
});

export const KeywordSearchInputContainer = styled('div', {
  display: 'flex',
  flex: 3,
  flexDirection: 'column',
  [`@media ${notMobile}`]: {
    gap: '4px',
    marginRight: '407px',
  },
});

export const DarkeningOverlay = styled('div', {
  background: 'rgba(0, 0, 0, 0.2)',
  height: '100%',
  left: 0,
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: 1,
});

export const SearchPlacementStyle = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: `8px 20px`,
});

export const InputBox = styled('div', {
  alignSelf: 'flex-start',
  backgroundColor: 'white',
  border: `1px solid $searchBorderColor`,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  left: 0,
  overflow: 'hidden',
  right: 0,
  top: 0,
  width: '100%',
  zIndex: 1,
  [`@media ${notMobile}`]: {
    borderRadius: '36px',
  },
  [`@media ${mobileOnly}`]: {
    borderRadius: '30px',
  },
  variants: {
    isSharpBottomRightCorner: {
      true: {
        borderBottomRightRadius: '0px',
      },
    },
    hasShadow: {
      true: {
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
      },
    },
    mobileScreen: {
      true: {
        position: 'relative',
      },
      false: {
        position: 'absolute',
      },
    },
  },
});

export const PositioningBox = styled('div', {
  height: '62px',
  zIndex: '9',
  marginBottom: '24px',
  position: 'relative',
  width: '100%',
  paddingRight: '$lg',
  [`@media ${mobileOnly}`]: {
    display: 'flex',
    marginBottom: 0,
    zIndex: 2,
    height: '62px !important',
  },
});

export const LocationInputContainer = styled('div', {
  display: 'flex',
  marginLeft: '24px',
  position: 'absolute',
  right: '20px',
  top: '0',
  width: '407px',
  zIndex: 1,
  [`@media ${mobileOnly}`]: {
    display: 'none',
  },
});

export const LocationInputRow = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  marginLeft: '24px',
  position: 'absolute',
  right: '24px',
  top: '12px',
  width: '407px',
});

export const LocationSearchPieceContainer = styled('div', {
  display: 'flex',
  flex: 1,
  [`@media ${notMobile}`]: {
    flexDirection: 'column',
    gap: '4px',
  },
  [`@media ${mobileOnly}`]: {
    display: 'none',
  },
  variants: {
    isShowingLocationDropdown: {
      true: {
        marginLeft: '25px',
        marginRight: '24px',
      },
      false: {
        marginLeft: '24px',
        marginRight: '24px',
      },
    },
  },
});

export const A11yHiddenSpan = styled('span', {
  border: '0',
  clip: 'rect(0 0 0 0)',
  clipPath: 'polygon(0px 0px, 0px 0px, 0px 0px)',
  '-webkit-clip-path': 'polygon(0px 0pxm 0px 0px, 0px 0px)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: '0',
  position: 'absolute',
  width: '1px',
  whiteSpace: 'nowrap',
});

export const LocationSearchPiece = styled('div', {
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  '#initInstrLocation': {
    display: 'none',
  },
});

export const LocationSearchLabel = styled('label', {
  color: darkGrey,
  fontSize: '12.64px',
  fontWeight: 700,
  lineHeight: '16px',
});

export const InputDivider = styled('div', {
  backgroundColor: lightGrey,
  margin: '0px',
  width: '1px',
  [`@media ${mobileOnly}`]: {
    display: 'none',
  },
});

export const IconContainer = styled('div', {
  height: '24px',
  marginBottom: 'auto',
  marginRight: '8px',
  marginTop: 'auto',
  width: '24px',
});
