import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { Text } from '@abyss/web/ui/Text';
import debounce from 'lodash/debounce';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocation } from '../../../hooks/useLocation';
import { parseSuggestionResults } from '../../../utils/locationSearch.util';
import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../Constants';
import { ItemList } from '../../ItemList';
import { locationSearchImpressionTrackEvent } from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import { hiddenTextStyle } from '../SearchBar/LocationDropdown/LocationDropdown.styled';
import {
  FindUserLocationContainer,
  ProviderSearchContainer,
  SearchContainer,
  SearchInput,
  StyledSearchButton,
} from './LocationSearchMobile.styled';

type Props = {
  findUserLocation: Function;
  location?: string;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  setLocation?: (a: any) => void;
};

export const LocationSearchMobile = ({
  findUserLocation,
  location,
  setIsOpen = () => {},
  setLocation = () => {},
}: Props) => {
  const { t } = useTranslation();

  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [gqlSuggestions, setgqlSuggestions] = useState<object[]>([]);
  const [currentListItemIndex, setCurrentListItemIndex] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Layout>('');
  const [address, setAddress] = useState<string>(location || '');
  const locationForAnalytics: string = 'change search location';

  const [, getLocation] = useLocation({
    onCompleted: (result: {
      data: { location: { features: [{ place_name: string; id: string }] } };
    }) => {
      const resultSuggestions = result?.data?.location;
      const parsedSuggestions = parseSuggestionResults(resultSuggestions);
      if (parsedSuggestions.length > 0) {
        setError('');
      } else {
        setError(
          <Layout.Group>
            <IconMaterial color="$error1" icon="error" />
            <Text css={hiddenTextStyle}>Error: </Text>
            {<div>{t('LOCATION_SEARCH.ERR_DEC_RELEASE')}</div>}
          </Layout.Group>
        );
      }
      setIsLoading(false);
      setSuggestions(parsedSuggestions);
      setgqlSuggestions(result?.data?.location?.features);
    },
    onError: () => {
      setIsLoading(false);
      setError(
        <Layout.Group>
          <IconMaterial color="$error1" icon="error" />
          {<div>{t('LOCATION_SEARCH.ERR_DEC_RELEASE')}</div>}
        </Layout.Group>
      );
    },
  });

  const handleLocationChange = async (loc: string) => {
    const { data } = await getLocation({
      variables: {
        address: encodeURIComponent(loc),
        countySearchEnabled: true,
      },
    });
    if (data) {
      locationSearchImpressionTrackEvent(loc, data.location?.features?.length);
    }
    setIsLoading(false);
  };

  const debounceApiFilteringSearch = useCallback(
    debounce(async (inputValue: string) => {
      await handleLocationChange(inputValue);
    }, 300),
    []
  );

  const handleOnChange = (e: { target: { value: string } }) => {
    const { value } = e.target;
    setAddress(value);
    if (value.length >= 2) {
      setIsLoading(true);
      debounceApiFilteringSearch.cancel();
      debounceApiFilteringSearch(value)?.catch(() => {});
    }
    setError('');
  };

  const handleItemListOnClick = (value: string) => {
    setAddress(value);
    if (address) {
      setLocation(value);
    }
    if (typeof setIsOpen !== 'undefined' && address) {
      setIsOpen(false);
    }
  };

  const handleArrowKeys = (e: { key: string; preventDefault: () => void }) => {
    if (
      e.key === 'ArrowDown' &&
      currentListItemIndex < suggestions.length - 1
    ) {
      setCurrentListItemIndex(currentListItemIndex + 1);
    }

    if (e.key === 'ArrowUp' && currentListItemIndex > 0) {
      setCurrentListItemIndex(currentListItemIndex - 1);
    }
  };

  const handleInputKeyDown = (e: {
    key: string;
    preventDefault: () => void;
  }) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (suggestions.length < 1) {
        e.preventDefault();
      } else if (address) {
        const selectedAddress = suggestions[currentListItemIndex];
        setLocation(selectedAddress);
      }
      if (address && currentListItemIndex === -1) {
        const selectedAddress = suggestions[0];
        setLocation(selectedAddress);
      }
      if (
        typeof setIsOpen !== 'undefined' &&
        address &&
        suggestions.length > 0
      ) {
        setIsOpen(false);
      }
    } else if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
      handleArrowKeys(e);
    } else if (e.key === 'Backspace') {
      setSuggestions([]);
    }
  };

  const adobeLinkTrackEventFindLocation = () =>
    adobeLinkTrackEvent({
      name: Constants.LOCATION_SEARCH.FIND_MY_LOCATION,
      location: `body:${locationForAnalytics}`,
      type: 'internal',
    });

  const adobeLinkTrackEventSuggestedLocation = () =>
    adobeLinkTrackEvent({
      name: 'suggested location',
      location: `body:${locationForAnalytics}`,
      type: 'internal',
    });

  const getMyLocation = () => {
    adobeLinkTrackEventFindLocation();
    findUserLocation();
  };

  return (
    <ProviderSearchContainer
      css={{ '.abyss-page-body-container': { paddingLeft: '$sm' } }}
    >
      <SearchContainer>
        {
          <Text fontWeight="500" size="$md">
            {t('locationTileTextDec')}
          </Text>
        }
        <SearchInput
          data-auto-testid="location-search-text"
          data-testid="location-search-text"
          errorMessage={error}
          isClearable
          label={t('Location')}
          onChange={(e: { target: { value: string } }) => {
            handleOnChange(e);
          }}
          onClear={() => {
            setAddress('');
            setError('');
          }}
          onKeyDown={(e: { key: string; preventDefault: () => void }) => {
            handleInputKeyDown(e);
          }}
          placeholder={t('LOCATION_SEARCH.PLACEHOLDER')}
          value={address}
        />
        <LoadingSpinner
          ariaLoadingLabel="Downloading files"
          isLoading={isLoading}
          size="$sm"
        />
        {suggestions.length > 0 &&
        address.length >= 2 &&
        gqlSuggestions.length > 0 ? (
          <ItemList
            activeIndex={currentListItemIndex}
            data-auto-testid="location-suggestions-list"
            data-testid="location-suggestions-list"
            gqlSug={gqlSuggestions}
            items={suggestions}
            onClick={(value: any) => {
              adobeLinkTrackEventSuggestedLocation();
              handleItemListOnClick(value);
            }}
          />
        ) : (
          <FindUserLocationContainer>
            <StyledSearchButton
              data-auto-testid="location-search-button"
              data-testid="location-search-button"
              onClick={getMyLocation}
              variant="ghost"
            >
              <IconMaterial css={{ marginRight: '10px' }} icon="my_location" />
              <Text color="$interactive1" size="sm">
                {t('Find my location')}
              </Text>
            </StyledSearchButton>
          </FindUserLocationContainer>
        )}
      </SearchContainer>
    </ProviderSearchContainer>
  );
};
