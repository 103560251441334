import { PageSize, Pagination } from '@abyss/web/ui/Pagination';
import { Skeleton } from '@abyss/web/ui/Skeleton';
import { useTranslation } from 'react-i18next';

import { PaginationControl, PagingationWrapStyled } from './MapView.styled';

type Props = {
  isLoading: boolean | undefined;
  pageSize: number;
  topPaginationRef: any;
  pageSizeOptions: any;
  setPageSizeFunc: any;
  previousPageTop: any;
  paginationProps: any;
  nextPageTop: any;
  enableMapViewEnhancements: boolean;
};

export const MapViewTopPagination = ({
  isLoading,
  pageSize,
  topPaginationRef,
  pageSizeOptions,
  setPageSizeFunc,
  previousPageTop,
  paginationProps,
  nextPageTop,
  enableMapViewEnhancements,
}: Props) => {
  const { t } = useTranslation();

  return (
    <PagingationWrapStyled ref={topPaginationRef}>
      <PaginationControl
        className="hide-pagination"
        css={{
          paddingBottom: '4px',
          '@screen < $md': {
            display: enableMapViewEnhancements ? 'flex' : 'none',
          },
        }}
        data-testid="pagination-results-container"
      >
        <PageSize
          css={{
            'abyss-pagination-page-size-label': {
              fontWeight: '$semibold',
            },
          }}
          customPerPageLabel={t('PAGINATION.PER_PAGE')}
          customResultsLabel={t('PAGINATION.RESULTS_LABEL')}
          data-testid="pagination-page-size"
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          setPageSize={setPageSizeFunc}
        />
        {isLoading ? (
          <Skeleton height={40} variant="rounded" width={150} />
        ) : (
          <Pagination
            {...paginationProps}
            css={{
              'abyss-pagination-abbreviated-container': {
                color: 'var(--abyss-colors-gray8)',
                fontWeight: 400,
              },
            }}
            customNextLabel={t('PAGINATION.NEXT')}
            customPreviousLabel={t('PAGINATION.PREVIOUS')}
            data-testid="pagination-results"
            nextPage={nextPageTop}
            previousPage={previousPageTop}
            variant="compact"
          />
        )}
      </PaginationControl>
    </PagingationWrapStyled>
  );
};
