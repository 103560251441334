import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import find from 'lodash/find';
import React, { useEffect, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { CustomDisclaimerBanner } from '../../../common/CustomDisclaimerBanner/CustomDisclaimerBanner';
import { useAdobePageTrackEvent } from '../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useCoverageType } from '../../../hooks/useCoverageType';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { useLagoon } from '../../../hooks/useLagoon';
import { CarePathsResponse } from '../../../models/CarePaths';
import { ProviderDetails } from '../../../models/ProviderDetails';
import {
  getNetworkStatus,
  getSelectedLocationIndex,
} from '../../../utils/providerDetails.utils';
import { getCurrentMember } from '../../../utils/user.utils';
import {
  Constants,
  CostEstimatesNotificationPolicy,
  ReverseCoverageTypesCodes,
} from '../../Constants';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import { convertProviderTypeToAdobeType } from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import { CarePaths } from './CarePaths/CarePaths';
import {
  CommonCostProceduresHeader,
  CommonCostProceduresTextSection,
  CommonProceduresAndCostContainer,
  CostEstimateNotificationAlert,
} from './Cost.styled';
import { InNetworkSection } from './InNetworkSection';

type Props = {
  carePathsData: CarePathsResponse;
  providerDetails: ProviderDetails;
  setCarePathPageNum: (num: number) => void;
  setCarePathStepCategory: (category: string) => void;
  tabTitle: string;
};

export const Cost = ({
  carePathsData,
  providerDetails,
  setCarePathPageNum,
  setCarePathStepCategory,
  tabTitle,
}: Props) => {
  const coverageType = useCoverageType();
  const currentMember = getCurrentMember();
  const [carePathDataAvailable, setCarePathDataAvailable] = useState(false);

  useEffect(() => {
    if (carePathsData && carePathsData?.carePathSteps) {
      setCarePathDataAvailable(carePathsData?.carePathSteps?.length > 0);
    } else {
      setCarePathDataAvailable(false);
    }
  }, [carePathsData]);
  const data = useLagoon('ui-messaging')();
  const customDisclaimerData = useLagoon('custom-disclaimers')();

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: tabTitle,
    sitesectionLevel1: Constants.ADOBE_TRACKING.DETAILS_SITESECTION1,
    sitesectionLevel2: `${convertProviderTypeToAdobeType(
      providerDetails.providerType
    )} details`,
  });

  useEffect(() => {
    if (providerDetails.providerName) adobePageTrackEvent();
  }, [providerDetails.providerName]);

  const costEstimateText = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS.COST_TAB_COST_ESTIMATES_TEXT,
  });
  const costEstimateTextNotAvailable = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS
      .COST_TAB_COST_ESTIMATES_NOT_AVAILABLE_TEXT,
  });
  const costEstimateHeading = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS.COST_TAB_COST_ESTIMATES_HEADING,
  });
  const costEstimateNotAvailableHeading = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS
      .COST_TAB_COST_ESTIMATES_NOT_AVAILABLE_HEADING,
  });
  const costEstimateNotification = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS.COST_TAB_COST_ESTIMATES_NOTIFICATION,
  });
  const [enableCostEstimateNotification] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_COST_ESTIMATES_NOTIFICATION,
  ]);

  const locationsDetails = providerDetails?.providerLocations;
  const [selectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );
  const index = getSelectedLocationIndex(locationsDetails, selectedId);
  const selectedLocation = locationsDetails?.[index];
  const networkStatus = getNetworkStatus(selectedLocation?.network?.status);

  const { title, message } = costEstimateNotification;
  const [showNotification, setShowNotification] = useState(true);

  const getCustomDisclaimerDataForCost = () => {
    const costDisclaimerData = customDisclaimerData?.filter(
      (data) =>
        data?.dnt_pageName ===
        Constants.CUSTOM_DISCALIMER_BANNER_PAGE_NAME.PROVIDER_DETAILS_COST_TAB
    );
    const activeCostDisclaimerData = costDisclaimerData?.filter(
      (data) =>
        data?.active &&
        data?.policyId
          ?.split(',')
          ?.map((id) => id.trim())
          ?.includes(currentMember?.policyNumber) &&
        data?.reciprocityId
          ?.split(',')
          ?.map((id) => id.trim())
          ?.includes(currentMember?.reciprocityId)
    );
    return activeCostDisclaimerData;
  };

  const customDisclaimerDataForCost = getCustomDisclaimerDataForCost();

  return (
    <React.Fragment>
      <CustomDisclaimerBanner data={customDisclaimerDataForCost} />
      {showNotification &&
      message &&
      enableCostEstimateNotification &&
      CostEstimatesNotificationPolicy.includes(currentMember?.policyNumber) &&
      coverageType === ReverseCoverageTypesCodes.MEDICAL ? (
        <CommonProceduresAndCostContainer>
          <CostEstimateNotificationAlert
            css={costEstimateNotification}
            data-testid="cost-estimate-notification-alert"
            isVisible={showNotification}
            onClose={() => {
              setShowNotification(false);
            }}
            title={title}
            variant="info"
          >
            {message}
          </CostEstimateNotificationAlert>
        </CommonProceduresAndCostContainer>
      ) : null}
      <InNetworkSection inNetwork={networkStatus} />
      <CommonProceduresAndCostContainer>
        <Heading
          css={CommonCostProceduresHeader}
          data-auto-testid="cost-tab-cost-estimate-heading"
          data-testid="cost-tab-cost-estimate-heading"
          display="h4"
          offset={1}
        >
          {carePathDataAvailable
            ? costEstimateHeading?.message
            : costEstimateNotAvailableHeading?.message}
        </Heading>
        <CommonCostProceduresTextSection>
          <Flex
            css={{
              '.&abyss-flex-root': {
                color: '$gray7',
                fontSize: '18px',
                '@screen < $sm': {
                  fontSize: '16px',
                  paddingTop: '12px',
                },
              },
              paddingBottom: '8px',
            }}
          >
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: carePathDataAvailable
                  ? costEstimateText?.message
                  : costEstimateTextNotAvailable?.message,
              }}
              style={{ color: '#4B4D4F' }}
            />
          </Flex>
        </CommonCostProceduresTextSection>

        <CarePaths
          carePaths={carePathsData}
          selectedLocation={selectedLocation}
          setCarePathDataAvailable={setCarePathDataAvailable}
          setCarePathPageNum={setCarePathPageNum}
          setCarePathStepCategory={setCarePathStepCategory}
        />
      </CommonProceduresAndCostContainer>
    </React.Fragment>
  );
};
